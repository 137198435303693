import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      no_users: 0,
      no_users_month: 0,
      no_transactions: 0,
      no_transactions_month: 0,
      wallet_bal: 0,
      no_assets: 0,
      total_vol: 0,
      total_trx_vol_month: 0,
      total_trx_vol: 0,
      active_tickets: 0,
      unreplied_tickets: 0,
      total_savings_portfolio: 0,
      today_fees:0
    };
  }

  componentDidMount() {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/overview", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          let overviewData = response.data.data;
          this.setState({
            no_users: overviewData.no_users,
            no_users_month: overviewData.no_users_month,
            no_transactions: overviewData.no_transactions,
            no_transactions_month: overviewData.no_transactions_month,
            wallet_bal: overviewData?.wallet_bal?.toFixed(0),
            no_assets: overviewData.no_assets,
            active_tickets: overviewData.active_tickets,
            unreplied_tickets: overviewData.unreplied_tickets,
            total_vol: parseFloat(overviewData.total_vol).toFixed(3),
            total_trx_vol_month: parseFloat(overviewData.total_trx_vol_month).toFixed(3),
            total_trx_vol: parseFloat(overviewData.total_trx_vol).toFixed(3),
            total_savings_portfolio: overviewData.total_savings_portfolio,
            today_fees: overviewData.today_fees,
          });
        }
      });
  }

  render() {

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row">
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total number of users
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                              <i className="far fa-user" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.no_users}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              New users this month
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="far fa-money-bill-alt" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.no_users_month}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Number of transactions
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-tasks" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.no_transactions}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title  text-muted margin-overview-card-text">
                              Transactions this month
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-circle" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.no_transactions_month}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total Number Of Assets
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.no_assets}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total amount in Wallet
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.wallet_bal}
                              prefix={"NGN "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total Asset Volume
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_vol}
                              prefix={"USD "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Total Transaction <br /> Volume
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_trx_vol}
                              prefix={"USD "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Transaction Volume <br /> This Month
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_trx_vol_month}
                              prefix={"USD "}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Open Tickets{" "}
                              {this.state.unreplied_tickets > 0 && (
                                <a href="/tickets">
                                  <span
                                    className="badge bg-danger text-white"
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    {this.state.unreplied_tickets}
                                  </span>
                                </a>
                              )}
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.active_tickets}
                              displayType={"text"}
                              thousandSeparator={","}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Savings Portfolio{" "}
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.total_savings_portfolio}
                              displayType={"text"}
                              thousandSeparator={","}
                              decimalScale="2"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="card card-stats mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <h4 className="card-title text-muted margin-overview-card-text">
                              Today Fees{" "}
                            </h4>
                          </div>
                          <div className="col-auto">
                            <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                              <i className="fas fa-piggy-bank" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <span className="h2 text-size font-weight-bold mb-0">
                            <NumberFormat
                              style={{ fontSize: 20 }}
                              value={this.state.today_fees}
                              displayType={"text"}
                              prefix={"NGN "}

                              thousandSeparator={","}
                              decimalScale="2"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
