import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";
import moment from "moment";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],

      email: "",
      status: "all",
      startDate: null,
      endDate: null,

      //pagination state
      perPage: 100,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,

      pin: "",
      order: {},
      action: "",
      actionLabel: "",
      reinitiate: null,
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    this.setState({
      orders: [],
    });
    const response = await axios.get(
      `${ConstantVar.API_BASE_URL}/admin/transfers/list?currentPage=${
        this.state.currentPage
      }&perPage=${this.state.perPage}&token=${localStorage.getItem("supportToken")}`
    );
    if (response.data.status === "success") {
      this.setState({
        orders: response.data.data.data,
        isLoading: false,
        _from: response.data.data.pagination.from,
        _to: response.data.data.pagination.to,
      });

      console.log(response.data.data.data);
      if (!this.state.paginationLoaded) {
        let total = response.data.data.pagination.total;
        this.setState({
          pageCount: Math.ceil(total / this.state.perPage),
          currentPage: response.data.data.pagination.currentPage,
          paginationLoaded: true,
          totalEntries: total,
        });
      }
    }

    // axios
    //   .get(ConstantVar.API_BASE_URL + "/admin/orders", {
    //     params: {
    //       token: localStorage.getItem("supportToken"),
    //       otp: this.props.otp,
    //       current_page: this.state.currentPage,
    //       per_page: this.state.perPage,
    //       email: this.state.email,
    //       status: this.state.status,
    //       end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
    //       start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.status === "true") {
    //       this.setState({
    //         orders: response.data.data.data,
    //         isLoading: false,
    //         _from: response.data.data.pagination.from,
    //         _to: response.data.data.pagination.to,
    //       });

    //       if (!this.state.paginationLoaded) {
    //         let total = response.data.data.pagination.total;
    //         this.setState({
    //           pageCount: Math.ceil(total / this.state.perPage),
    //           currentPage: response.data.data.pagination.currentPage,
    //           paginationLoaded: true,
    //           totalEntries: total,
    //         });
    //       }
    //     }
    //   });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getOrders();
    });
  };

  handleAction = (e) => {
    e.preventDefault();
    if (this.state.pin.length !== 6) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + `/admin/${this.state.action}`, {
        token: localStorage.getItem("supportToken"),
        order_id: this.state.order.id,
        pin: this.state.pin,
        reinitiate: this.state.reinitiate,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.$("#orderModal").modal("hide");
          alert(response.data.message);
          this.setState({
            isSubmitting: false,
          });
          this.getOrders();
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">
                  Orders
                  <div className=" form-inline float-right">
                    <button
                      type="button"
                      onClick={() => {
                        window.$("#filterModal").modal("show");
                      }}
                      class="btn btn-primary btn-sm"
                    >
                      Filter
                    </button>
                  </div>
                  <div className="text-right">{paginationElement}</div>
                </h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>S/N</th>
                    <th>Date</th>
                    <th>Coin</th>
                    <th>Amount</th>
                    <th>Trade Count</th>
                    <th>USD Val</th>
                    <th>Avg Price</th>
                    <th>Type</th>
                    {/* <th>Status</th> */}
                    {/* 
                    <th>...</th>
                    <th>...</th>
                    <th>...</th>
                    <th>...</th>
                    <th>...</th>
                    <th>...</th>
                    <th>...</th> */}
                  </thead>
                  <tbody>
                    {this.state.orders.length > 0 ? (
                      this.state.orders.map((order, index) => {
                        return (
                          <tr>
                            <th>{index + 1}</th>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                <p className="text-muted trans-date">
                                  {moment(order.created_at).format("MMM Do")}

                                  <br />
                                  {moment(order.created_at).format("hh:mm A")}
                                </p>
                              </div>
                            </th>

                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                <img
                                  className="rounded-circle"
                                  src={
                                    ConstantVar.API_BASE_URL +
                                    "/static/assets/" +
                                    order.asset +
                                    ".png"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultCoinIcon;
                                  }}
                                />
                                {"  "}
                                {order.asset}
                              </div>
                            </th>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                <a href={"/user/" + order.email} target="_blank">
                                  {order.asset_amount}
                                </a>
                              </div>
                            </td>
                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {order.trade_amount}
                              </div>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <h3 className="media align-items-center">
                                {" "}
                                <NumberFormat
                                  value={order.asset_usd_value.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={","}
                                />
                              </h3>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {order.current_price}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center"> {"Sell"}</div>
                            </td>

                            {/* <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {order.status}
                              </div>
                            </td> */}

                            {/* <td className="dash-td" align="left">
                              {order.status === "trading" && (
                                <div className="media align-items-center">
                                  <button
                                    className="btn btn  btn-primary"
                                    onClick={() => {
                                      this.setState({
                                        pin: "",
                                        action: "cancel_order",
                                        actionLabel: "Cancel Order",
                                        order,
                                      });
                                      window.$("#orderModal").modal("show");
                                    }}
                                  >
                                    Cancel Order
                                  </button>
                                  <button
                                    className="btn btn  btn-warning"
                                    onClick={() => {
                                      this.setState({
                                        pin: "",
                                        action: "cancel_order",
                                        actionLabel: " Cancel & Reinitiate Order",
                                        reinitiate: 1,
                                        order,
                                      });
                                      window.$("#orderModal").modal("show");
                                    }}
                                  >
                                    Cancel & Reinitiate Order
                                  </button>
                                </div>
                              )}

                              {order.status === "trading_failure" && (
                                <div className="media align-items-center">
                                  <button
                                    className="btn btn  btn-primary"
                                    onClick={() => {
                                      this.setState({
                                        pin: "",
                                        action: "reinitiate_order",
                                        actionLabel: "Cancel Order",
                                        order,
                                      });
                                      window.$("#orderModal").modal("show");
                                    }}
                                  >
                                    Reinitiate Order
                                  </button>
                                  <button
                                    className="btn btn  btn-warning"
                                    onClick={() => {
                                      this.setState({
                                        pin: "",
                                        action: "complete_order",
                                        actionLabel: "Transfer values to Master Account",
                                        order,
                                      });
                                      window.$("#orderModal").modal("show");
                                    }}
                                  >
                                    Transfer to Master Account
                                  </button>
                                </div>
                              )}
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No orders loaded yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="orderModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="orderModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="orderModalLabel">
                  {this.state.actionLabel}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleAction} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Type: </label>
                    <b> {this.state.order.type}</b>
                  </div>
                  <div class="form-group">
                    <label>Asset: </label>
                    <b> {this.state.order.asset}</b>
                  </div>
                  <div class="form-group">
                    <label>Email: </label>
                    <b> {this.state.order.email}</b>
                  </div>
                  <div class="form-group">
                    <label>Amount: </label>
                    <b> {this.state.order.amount}</b>
                  </div>

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">
                  Filter Orders
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  this.setState(
                    {
                      currentPage: 1,
                      paginationLoaded: false,
                    },
                    () => {
                      window.$("#filterModal").modal("hide");
                      this.getOrders();
                    }
                  );
                }}
                autoComplete="off"
              >
                <div class="modal-body">
                  <div className=" form-group">
                    <label> Per Page</label>
                    <input
                      type="number"
                      name="perPage"
                      placeholder="Per Page"
                      value={this.state.perPage}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label> Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="successful">Completed</option>
                      <option value="trading">Trading</option>
                      <option value="transfer">Transfer</option>
                      <option value="created">Created</option>
                      <option value="insufficient_fund">Insufficient Fund</option>
                      <option value="trading_failure">Trading Failure</option>
                    </select>
                  </div> */}
                  <div className="form-group">
                    <label>Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  {/* <div className=" form-group">
                    <label> Email Address</label>
                    <input
                      className="form-control"
                      placeholder="Email Address:"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div> */}
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Order;
