import React, { Component } from "react";
import "./assets/index.css";

class Asset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
    };
  }
  componentDidMount() {
    this.setState({
      assets: this.props.assets,
    });
  }

  render() {
    return (
      <div
        className="tab-pane fade"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Assets</h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>..</th>
                    <th>Coin</th>
                    <th>Balance</th>
                    <th>Total Deposit</th>
                    <th>Total Sent</th>
                  </thead>
                  <tbody>
                    {this.state.assets.length > 0 ? (
                      this.state.assets.map((asset, index) => {
                        return (
                          <tr>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center"></div>
                            </th>

                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {asset.coin}
                              </div>
                            </th>

                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <h3 className="media text-primary align-items-center">
                                {" "}
                                {asset.bal !== 0 ? asset.bal.toFixed(8) : 0}
                              </h3>
                            </td>

                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <h3 className="media text-success align-items-center">
                                {" "}
                                {asset.total_deposited !== 0
                                  ? asset.total_deposited.toFixed(8)
                                  : 0}
                              </h3>
                            </td>

                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <h3 className="media text-danger align-items-center">
                                {" "}
                                {asset.total_sent !== 0
                                  ? asset.total_sent.toFixed(8)
                                  : 0}
                              </h3>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No assets done yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Asset;
