import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import MessageUser from "./message.js";
import BroadcastMessage from "./broadcast_message.js";
import BlogScreen from "./blog.js";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row">
                  <nav>
                    <div className="nav " id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link profile-click-m active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        href="#nav-home"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Message Users</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item nav-link profile-click-m"
                        id="nav-broadcast-tab"
                        data-toggle="tab"
                        href="#nav-broadcast-message"
                        role="tab"
                        aria-controls="nav-broadcast"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Broadcast</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item nav-link profile-click-m"
                        id="nav-blog-tab"
                        data-toggle="tab"
                        href="#nav-blog-screen"
                        role="tab"
                        aria-controls="nav-broadcast"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Blog</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt--7 promargin-m">
            <div className="row">
              <div className="col-xl-12 order-xl-1">
                <div className="tab-content" id="nav-tabContent">
                  <MessageUser />
                  <BroadcastMessage />
                  <BlogScreen />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
