import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      target_users: [],
    };
    this.createMessage = this.createMessage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  createMessage(e) {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });
    if (e.target.title.value.length < 5) {
      alert("message title is to short");
      return;
    }
    if (e.target.body.value.length < 10) {
      alert("message content is to short");
      return;
    }

    if (window.confirm("Are you sure you want to send the message?")) {
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/message", {
          title: e.target.title.value,
          body: e.target.body.value,
          pin: e.target.pin.value,
          token: localStorage.getItem("supportToken"),
          target_users: this.state.target_users,
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert(response.data.message);
            window.location.href = "/messaging";
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    }
  }

  handleFiles = (event) => {
    var input = event.target;
    let self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var text = reader.result;
      var data = window.$.csv.toObjects(text);
      self.setState({
        target_users: data,
      });
    };
    if (input.files[0]) reader.readAsText(input.files[0]);
  };

  render() {
    return (
      <div
        className="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div className="card shadow">
          <form
            role="form"
            style={{ padding: 50 }}
            onSubmit={this.createMessage}
            autoComplete="off"
          >
            <h2>Message All Users</h2>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <input
                  required
                  autoComplete="off"
                  name="title"
                  className="form-control"
                  placeholder="Title of message"
                  type="text"
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <textarea
                  rows={10}
                  required
                  autoComplete="off"
                  name="body"
                  className="form-control"
                  placeholder="Body of message"
                />
              </div>
            </div>
            <div className="form-inline mb-3">
              <label>Target Users</label>
              <div className="input-group input-group-alternative">
                <input
                  name="targets"
                  accept=".csv"
                  className="form-control"
                  type="file"
                  onChange={this.handleFiles}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <div className="input-group input-group-alternative">
                <input
                  required
                  autoComplete="off"
                  name="pin"
                  className="form-control"
                  placeholder="Confirm PIN"
                  type="text"
                />
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                disabled={this.state.isSubmitting}
                className="btn btn-primary my-2 w-100"
              >
                Send now (Kindly crosscheck before clicking this)
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Messaging;
