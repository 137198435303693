import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
// import AppContext from "../../../AppContext";//

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      old_password: "",
      password: "",
      c_password: "",
      isLoading: false,
    };
  }

  componentDidMount() {}

  changPass = (e) => {
    e.preventDefault();
    // check if matric number match
    if (e.target.password.value != e.target.c_password.value) {
      alert("New Pin does not match");
      return;
    }
    if (e.target.password.value.length != 6) {
      alert("New Pin must be 6 digits.");
      return;
    }
    let self = this;
    this.setState({
      isSubmitting: true,
    });
    let data = {
      old_password: e.target.old_password.value,
      password: e.target.password.value,
      type: "0",
      token: localStorage.getItem("supportToken"),
    };
    // register student
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/change_pin", data)
      .then((response) => {
        if (response.data.status === "true") {
          // reset form
          self.setState({
            isSubmitting: false,
            old_password: "",
            password: "",
            c_password: "",
          });
          alert(response.data.message);
          window.location = "/";
        }
      })
      .catch((error) => {
        self.setState({
          isSubmitting: false,
        });
        alert(error.response.data.message);
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container mt--9">
            <div className="col-md-6 mx-auto">
              <div className="card shadow" style={{ height: "500px" }}>
                <div className="card-header border-0">
                  <h2 className="mb-0 d-inline">Change Pin</h2>
                </div>
                <hr className="dash-hr" />

                <form onSubmit={this.changPass} className="p-5">
                  <div class="form-group">
                    <label>Old Pin</label>
                    <input
                      type="password"
                      name="old_password"
                      class="form-control"
                      placeholder="Old Pin"
                      required
                      onChange={this.handleChange}
                      value={this.state.old_password}
                    />
                  </div>

                  <div class="form-group">
                    <label>New Pin</label>
                    <input
                      type="password"
                      name="password"
                      class="form-control"
                      placeholder="Enter your new pin"
                      required
                      onChange={this.handleChange}
                      value={this.state.password}
                    />
                  </div>

                  <div class="form-group">
                    <label>Re-type New Pin</label>
                    <input
                      type="password"
                      name="c_password"
                      class="form-control"
                      placeholder="Re-type your new pin"
                      required
                      value={this.state.c_password}
                      onChange={this.handleChange}
                    />
                  </div>

                  {this.state.isLoading ? (
                    <div className="text-center">
                      <p style={{ padding: 100 }}>Loading...</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="submit"
                    class="btn btn-primary btn-block mb-5 "
                    // disabled={this.state.isSubmitting}
                  >
                    Change
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
// ChangePassword.contextType = AppContext;
