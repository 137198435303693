import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../../components/assets/paginate.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import { CSVLink, CSVDownload } from "react-csv";

class Trxs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aggregate_trxs: [],
      isLoading: true,
      isSubmitting: false,
      trx: {},
      start_date: null,
      end_date: null,
      account: "",
      type: "deposit",
      confirmed: "all",

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTrxs();
  }

  getTrxs = (e) => {
    if (e !== undefined) {
      e.preventDefault();
      window.$("#filterTrxModal").modal("hide");
      this.setState({
        paginationLoaded: false,
      });
    }
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(
        ConstantVar.API_BASE_URL + "/admin/aggregate_p2p_bank_trx_by_date_and_account",
        {
          params: {
            token: localStorage.getItem("supportToken"),
            per_page: this.state.perPage,
            current_page: this.state.currentPage,
            account: this.state.account,
            type: this.state.type,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            confirmed: this.state.confirmed,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            aggregate_trxs: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  confirmAmountReceived = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_account/confirm_amount_received", {
        token: localStorage.getItem("supportToken"),
        amount_received: this.state.amount_received,
        id: this.state.trx.id,
        pin: this.state.pin,
        type: this.state.trx.type,
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            isSubmitting: false,
            amount_received: 0,
          });
          window.$("#confirmAmountReceivedModal").modal("hide");
          alert(response.data.message);
          this.getTrxs();
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTrxs();
    });
  };

  getFileName = () => {
    let from = this.state.startDate
      ? `_${this.state.start_date}_${this.state.end_date}`
      : `_${moment().format("YYYY-MM-DD")}`;

    return `p2p-bank-transactions-report${from}.csv`;
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div className="mt-3">
        {this.state.aggregate_trxs.length > 0 && (
          <CSVLink
            data={this.state.aggregate_trxs}
            className="text-primary"
            filename={this.getFileName()}
          >
            Download csv
          </CSVLink>
        )}
        <div className=" form-inline float-right">
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => {
              this.setState({
                update_type: "fee",
              });
              window.$("#filterTrxModal").modal("show");
            }}
          >
            Filter
          </button>
        </div>
        {(() => {
          if (this.state.isLoading)
            return (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            );
          else
            return (
              <div className="table-responsive mt-2">
                {this.state.aggregate_trxs.length > 0 ? (
                  <div>
                    <div className="pl-2 ml-5">{paginationElement}</div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Account Name</th>
                          <th>Account Number</th>
                          <th>Bank Name</th>
                          <th>Total Amount</th>
                          <th>Amount Received</th>
                          <th>....</th>
                        </thead>
                        <tbody>
                          {this.state.aggregate_trxs.map((trx) => {
                            return (
                              <tr>
                                <th scope="row dash-td">
                                  {moment(trx.date).format("YYYY-MM-DD")}
                                </th>
                                <th scope="row dash-td">{trx.account}</th>
                                <td className="dash-td">{trx.account_name}</td>
                                <td className="dash-td">
                                  <h3 className="">
                                    <p>{trx.account_number}</p>
                                  </h3>
                                </td>
                                <td className="dash-td">
                                  <p>{trx.bank_name}</p>
                                </td>

                                <td className="dash-td">
                                  <NumberFormat
                                    value={trx.amount}
                                    className="card-bal-your text-primary"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <td className="dash-td">
                                  <NumberFormat
                                    value={trx.amount_received}
                                    className="card-bal-your text-success"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                  />
                                </td>

                                <td className="dash-td">
                                  {trx.confirmed === null ? (
                                    <button
                                      className="btn btn-sm btn-primary"
                                      disabled={this.state.isSubmitting}
                                      onClick={() => {
                                        this.setState({
                                          trx: trx,
                                        });
                                        window
                                          .$("#confirmAmountReceivedModal")
                                          .modal("show");
                                      }}
                                    >
                                      Confirm
                                    </button>
                                  ) : trx.confirmed === 1 ? (
                                    <button className="btn btn-sm btn-success">
                                      Confirmed
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        <thead>
                          <th>
                            <h4>Total:</h4>{" "}
                          </th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>
                            <NumberFormat
                              value={this.state.aggregate_trxs.reduce(
                                (partialSum, a) => partialSum + a.amount,
                                0
                              )}
                              className="card-bal-your  h4"
                              displayType={"text"}
                              prefix={"NGN "}
                              decimalScale={0}
                              thousandSeparator={true}
                            />
                          </th>
                          <th>
                            <NumberFormat
                              value={this.state.aggregate_trxs.reduce(
                                (partialSum, a) => partialSum + a.amount_received,
                                0
                              )}
                              className="card-bal-your  h4"
                              displayType={"text"}
                              prefix={"NGN "}
                              decimalScale={0}
                              thousandSeparator={true}
                            />
                          </th>
                          <th>....</th>
                        </thead>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="text-center no-data full-width-data">
                    <p className="text-muted no-trans-text-o">No record yet!</p>
                  </div>
                )}
              </div>
            );
        })()}

        <div
          class="modal fade"
          id="filterTrxModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterTrxModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterTrxModalLabel">
                  Filter Transaction
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.getTrxs} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Account</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Account"
                      name="account"
                      value={this.state.account}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Start Date</label>
                    <input
                      class="form-control form-control-sm"
                      type="date"
                      placeholder="Start Date"
                      name="start_date"
                      value={this.state.start_date}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>End Date</label>
                    <input
                      class="form-control form-control-sm"
                      type="date"
                      placeholder="End Date"
                      name="end_date"
                      value={this.state.end_date}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Type</label>
                    <select
                      class="form-control form-control-sm"
                      name="type"
                      value={this.state.type}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="deposit">Deposit</option>
                      <option value="withdraw">Withdraw</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Confirmation</label>
                    <select
                      class="form-control form-control-sm"
                      name="confirmed"
                      value={this.state.confirmed}
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="unconfirmed">Unconfirmed</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Per page</label>
                    <select
                      class="form-control form-control-sm"
                      name="perPage"
                      value={this.state.perPage}
                      onChange={this.handleChange}
                    >
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                      <option value="5">5</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="confirmAmountReceivedModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmAmountReceivedModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirmAmountReceivedModalLabel">
                  Confirm Amount Received
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.confirmAmountReceived} autoComplete="off">
                <div class="modal-body">
                  <h5>
                    @{this.state.trx.account} -{" "}
                    {moment(this.state.trx.date).format("YYYY-MM-DD")} -{" "}
                    <NumberFormat
                      value={this.state.trx.amount}
                      className="card-bal-your text-primary"
                      displayType={"text"}
                      prefix={"NGN "}
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  </h5>

                  <div class="form-group">
                    <label>Amount Received</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Amount Received"
                      name="amount_received"
                      value={this.state.amount_received}
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trxs;
