import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import NumberFormat from "react-number-format";

class UserAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      isSubmitting: false,
    };
  }
  componentDidMount() {
    this.getUserAssets();
  }

  getUserAssets = () => {
    this.setState({
      assets: [],
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/assets", {
        params: {
          token: localStorage.getItem("supportToken"),
          otp: this.props.otp,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            assets: response.data.data,
          });
        }
      });
  };

  render() {
    return (
      <div className="tab-pane" id="nav-assets" role="tabpanel" aria-labelledby="nav-assets-tab">
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">
                  User Assets
                  <small>
                    <i
                      className="ml-2 fa fa-refresh"
                      onClick={this.getUserAssets}
                      style={{
                        cursor: "pointer",
                      }}
                    ></i>
                  </small>
                </h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>..</th>
                    <th>Coin</th>
                    <th>Balance</th> <th>USD Val</th>
                    <th>Current Price</th>
                    <th>24hrs changes</th>
                  </thead>
                  <tbody>
                    {this.state.assets.length > 0 ? (
                      this.state.assets.map((asset, index) => {
                        return (
                          <tr>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center" style={{height: '40px', width: '40px',}}>
                                <img
                                  alt=""
                                  className="rounded-circle"
                                  // style={{
                                  //   width: "50px",
                                  //   height: "50px",
                                  // }}
                                  src={ConstantVar.API_BASE_URL + "/static/assets/" + asset.coin + ".png"}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultCoinIcon;
                                  }}
                                />
                              </div>
                            </th>

                            <th scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center"> {asset.coin}</div>
                            </th>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <h3 className="media text-primary align-items-center">
                                {" "}
                                {asset.bal !== 0 && asset.bal !== null ? asset.bal.toFixed(8) : 0}
                              </h3>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <div className="media align-items-center text-primary">
                                <NumberFormat
                                  value={asset.bal !== 0 ? (asset.bal * asset.current_price).toFixed(2) : 0}
                                  displayType={"text"}
                                  className="h3"
                                  thousandSeparator={","}
                                />
                              </div>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <div className="media align-items-center">{asset.current_price}</div>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                <p className={asset._24hrs.indexOf("-") >= 0 ? "text-danger" : "text-success"}>
                                  {" "}
                                  {asset._24hrs}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">No assets loaded yet!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserAsset;
