/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../../components/navbar.js";
import Sidebar from "../../../components/sidebar.js";
import BlockTrx from "./block_trx.js";
import BlockAccount from "./block_account.js";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "account",
    };
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Block</h2>
            <ul class="nav nav-pills  p-3">
              <li class="nav-item">
                <a
                  className={"nav-link " + (this.state.tab === "account" ? "active" : "")}
                  href="#"
                  onClick={() => this.setState({ tab: "account" })}
                >
                  {" "}
                  Blocked Account
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={"nav-link " + (this.state.tab === "trx" ? "active" : "")}
                  href="#"
                  onClick={() => this.setState({ tab: "trx" })}
                >
                  Blocked Transactions
                </a>
              </li>
            </ul>

            <div class="p-3">
              {this.state.tab === "account" ? (
                <BlockAccount />
              ) : this.state.tab === "trx" ? (
                <BlockTrx />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
