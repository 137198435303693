import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import NumberFormat from "react-number-format";

class SavingsAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      tradeType: null,
      asset: {},
      quantity: 0,
      isSubmitting: false,
      pin: "",
      convert_res: 0,
      convert_coin: 0,
      transfer_quantity: 0,
    };
  }
  componentDidMount() {
    this.getSavingsAccount();
  }

  trade = (e) => {
    e.preventDefault();

    if (this.state.quantity <= 0 || this.state.pin.length !== 6) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/savings/trade", {
        token: localStorage.getItem("supportToken"),
        order_type: this.state.tradeType,
        quantity: this.state.quantity,
        asset: this.state.asset.coin,
        price: this.state.asset.current_price,
        pin: this.state.pin,
        is_savings: true,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.$("#tradeModal").modal("hide");
          alert(response.data.message);

          this.setState({
            isSubmitting: false,
          });
          this.getSavingsAccount();
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  transfer = (e) => {
    e.preventDefault();

    if (this.state.transfer_quantity <= 0 || this.state.pin.length !== 6) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/savings/transfer", {
        token: localStorage.getItem("supportToken"),
        quantity: this.state.transfer_quantity,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.$("#transferModal").modal("hide");
          alert(response.data.message);

          this.setState({
            isSubmitting: false,
          });
          this.getSavingsAccount();
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  getSavingsAccount = () => {
    this.setState({
      assets: [],
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/savings_account", {
        params: {
          token: localStorage.getItem("supportToken"),
          otp: this.props.otp,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            assets: response.data.data,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  convert = (e) => {
    e.preventDefault();

    if (this.state.convert_quantity <= 0) {
      return;
    }
    const convert_res = (this.state.convert_quantity / this.state.convert_coin).toFixed(
      8
    );
    this.setState({
      convert_res,
    });
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">
                  Savings Account
                  <small>
                    <i
                      className="ml-2 fa fa-refresh"
                      onClick={this.getSavingsAccount}
                      style={{
                        cursor: "pointer",
                      }}
                    ></i>
                  </small>
                </h3>

                <button
                  className="mb-0 d-inline float-right btn btn-sm"
                  onClick={() => {
                    window.$("#converterModal").modal("show");
                  }}
                >
                  Converter
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>..</th>
                    <th>Coin</th>
                    <th>Balance</th>
                    <th>Locked</th>
                    <th>USD Val</th>
                    <th>Current Price</th>
                    <th>24hrs changes</th>
                    <th>...</th>
                  </thead>
                  <tbody>
                    {this.state.assets.length > 0 ? (
                      this.state.assets.map((asset, index) => {
                        return (
                          <tr>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                <img
                                  alt=""
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  className="rounded-circle"
                                  src={
                                    ConstantVar.API_BASE_URL +
                                    "/static/assets/" +
                                    asset.coin +
                                    ".png"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultCoinIcon;
                                  }}
                                />
                              </div>
                            </th>

                            <th scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {asset.coin}
                              </div>
                            </th>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <h3 className="media text-primary align-items-center">
                                {" "}
                                {asset.bal !== 0 ? asset.bal.toFixed(8) : 0}
                              </h3>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <h3 className="media text-info align-items-center">
                                {" "}
                                {asset.locked !== 0 ? asset.locked.toFixed(8) : 0}
                              </h3>
                            </td>
                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <h3 className="media align-items-center">
                                {" "}
                                <NumberFormat
                                  value={
                                    asset.bal !== 0
                                      ? (asset.bal * asset.current_price).toFixed(2)
                                      : 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={","}
                                />
                              </h3>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {asset.current_price}
                              </div>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                <p
                                  className={
                                    asset._24hrs.indexOf("-") >= 0
                                      ? "text-danger"
                                      : "text-success"
                                  }
                                >
                                  {" "}
                                  {asset._24hrs}
                                </p>
                              </div>
                            </td>

                            <td className="dash-td" align="left" style={{ width: "16%" }}>
                              {asset.coin !== "USDT" ? (
                                <div className="media align-items-center">
                                  <button
                                    className="btn btn  btn-primary"
                                    onClick={() => {
                                      this.setState({
                                        tradeType: 0,
                                        asset: asset,
                                        quantity: "",
                                        pin: "",
                                      });
                                      window.$("#tradeModal").modal("show");
                                    }}
                                  >
                                    Buy
                                  </button>
                                  <button
                                    className="btn btn  btn-warning"
                                    onClick={() => {
                                      this.setState({
                                        tradeType: 1,
                                        asset: asset,
                                        quantity: asset.bal,
                                        pin: "",
                                      });
                                      window.$("#tradeModal").modal("show");
                                    }}
                                  >
                                    Sell
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className="btn btn  btn-primary"
                                  onClick={() => {
                                    this.setState({
                                      transfer_quantity: 0,
                                      pin: "",
                                    });
                                    window.$("#transferModal").modal("show");
                                  }}
                                >
                                  Transfer to Master
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No assets loaded yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="tradeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="tradeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="tradeModalLabel">
                  {this.state.tradeType === 0 ? "BUY" : "SELL"} {this.state.asset.coin}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.trade} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Quantity</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      name="quantity"
                      value={this.state.quantity}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="converterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="converterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="converterModalLabel">
                  CONVERTER
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.convert} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>USDT</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      name="convert_quantity"
                      value={this.state.convert_quantity}
                      onChange={this.handleChange}
                      required
                      placeholder="USDT quantity"
                    />
                  </div>

                  <div class="form-group">
                    <label>Coin</label>
                    <select
                      class="form-control form-control-sm"
                      type="text"
                      name="convert_coin"
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Select</option>
                      {this.state.assets.map((asset) => {
                        return <option value={asset.current_price}>{asset.coin}</option>;
                      })}
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Result</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      value={this.state.convert_res}
                      disabled
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary btn-sm">
                    Convert
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="transferModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="transferModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="transferModalLabel">
                  Transfer to Master
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.transfer} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Quantity</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      name="transfer_quantity"
                      value={this.state.transfer_quantity}
                      onChange={this.handleChange}
                      required
                      placeholder="USDT quantity"
                    />
                  </div>

                  <div class="form-group">
                    <label>Pin</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      name="pin"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary btn-sm">
                    Transfer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SavingsAccount;
