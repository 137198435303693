import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import ReactPaginate from "react-paginate";

class Trade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trades: [],

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }
  componentDidMount() {
    this.getTrades();
  }

  getTrades = () => {
    this.setState({
      trades: [],
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/trades", {
        params: {
          token: localStorage.getItem("supportToken"),
          otp: this.props.otp,
          current_page: this.state.currentPage,
          per_page: this.state.perPage,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            trades: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            this.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTrades();
    });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">
                  Trades
                  <div className="text-right">{paginationElement}</div>
                </h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>..</th>
                    <th>Coin</th>
                    <th>Email</th>
                    <th>Amount</th>
                    <th>Price</th>
                    <th>Type</th>
                    <th>Trx ID</th>
                    <th>Order ID</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {this.state.trades.length > 0 ? (
                      this.state.trades.map((trade, index) => {
                        return (
                          <tr>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center" style={{height: '40px', width: '40px',}}>
                                <img
                                  alt=""
                                  className="rounded-circle"
                                  // style={{
                                  //   width: "50px",
                                  //   height: "50px",
                                  // }}
                                  src={
                                    ConstantVar.API_BASE_URL +
                                    "/static/assets/" +
                                    trade.asset +
                                    ".png"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultCoinIcon;
                                  }}
                                />
                              </div>
                            </th>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.asset}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.email}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.amount}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.price}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.side}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.client_order_id}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.order_id}
                              </div>
                            </td>

                            <td scope="row dash-td" style={{ width: "16%" }}>
                              <div className="media align-items-center">
                                {" "}
                                {trade.status}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No orders loaded yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trade;
