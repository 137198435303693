import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class DebitAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      asset: "",
      amount: null,
      wallet: "",

      user: {
        info: {},
        assets: [],
      },
      isSubmitting: false,
      stage: 1,
      pin: "",
    };
  }

  getUserInfo = () => {
    this.setState({
      isSubmitting: true,
    });

    axios
      .get(ConstantVar.API_BASE_URL + "/admin/user", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            user: response.data.data,
            isSubmitting: false,
            stage: 2,
          });
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
        this.setState({
          isSubmitting: false,
        });
      });
  };

  handleDebitAccountForm = (e) => {
    e.preventDefault();

    if (this.state.stage === 1) {
      this.getUserInfo();
    } else if (this.state.stage === 2) {
      let currency = this.state.wallet === "fiat" ? "NGN" : this.state.asset;
      let cfm = window.confirm(
        `Are sure you want to debit ${this.state.email} \n of ${this.state.amount} ${currency}`
      );
      if (cfm) {
        this.debitUserAccount();
      }
    }
  };

  debitUserAccount = () => {
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/debit_account", {
        token: localStorage.getItem("supportToken"),
        wallet: this.state.wallet,
        asset: this.state.asset,
        amount: this.state.amount,
        pin: this.state.pin,
        email: this.state.email,
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.resetForm();
          alert(response.data.message);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
        this.setState({
          isSubmitting: false,
        });
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  resetForm = () => {
    this.setState({
      isSubmitting: false,
      stage: 1,
      email: "",
      pin: "",
      wallet: "",
      assets: "",
      amount: "",
    });
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">
                  Debit Account{" "}
                  <small>
                    <i
                      className="ml-2 fa fa-refresh"
                      onClick={this.resetForm}
                      style={{
                        cursor: "pointer",
                      }}
                    ></i>
                  </small>
                </h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />

              <form
                onSubmit={this.handleDebitAccountForm}
                autoComplete="off"
                className="row mt-3"
              >
                <div class="col-md-6 mx-auto">
                  {this.state.stage === 1 ? (
                    <div>
                      <h3>Account</h3>
                      <div class="form-group mt-5">
                        <label>Email Address</label>
                        <input
                          class="form-control mb-3"
                          type="text"
                          required
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <button
                          className="btn btn-primary btn-sm"
                          disabled={this.state.isSubmitting}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  ) : this.state.stage === 2 ? (
                    <div>
                      <h3>
                        {this.state.user.info.fname} {this.state.user.info.lname} <br />
                        <small> {this.state.user.info.email}</small>
                      </h3>
                      <div class="form-group mt-5">
                        <label>Wallet option</label>
                        <select
                          class="form-control"
                          name="wallet"
                          value={this.state.wallet}
                          onChange={this.handleChange}
                        >
                          <option value="">Select</option>
                          <option value="fiat">Fiat</option>
                          <option value="asset">Asset</option>
                        </select>
                        {this.state.wallet === "fiat" ? (
                          <small>
                            Available Bal:
                            <NumberFormat
                              value={
                                this.state.user.info.available_bal !== 0
                                  ? this.state.user.info.available_bal.toFixed(2)
                                  : 0
                              }
                              className="h6"
                              displayType={"text"}
                              prefix="NGN "
                              thousandSeparator={","}
                            />
                          </small>
                        ) : (
                          ""
                        )}

                        {/* show asset list if the asset is selected as wallet option */}
                        {this.state.wallet === "asset" ? (
                          <div className="mt-3">
                            <label>Assets</label>
                            <select
                              class="form-control mb-3"
                              name="asset"
                              value={this.state.asset}
                              onChange={this.handleChange}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.user.assets.map((asset) => {
                                return (
                                  <option value={asset.coin}>
                                    {asset.coin} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; bal: {asset.bal.toFixed(8)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* show amount text box to enter the amount to debit */}
                        {this.state.wallet !== "" ? (
                          <div className="mt-3">
                            <label>Amount to debit</label>
                            <input
                              class="form-control mb-3"
                              type="text"
                              name="amount"
                              value={this.state.amount}
                              onChange={this.handleChange}
                              required
                            />

                            {this.state.amount > 0 ? (
                              <input
                                class="form-control mb-3"
                                type="password"
                                placeholder="Enter PIN to confirm"
                                name="pin"
                                value={this.state.pin}
                                onChange={this.handleChange}
                                required
                              />
                            ) : (
                              ""
                            )}

                            <button
                              className="btn btn-primary btn-sm"
                              disabled={this.state.isSubmitting}
                            >
                              Confirm
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DebitAccount;

//  user: {
//        info : {
//           fname: "Segun",
//         lname: "Babalola",
//         email: "babalolaolusegun1@gmail.com",
//         available_bal: "30000",
//        },
//         assets: [
//           {
//             bal: "2.1",
//             coin: "BTC",
//           },
//           {
//             bal: "20",
//             coin: "USDT",
//           },
//           {
//             bal: "0.0002345",
//             coin: "BNB",
//           },
//         ],
//       },
