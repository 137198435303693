import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import ReactPaginate from "react-paginate";

class TradeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buy_naira_rate: 0,
      sell_naira_rate: 0,
      fee_percent: 0,
      minimum_to_buy: 0,
      minimum_to_sell: 0,
      minimum_to_deposit: 0,
      minimum_to_withdraw: 0,
      minimum_to_transfer: 0,
      bank_deposit_fee_cap: 0,
      bank_withdraw_fee_cap: 0,
      bank_withdraw_processing_method: "",
      isSubmitting: false,
      pin: "",
    };
  }

  componentDidMount() {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/trade_info", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          const appData = response.data.data;
          this.setState({
            isLoading: false,
            buy_naira_rate: appData.buy_naira_rate,
            sell_naira_rate: appData.sell_naira_rate,
            fee_percent: appData.fee_percent,
            minimum_to_buy: appData.minimum_to_buy,
            minimum_to_sell: appData.minimum_to_sell,
            minimum_to_deposit: appData.minimum_to_deposit,
            minimum_to_withdraw: appData.minimum_to_withdraw,
            minimum_to_transfer: appData.minimum_to_transfer,
            bank_deposit_fee_cap: appData.bank_deposit_fee_cap,
            bank_withdraw_fee_cap: appData.bank_withdraw_fee_cap,
            bank_withdraw_processing_method: appData.bank_withdraw_processing_method,
          });
        }
      });
  }

  updateTradeInfo = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/trade_info", {
        token: localStorage.getItem("supportToken"),
        buy_naira_rate: this.state.buy_naira_rate,
        sell_naira_rate: this.state.sell_naira_rate,
        fee_percent: this.state.fee_percent,
        minimum_to_buy: this.state.minimum_to_buy,
        minimum_to_sell: this.state.minimum_to_sell,
        minimum_to_deposit: this.state.minimum_to_deposit,
        minimum_to_withdraw: this.state.minimum_to_withdraw,
        minimum_to_transfer: this.state.minimum_to_transfer,
        bank_withdraw_fee_cap: this.state.bank_withdraw_fee_cap,
        bank_deposit_fee_cap: this.state.bank_deposit_fee_cap,
        bank_withdraw_processing_method: this.state.bank_withdraw_processing_method,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            isSubmitting: false,
            pin: "",
          });
          alert(response.data.message);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
        this.setState({
          isSubmitting: false,
        });
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Trading Info.</h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />

              <form
                onSubmit={this.updateTradeInfo}
                autoComplete="off"
                className="row mt-3"
              >
                <div class="col-md-6 mx-auto">
                  <div class="form-group">
                    <label>Buy Naira Rate</label>
                    <input
                      class="form-control"
                      type="text"
                      name="buy_naira_rate"
                      value={this.state.buy_naira_rate}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label>Sell Naira Rate</label>
                    <input
                      class="form-control"
                      type="text"
                      name="sell_naira_rate"
                      value={this.state.sell_naira_rate}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label>Fee Percent.</label>
                    <input
                      class="form-control"
                      type="text"
                      name="fee_percent"
                      value={this.state.fee_percent}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label>Minimum to Buy</label>
                    <input
                      class="form-control"
                      type="text"
                      name="minimum_to_buy"
                      value={this.state.minimum_to_buy}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Minimum to Sell</label>
                    <input
                      class="form-control"
                      type="text"
                      name="minimum_to_sell"
                      value={this.state.minimum_to_sell}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Minimum to Transfer</label>
                    <input
                      class="form-control"
                      type="text"
                      name="minimum_to_transfer"
                      value={this.state.minimum_to_transfer}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Minimum to Withdraw</label>
                    <input
                      class="form-control"
                      type="text"
                      name="minimum_to_withdraw"
                      value={this.state.minimum_to_withdraw}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Bank Deposit Fee Cap</label>
                    <input
                      class="form-control"
                      type="text"
                      name="bank_deposit_fee_cap"
                      value={this.state.bank_deposit_fee_cap}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Bank Withdraw Fee Cap</label>
                    <input
                      class="form-control"
                      type="text"
                      name="bank_withdraw_fee_cap"
                      value={this.state.bank_withdraw_fee_cap}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Bank Withdraw Processing Type</label>
                    <select
                      class="form-control"
                      name="bank_withdraw_processing_method"
                      value={this.state.bank_withdraw_processing_method}
                      onChange={this.handleChange}
                    >
                      <option value="">select</option>
                      <option value="auto">Automatic</option>
                      <option value="manual">Manually</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Confirm PIN</label>
                    <input
                      class="form-control"
                      type="password"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary "
                      disabled={this.state.isSubmitting}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TradeInfo;
