import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './assets/index.css';
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class ReferralBonuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonuses: [],
      isLoading: true,

      email: "",

      start_date: "",
      end_date: "",

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    this.setState({ start_date: current_date, end_date: next_date }, () => {
      this.getReferralBonuses();
    });
  }

  getReferralBonuses = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/referral_bonuses", {
        params: {
          token: localStorage.getItem("supportToken"),
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            bonuses: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getDeposits();
    });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2>Referral Bonuses</h2>
            <div className=" form-inline float-right">
              <label class="form-control-label">Start Date</label>
              <input
                class="form-control form-control-sm ml-2"
                type="date"
                name="start_date"
                value={this.state.start_date}
                onChange={this.handleChange}
              />
              <label class="form-control-label ml-2">End Date</label>
              <input
                class="form-control form-control-sm ml-2"
                type="date"
                name="end_date"
                value={this.state.end_date}
                onChange={this.handleChange}
              />
              <button
                className="btn btn-primary btn-sm ml-2"
                onClick={() => {
                  this.getReferralBonuses();
                }}
              >
                Filter
              </button>
            </div>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.bonuses.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead>
                            <th>Date</th>
                            <th>Account</th>
                            <th>Refer By</th>
                            <th>Amount</th>
                            <th>Reference</th>
                          </thead>
                          <tbody>
                            {this.state.bonuses.map((bonus) => {
                              return (
                                <tr>
                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(bonus.created_at).format("MMM Do")}

                                        <br />
                                        {moment(bonus.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td">
                                    <div>
                                      <a target="_blank" href={"/user/" + bonus.email}>
                                        {bonus.email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <div>
                                      <a target="_blank" href={"/user/" + bonus.referee}>
                                        {bonus.referee}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="">
                                      <NumberFormat
                                        value={bonus.amount}
                                        className="card-bal-your text-success"
                                        displayType={"text"}
                                        prefix={"+NGN "}
                                        decimalScale={3}
                                        thousandSeparator={true}
                                      />
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <p>{bonus.trx_id}</p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No data found!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>
      </div>
    );
  }
}

export default ReferralBonuses;
