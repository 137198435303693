import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class NINLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nin: "",
      user_nin: null,
      user_info: null,
    };
  }

  handleChange = (evt) => {
    this.setState({ nin: evt.target.value });
  };

  lookupNIN = () => {
    //bind this to self
    if (this.state.nin.length < 5) {
      alert("Enter a valid nin");
      return false;
    }
    this.setState({
      isLoading: true,
      user_nin: null,
    });

    axios
      .get(ConstantVar.API_BASE_URL + "/admin/nin_lookup", {
        params: {
          token: localStorage.getItem("supportToken"),
          nin: this.state.nin,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            user_nin: response.data.data.nin,
            user_info: response.data.data.info,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        alert(err.response.data.message);
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">NIN LookUp</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="nin"
                className="form-control"
                placeholder="Enter NIN"
                value={this.state.nin}
                onChange={this.handleChange}
                type="text"
              />
            </div>
            <button onClick={this.lookupNIN} className="btn btn-success">
              lookup
            </button>
          </div>

          {this.state.isLoading ? (
            <div class="text-center">
              <label>Loading....</label>
            </div>
          ) : (
            ""
          )}

          {this.state.user_nin !== null ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">NIN</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Middle Name</th>
                  <th scope="col">DOB</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.user_nin.nin}</td>
                  <td>{this.state.user_nin.firstname}</td>
                  <td>{this.state.user_nin.lastname}</td>
                  <td>{this.state.user_nin.middlename}</td>
                  <td>{this.state.user_nin.birthdate}</td>
                  <td>{this.state.user_nin.phone}</td>
                </tr>

                {this.state.user_info !== null ? (
                  <tr>
                    <td colSpan="7" align="center">
                      Already used by
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {this.state.user_info !== null ? (
                  <tr>
                    <td> {this.state.user_info.nin}</td>
                    <td>{this.state.user_info.fname}</td>
                    <td>{this.state.user_info.lname}</td>
                    <td>{this.state.user_info.email}</td>
                    <td>{this.state.user_info.dob}</td>
                    <td>{this.state.user_info.phone}</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default NINLookup;
