import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

class TransactedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      startDate: null,
      endDate: null,
      total: 0,
      reports: [],
      generate: null,
    };
  }
  componentDidMount() {
    this.getTransactedUsers();
  }

  getTransactedUsers = () => {
    this.setState({
      isSubmitting: true,
      generate: null,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/app", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "transacted",
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            total: response.data.data,
            isSubmitting: false,
          });
        }
      });
  };

  getTransactedUsersReport = () => {
    this.setState({
      generate: 0,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/app", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "transacted-report",
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            reports: response.data.data,
            generate: 1,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getFileName = () => {
    let from = this.state.startDate
      ? `_${this.state.startDate}_${this.state.endDate}`
      : `_${moment().format("YYYY-MM-DD")}`;

    return `Transacted_users${from}.csv`;
  };

  render() {
    return (
      <div className="">
        <div className="">
          <hr className="dash-hr" />

          <div className="p-5">
            <div className="form-inline">
              <label>Start Date </label>{" "}
              <input
                type="date"
                name="startDate"
                value={this.state.startDate}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <label>End Date </label>{" "}
              <input
                type="date"
                name="endDate"
                value={this.state.endDate}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <button className="btn btn-primary" onClick={this.getTransactedUsers}>
                Continue
              </button>
            </div>

            {!this.state.isSubmitting ? (
              <div className="table-responsive mt-5 p-5">
                <strong>
                  <address
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    <NumberFormat
                      value={this.state.total}
                      displayType={"text"}
                      thousandSeparator={","}
                      className="h1"
                      suffix=" Users"
                    />
                  </address>
                </strong>

                <p>
                  {this.state.generate === null && this.state.total > 0 ? (
                    <a href="#" onClick={this.getTransactedUsersReport}>
                      Generate Report
                    </a>
                  ) : this.state.generate === 0 ? (
                    <div className="p-5 text-center">
                      <h5>Generating....</h5>
                    </div>
                  ) : this.state.generate === 1 ? (
                    <CSVLink
                      data={this.state.reports}
                      className="btn btn-info"
                      filename={this.getFileName()}
                    >
                      Download report
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              <div className="p-5 text-center">
                <h3>Loading....</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TransactedUsers;
