import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class ManualTokenWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdraws: [],
      isLoading: true,
      email: "",
      status: "all",
      startDate: moment().subtract(7, "days").format("YYYY MM DD"),
      endDate: moment().format("YYYY MM DD"),

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,

      withdrawId: null,
      pin: "",
      type: "",
      url: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getWithdraws();
  }

  getWithdraws = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/token_withdraws", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
          manually_verified: 1,
          status: this.state.status,

          start_date: this.state.startDate,
          end_date: this.state.endDate,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            withdraws: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  markWithdrawalAsSuccessful = (trx_id) => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/withdrawal/mark", {
        token: localStorage.getItem("supportToken"),
        trx_id,
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            isLoading: false,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getWithdraws();
    });
  };

  approveWithdraw = (e) => {
    e.preventDefault();

    if (this.state.pin.length !== 6) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/token_withdraw/apply", {
        token: localStorage.getItem("supportToken"),
        withdraw_id: this.state.withdrawId,
        pin: this.state.pin,
        type: this.state.type,
        url: this.state.url,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.$("#approveWithdrawModal").modal("hide");
          alert(response.data.message);
          this.setState({
            isSubmitting: false,
          });
          this.getWithdraws();
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2>Manually Verified Token Withdraws</h2>
            <div className=" form-inline float-right">
              <button
                type="button"
                onClick={() => {
                  window.$("#filterModal").modal("show");
                }}
                class="btn btn-primary btn-sm"
              >
                Filter
              </button>
            </div>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.withdraws.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>Asset</th>
                            <th>Network</th>
                            <th>Address</th>
                            <th>Memo</th>
                            <th>Amount</th>
                            <th>USD Value</th>
                            <th>
                              <b>
                                Amount <br /> To withdraw
                              </b>
                            </th>
                            <th>Approve</th>
                            <th>Status</th>
                            <th>Manually processed</th>
                          </thead>
                          <tbody>
                            {this.state.withdraws.map((withdraw, index) => {
                              return (
                                <tr>
                                  <th>{index + 1}</th>

                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(withdraw.created_at).format("MMM Do")}

                                        <br />
                                        {moment(withdraw.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td" style={{ width: "9%" }}>
                                    <div>
                                      <a target="_blank" href={"/user/" + withdraw.email}>
                                        {withdraw.email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="">
                                      <p>{withdraw.asset}</p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="">
                                      <p>{withdraw.network}</p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <p>{withdraw.address}</p>
                                  </td>
                                  <td className="dash-td">
                                    <p>{withdraw.memo}</p>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="text-dark">
                                      <p>{withdraw.amount}</p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="text-dark">
                                      <p>
                                        <b>
                                          {" "}
                                          {(withdraw.amount * withdraw.price).toFixed(2)}
                                        </b>
                                      </p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="text-dark">
                                      <p className="text-warning">
                                        {(
                                          parseFloat(withdraw.amount) +
                                          parseFloat(withdraw.fee)
                                        ).toFixed(8)}
                                      </p>
                                    </h3>
                                  </td>

                                  <td className="dash-td">
                                    {withdraw.approved === 0 &&
                                    withdraw.status === "-0" ? (
                                      <div>
                                        <button
                                          className=" btn btn-sm btn-primary"
                                          onClick={() => {
                                            this.setState({
                                              withdrawId: withdraw.id,
                                              pin: "",
                                              type: "approve",
                                            });
                                            window
                                              .$("#approveWithdrawModal")
                                              .modal("show");
                                          }}
                                        >
                                          Approve
                                        </button>

                                        <button
                                          className=" btn btn-sm btn-primary ml-3"
                                          onClick={() => {
                                            this.setState({
                                              withdrawId: withdraw.id,
                                              pin: "",
                                              type: "reverse",
                                            });
                                            window
                                              .$("#approveWithdrawModal")
                                              .modal("show");
                                          }}
                                        >
                                          Reverse
                                        </button>
                                      </div>
                                    ) : (
                                      <button
                                        className=" btn btn-sm text-white"
                                        style={{ width: "100px", background: "green" }}
                                      >
                                        Approved
                                      </button>
                                    )}
                                  </td>

                                  <td className="dash-td">
                                    {withdraw.status === "0" ? (
                                      <div>
                                        <button
                                          className=" btn btn-sm btn-primary"
                                          onClick={() => {
                                            this.setState({
                                              withdrawId: withdraw.id,
                                              type: "mark_as_paid",
                                              pin: "",
                                            });
                                            window
                                              .$("#approveWithdrawModal")
                                              .modal("show");
                                          }}
                                        >
                                          Mark as Paid
                                        </button>

                                        <button
                                          className=" btn btn-sm btn-primary ml-3"
                                          onClick={() => {
                                            this.setState({
                                              withdrawId: withdraw.id,
                                              type: "reverse",
                                              pin: "",
                                            });
                                            window
                                              .$("#approveWithdrawModal")
                                              .modal("show");
                                          }}
                                        >
                                          Reverse
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {withdraw.status === "3" ||
                                    withdraw.status === "-3" ||
                                    withdraw.status === "1" ? (
                                      <button
                                        className="success btn btn-sm text-dark"
                                        style={{
                                          backgroundColor: "yellow",
                                          width: "100px",
                                        }}
                                      >
                                        processing
                                      </button>
                                    ) : withdraw.status === "2" ? (
                                      <button
                                        className="success btn btn-sm btn-danger"
                                        style={{ width: "100px" }}
                                      >
                                        failed
                                      </button>
                                    ) : withdraw.status === "4" ? (
                                      <button
                                        className="success btn btn-sm btn-success"
                                        style={{ width: "100px" }}
                                      >
                                        successful
                                      </button>
                                    ) : withdraw.status === "5" ? (
                                      <button
                                        className="success btn btn-sm btn-warning"
                                        style={{ width: "100px" }}
                                      >
                                        cancelled
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {withdraw.status !== "5" &&
                                      withdraw.status !== "4" && (
                                        <button
                                          onClick={async () => {
                                            if (
                                              window.confirm(
                                                "Do you want to mark this withdrawal as processed?"
                                              )
                                            ) {
                                              this.markWithdrawalAsSuccessful(
                                                withdraw.trx_id
                                              );
                                            }
                                          }}
                                          className="success btn btn-sm btn-success"
                                        >
                                          Mark as processed
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No withdrawal yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>

        <div
          class="modal fade"
          id="approveWithdrawModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="approveWithdrawModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="approveWithdrawModalLabel">
                  {this.state.type === "approve" ? "Confirm Withdraw" : "Mark ss Paid"}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.approveWithdraw} autoComplete="off">
                <div class="modal-body">
                  {this.state.type === "mark_as_paid" ? (
                    <div class="form-group">
                      <label>Blockchain Hash URL</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Blockchain Hash URL"
                        name="url"
                        value={this.state.url}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">
                  Filter Withdrawals
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  this.setState(
                    {
                      currentPage: 1,
                      paginationLoaded: false,
                    },
                    () => {
                      window.$("#filterModal").modal("hide");
                      this.getWithdraws();
                    }
                  );
                }}
                autoComplete="off"
              >
                <div class="modal-body">
                  <div className=" form-group">
                    <label> Per Page</label>
                    <input
                      type="number"
                      name="perPage"
                      placeholder="Per Page"
                      value={this.state.perPage}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label> Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="completed">Completed</option>
                      <option value="pending">Pending</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className=" form-group">
                    <label> Email Address</label>
                    <input
                      className="form-control"
                      placeholder="Email Address:"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualTokenWithdraw;
