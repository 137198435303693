import React, { Component } from "react";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      archived: [],
      open: [],
      category_tickets: [],
      isLoading: true,
      page: "overview",
      ticket: {
        replies: [],
      },
      positioned: null,
      view: "category",
      message: "",
      showUpload: false,
      attached_image: null,

      can_open_ticket: true,


      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
    this.handleReturn = this.handleReturn.bind(this);
    this.switchType = this.switchType.bind(this);
  }

  handleReturn = () => {
    this.setState({
      isLoading:true,
      tickets: [],
      archived: [],
      open: [],
      category_tickets: [],
      view: "new",
      page: "overview",
      closePagination: false,
    });

    // this.componentDidMount()
    // this.loadArchivedTickets();
    this.loadTickets();
    // this.loadOpenTickets();
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;

    this.setState({ currentPage: selectedPage + 1 }, () => {
      console.log("this.state.view::::: ",this.state.view)
      if(this.state.view === "archive"){
        this.loadArchivedTickets()
      }else{
        this.loadTickets()
      }
    });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({isLoading:true})
    this.loadTickets()
  
  }

  switchType(pageType){
    // const pageType = this.state.view
    if(pageType === "archive"){
      this.setState({
        view: "archive",
      },()=>{
        this.loadArchivedTickets()
      })
    }else if(pageType === "category"){
      this.setState({
        view: "category",
        isLoading:true,
      
      })
    }
  }

  openTicket = () => {
    let searchText = this.props.location.search;
    if (searchText !== "" && this.state.can_open_ticket === true) {
      let ticketID = searchText.split("?ticket_id=")[1];
      let tickets = this.state.tickets || [];
      if (tickets.length > 0) {
        for (let i = 0; i < tickets.length; i++) {
          const ticket = tickets[i];
          if (ticket.id === ticketID) {
            this.setState({
              page: "chatPage",
              positioned: i,
              ticket: ticket,
              can_open_ticket: false,
            });
            return;
          }
        }
      }
    }
  };

  loadTickets = () => {
    let self = this;
    this.setState({ tickets: [] ,isLoading:true });

    axios
      .get(ConstantVar.API_BASE_URL + "/admin/tickets", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true")
          var all_tickets = response.data.data;
        // .sort(
        //   (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
        // );

        var ticket_categories = localStorage.getItem("ticket_categories");

        var parsedticket_categories = JSON.parse(ticket_categories);
        var categories = new Set(parsedticket_categories);

        // // Filter tickets based on the categories using Set for constant-time lookup
        var filteredTickets = all_tickets.filter((ticket) =>
          categories.has(ticket.category)
        );

        self.setState(
          {
            tickets: response.data.data,
            isLoading: false,
            category_tickets: filteredTickets,
          },
          () => {
            this.openTicket();
          }
        );
      });
  };

  loadArchivedTickets = () => {
    let self = this;
    this.setState({ archived: [] ,isLoading:true });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/archived_tickets", {
        params: {
          token: localStorage.getItem("supportToken"),
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
        },
      })
      .then((response) => {
        if (response.data.status === "true")
          self.setState(
            {
              archived: response.data.data.data,
              _from: response.data.data.pagination.from,
              _to: response.data.data.pagination.to,
              isLoading: false,
            },
          );
          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
      });
  };

  // loadOpenTickets = () => {
  //   let self = this;
  //   this.setState({ open: [],isLoading:true });

  //   axios
  //     .get(ConstantVar.API_BASE_URL + "/admin/open_tickets", {
  //       params: {
  //         token: localStorage.getItem("supportToken"),
  //         per_page: this.state.perPage,
  //         current_page: this.state.currentPage,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data.status === "true")
  //         self.setState(
  //           {
  //             open: response.data.data.data,
  //             _from: response.data.data.pagination.from,
  //             _to: response.data.data.pagination.to,
  //             isLoading: false,
  //           },
  //         );
  //         if (!this.state.paginationLoaded) {
  //           let total = response.data.data.pagination.total;
  //           self.setState({
  //             pageCount: Math.ceil(total / this.state.perPage),
  //             currentPage: response.data.data.pagination.currentPage,
  //             paginationLoaded: true,
  //             totalEntries: total,
  //           });
  //         }
  //     });
  // };

  handleReplyReturn = () => {
    switch (this.state.view) {
      case "new":
        this.setState({
          view: "new",
          page: "overview",
        });
        break;

      case "archive":
        this.setState({ view: "archive", page: "overview" });
        break;

      case "category":
        this.setState({ view: "category", page: "overview" });
        break;
      case "open":
        this.setState({ view: "open", page: "overview" });
        break;
    }
    this.componentDidMount();
  };

  sendReply = () => {
    let self = this;
    if (this.state.showUpload) {
      if (this.state.attached_image === null) {
        alert("Image upload is required");
        return;
      }
    } else {
      if (this.state.message.length < 3) {
        alert("Message is required");
        return;
      }
    }
    this.setState({
      isSubmitting: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/ticket/reply", {
        token: localStorage.getItem("supportToken"),
        email: this.state.ticket.email,
        message: this.state.message,
        message_option: this.state.message_option,
        ticket_id: this.state.ticket.id,
        upload_image: this.state.showUpload,
        attached_image: this.state.attached_image,
      })
      .then((response) => {
        if (response.data.status === "true") {
          let tickets = self.state.tickets;
          let ticket = self.state.ticket;

          ticket.replies.unshift({
            message: this.state.upload_image
              ? "Image attached"
              : this.state.message,
            created_at: moment().toString(),
            is_agent: 1,
          });
          tickets[self.state.positioned] = ticket;

          self.setState({
            tickets: tickets,
            message: "",
            message_option: "",
            upload_image: false,
            attached_image: null,
            isSubmitting: false,
          });
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        this.setState({
          isSubmitting: false,
        });
      });
  };

  closeTicket = () => {
    let self = this;

    var canClose = window.confirm(
      "Are you sure you want to close this ticket?"
    );
    if (canClose) {
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/ticket/close", {
          token: localStorage.getItem("supportToken"),
          ticket_id: this.state.ticket.id,
        })
        .then((response) => {
          if (response.data.status === "true") {
            self.setState({
              page: "overview",
            });
            this.loadTickets();
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        });
    }
  };

  archiveTicket = (ticket) => {
    this.setState({ isSubmitting: true });

    var answer = window.confirm(
      "Are you sure you need to Archive this ticket?"
    );
    if (!answer) {
      this.setState({ ...this.state, isSubmitting: false });
      return;
    }
    axios
      .post(ConstantVar.API_BASE_URL + "admin/ticket/archive", {
        token: localStorage.getItem("supportToken"),
        ticketId: ticket,
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.loadArchived();
          this.loadNewTickets();
          this.setState({ isSubmitting: false, page: "overview" });
        }
      });
  };

  createTicket = (e) => {
    let self = this;

    e.preventDefault();

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/ticket", {
        token: localStorage.getItem("supportToken"),
        email: this.state.email_ct,
        message: this.state.message_ct,
        subject: this.state.subject_ct,
        message_option: this.state.message_option_ct,
        category: this.state.category_ct,
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            message_ct: "",
            message_option_ct: "",
            email_ct: "",
            subject_ct: "",
            page: "overview",
          });
          self.loadTickets();
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  onDrop = (evt) => {
    let fileInput = evt.target;
    let self = this;
    if (fileInput.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      var url = window.URL || window.webkitURL;
      var image = new Image();
      image.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var imgWidth = image.width;
        var imgHeight = image.height;
        if (image.width > 400) {
          imgWidth = image.width / 2;
          imgHeight = image.width / 2;
        }
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        ctx.drawImage(image, 0, 0, imgWidth, imgHeight);
        self.setState({
          attached_image: canvas.toDataURL(),
        });
      };
      image.onerror = function () {
        alert("Upload Error", "Invalid image");
      };
      image.src = url.createObjectURL(fileInput.files[0]);
    } else {
      alert("Upload Error", "Not an image");
    }
  };

  ticketsPage = () => {
    let tickets = this.state.tickets || [];
    let category_tickets = this.state.category_tickets || [];
    let archive = this.state.archived || [];
    // let open = this.state.open || [];

    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination py-4"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div className="col">
        {this.state.view == "new" ? (
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                Tickets ({this.state.tickets.length}){" "}
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={()=>this.switchType("category")}
                  >
                    My Tickets
                  </a>
                </small>
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={()=>this.switchType("archive")}

                  >
                    Archive
                  </a>
                </small>
               
                <button
                  className="btn btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      page: "createTicketPage",
                    })
                  }
                >
                  <i className="fa fa-plus fa-2x"></i>
                </button>
              </h2>
            </div>
            <hr className="dash-hr" />
            {this.state.isLoading ? (
              <div className="text-center py-3">Loading...</div>
            ) : (
              <div className="col-12">
                {!this.state.isLoading ? (
                  tickets.map((ticket, index) => {
                    return (
                      <div
                        key={index}
                        // className=""
                        style={{
                          margin: 20,
                          padding: "20",
                          borderBottom: "1px solid #cccccc",
                          cursor: "pointer",
                        }}
                        className={
                          ticket.replies[0].is_agent !== 1
                            ? "bg-light p-3 card card-style "
                            : "card card-style "
                        }
                        onClick={() =>
                          this.setState({
                            ticket: ticket,
                            page: "chatPage",
                            positioned: index,
                          })
                        }
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <h3 className="text-warning">{ticket.category}</h3>
                            <p>
                              <span className="font-weight-bold">
                                <Link
                                  style={{ color: "midnightblue" }}
                                  to={"/user/" + ticket.email}
                                >
                                  {ticket.email}
                                </Link>
                              </span>{" "}
                              - {ticket.phone}
                            </p>

                            <p>
                              <span className="font-weight-bold">
                                {ticket.replies[0].is_agent === 1
                                  ? "Reply:"
                                  : "Message"}
                              </span>{" "}
                              <address>
                                {ticket.replies[0].is_image === 1 ? (
                                  "Image attached"
                                ) : (
                                  <div className="itm-title">
                                    {ticket.replies[0].message}
                                  </div>
                                )}
                                <div className="text-right">
                                  <small>
                                    {moment(
                                      ticket.replies[0].created_at
                                    ).fromNow()}
                                  </small>
                                </div>
                              </address>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p style={{ padding: 100 }}>Loading...</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : this.state.view == "archive" ? (
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm m-2" onClick={this.handleReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Archived ({archive.length}){" "}
              </h2>
            </div>
            <div className="pl-2 ml-5 ">
              {!this.state.isLoading && <>{paginationElement}</>}
            </div>
            <hr className="dash-hr" />

            {this.state.isLoading ? (
              <div className="text-center py-3">Loading...</div>
            ) : (
              <div className="col-12">
                {!this.state.isLoading ? (
                  archive.map((ticket, index) => {
                    return (
                      <div
                        key={index}
                        // className=""
                        style={{
                          margin: 20,
                          padding: "20",
                          borderBottom: "1px solid #cccccc",
                          cursor: "pointer",
                        }}
                        className={
                          ticket.replies[0].is_agent !== 1
                            ? " card card-style bg-light p-3"
                            : "card card-style"
                        }
                        onClick={() =>
                          this.setState({
                            ticket: ticket,
                            page: "chatPage",
                            positioned: index,
                          })
                        }
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <h3 className="text-warning">{ticket.category}</h3>
                            <p>
                              <span className="font-weight-bold">
                                <Link
                                  style={{ color: "midnightblue" }}
                                  to={"/user/" + ticket.email}
                                >
                                  {ticket.email}
                                </Link>
                              </span>{" "}
                              - {ticket.phone}
                            </p>

                            <p>
                              <span className="font-weight-bold">
                                {ticket.replies[0].is_agent === 1
                                  ? "Reply:"
                                  : "Message"}
                              </span>{" "}
                              <address>
                                {ticket.replies[0].is_image === 1 ? (
                                  "Image attached"
                                ) : (
                                  <div className="itm-title">
                                    {ticket.replies[0].message}
                                  </div>
                                )}
                                <div className="text-right">
                                  <small>
                                    {moment(
                                      ticket.replies[0].created_at
                                    ).fromNow()}
                                  </small>
                                </div>
                              </address>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p style={{ padding: 100 }}>Loading...</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ): this.state.view == "category" ? (
          <div className="card shadow col m-2 ">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm m-2" onClick={this.handleReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                My Tickets ({this.state.category_tickets.length}){" "}
                <small className="p-3">
                  <a
                    href="#"
                    className="text-primary-small user-select-none"
                    onClick={()=>this.switchType("archive")}

                  >
                    Archive
                  </a>
                </small>
               
                <button
                  className="btn btn-sm float-right"
                  onClick={() =>
                    this.setState({
                      page: "createTicketPage",
                    })
                  }
                >
                  <i className="fa fa-plus fa-2x"></i>
                </button>
              </h2>
            </div>

            <hr className="dash-hr" />
            {this.state.isLoading ? (
              <div className="text-center py-3">Loading...</div>
            ) : (
              <div
                className="col-12  mb-5 "
                style={{ overflowY: "scroll", minHeight: "70%" }}
              >
                {!this.state.isLoading ? (
                  <div>
                    {category_tickets.length > 0 ? (
                      category_tickets.map((ticket, index) => {
                        return (
                          <div
                            key={index}
                            className="bg-light card-style card"
                            style={{
                              margin: 20,
                              padding: "20",
                              borderBottom: "1px solid #cccccc",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.setState({
                                ticket: ticket,
                                page: "chatPage",
                                positioned: index,
                              })
                            }
                          >
                            <div className="row ">
                              <div className="col-sm-12">
                                <h3 className="text-warning">
                                  {ticket.category}
                                </h3>
                                <p>
                                  <span className="font-weight-bold">
                                    <Link
                                      style={{ color: "midnightblue" }}
                                      to={"/user/" + ticket.email}
                                    >
                                      {ticket.email}
                                    </Link>
                                  </span>{" "}
                                  - {ticket.phone}
                                </p>

                                <div>
                                  <span className="font-weight-bold">
                                    {ticket.replies[0]?.is_agent === 1
                                      ? "Reply:"
                                      : "Message"}
                                  </span>{" "}
                                  <address>
                                    {ticket.replies[0]?.is_image === 1 ? (
                                      "Image attached"
                                    ) : (
                                      <span className="itm-title">
                                        {ticket.replies[0]?.message}
                                      </span>
                                    )}
                                    <div className="text-right d-flex justify-content-between align-items-center">
                                      <small>
                                        {moment(ticket.updated_at).fromNow()}
                                      </small>
                                    </div>
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="">
                        <h3>
                          No assigned ticket, Go to{" "}
                          <a href="#" onClick={this.handleReturn}>
                            General tickets
                          </a>
                        </h3>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <p style={{ padding: 100 }}>Loading...</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  chatPage = () => {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button className="btn btn-sm" onClick={this.handleReplyReturn}>
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                {this.state.ticket.email}
              </h2>{" "}
              <button
                className="btn btn-danger btn-sm float-right"
                disabled={this.state.isSubmitting}
                onClick={this.closeTicket}
              >
                Close Ticket
              </button>
              <div className="mb-3 mt-3">
                <h3 className="text-warning">
                  {this.state.ticket.category}
                  {" - "} <small>{this.state.ticket.subject}</small>
                </h3>
                <div></div>
              </div>
            </div>
            <hr className="dash-hr" />

            <div className="row">
              <div className="col-12">
                <div className="container p-3">
                  <div className="form-group mt-3">
                    <label
                      className={
                        !this.state.showUpload ? "text-dark" : "text-muted"
                      }
                      onClick={() => {
                        this.setState({
                          showUpload: false,
                        });
                      }}
                    >
                      Reply user
                    </label>{" "}
                    |{" "}
                    <label
                      className={
                        this.state.showUpload ? "text-dark" : "text-muted"
                      }
                      onClick={() => {
                        this.setState({
                          showUpload: true,
                        });
                      }}
                    >
                      Upload Image
                    </label>
                    {this.state.showUpload ? (
                      <div className="form-group mt-3">
                        <label>Attach an image</label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          onChange={this.onDrop}
                        />
                      </div>
                    ) : (
                      <textarea
                        className="form-control"
                        placeholder="Reply here..."
                        name="message"
                        rows="8"
                        onChange={this.handleChange}
                        value={this.state.message}
                      ></textarea>
                    )}
                    <select
                      className="p-2"
                      name="message_option"
                      onChange={this.handleChange}
                      value={this.state.message_option}
                    >
                      <option value="">Message Option</option>
                      <option value="allow_upload">Allow Upload</option>{" "}
                    </select>
                    <button
                      className="mt-2 btn  btn-primary float-right"
                      onClick={this.sendReply}
                      disabled={this.state.isSubmitting}
                    >
                      Send
                    </button>
                  </div>

                  <hr />
                  <div>
                    <h4 className="mt-2 mb-3">Conversations</h4>

                    {this.state.ticket.replies.length > 0 ? (
                      this.state.ticket.replies.map((con, index) => {
                        return (
                          <div
                            className="btm-shadow"
                            key={index}
                            style={{
                              backgroundColor: con.is_agent ? "" : "#f8f9fe",
                            }}
                          >
                            <address className="p-3">
                              {con.is_image ? (
                                <img
                                  width="100%"
                                  src={`${ConstantVar.API_BASE_URL}/static/tickets/${this.state.ticket.id}/${con.file_name}.png`}
                                />
                              ) : (
                                con.message
                              )}
                              <div className="text-right text-sm text-muted">
                                {moment(con.created_at).format(
                                  "MMM Do, h:mm:ss a"
                                )}
                              </div>
                            </address>
                            <hr />
                          </div>
                        );
                      })
                    ) : (
                      <label>No conversations yet</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createTicketPage = () => {
    return (
      <div className="row">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h2 className="mb-0">
                <button
                  className="btn btn-sm"
                  onClick={() =>
                    this.setState({
                      page: "overview",
                    })
                  }
                >
                  <i className="fa fa-angle-left fa-3x"></i>
                </button>
                Open Ticket
              </h2>{" "}
            </div>
            <hr className="dash-hr" />

            <div className="row">
              <div className="col-12">
                <div className="container p-3">
                  <form className=" mt-3" onSubmit={this.createTicket}>
                    <div className="form-group">
                      <label>Category</label>
                      <select
                        type="text"
                        className="form-control"
                        name="category_ct"
                        required
                        onChange={this.handleChange}
                        value={this.state.category_ct}
                      >
                        <option value="">Select</option>
                        <option>Verification issues</option>
                        <option>Delayed withdrawals</option>
                        <option>Delayed deposits</option>
                        <option>Technical issues</option>
                        <option>Others</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject here..."
                        name="subject_ct"
                        required
                        onChange={this.handleChange}
                        value={this.state.subject_ct}
                      />
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Reply here..."
                        name="message_ct"
                        rows="8"
                        required
                        onChange={this.handleChange}
                        value={this.state.message_ct}
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label>User Email</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder="User email address"
                        name="email_ct"
                        onChange={this.handleChange}
                        value={this.state.email_ct}
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="p-2"
                        name="message_option_ct"
                        onChange={this.handleChange}
                        value={this.state.message_option_ct}
                      >
                        <option value="">Message Option</option>
                        <option value="allow_upload">Allow Upload</option>{" "}
                      </select>
                      <button
                        className="mt-2 btn  btn-primary float-right"
                        disabled={this.state.isSubmitting}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {this.state.page === "overview"
                  ? this.ticketsPage()
                  : this.state.page === "chatPage"
                  ? this.chatPage()
                  : this.createTicketPage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ticket;
