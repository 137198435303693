import React, { useState } from "react";
import { API_BASE_URL } from "../../constants_var";
import axios from "axios";
const EditGiftCardItem = (prop) => {
  const {
    index,
    cardName,
    cardCountry,
    cardType,
    cardRange,
    cardPrice,
    cardStatus,
    availableRanges,
    callBack,
    cardPriceId,
  } = prop;
  const [shouldEdit, setShouldEdit] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(cardPrice);
  const [currentRange, setCurrentRange] = useState(
    availableRanges.find((value) => value.range_from === cardRange).range_id
  );
  async function changeGiftcardState() {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin/giftcard/deactivate`, {
        priceID: cardPriceId,
        status: cardStatus === 1 ? 0 : 1,
        token: localStorage.getItem("supportToken"),
      });
      callBack();
      setShouldEdit(false);
    } catch (err) {
      alert("An error occured");
    }
  }
  async function updateGifcardInfor() {
    try {
      const response = await axios.post(`${API_BASE_URL}/admin/giftcard/update`, {
        priceID: cardPriceId,
        rangeID: currentRange,
        rate: parseFloat(currentPrice),
        token: localStorage.getItem("supportToken"),
      });
      setShouldEdit(false);
      callBack();
    } catch (err) {
      alert("An error occured");
    }
  }

  return (
    <div className="w-full  flex items-center justify-start">
      <p className="flex-[0.5] font-medium mx-2">{index}</p>
      <p className="flex-[1.2] font-medium mx-2">{cardName}</p>
      <p className="flex-[1] font-medium mx-2">{cardCountry}</p>
      <p className="flex-[1] font-medium mx-2">{cardType}</p>
      <div className="flex-[1.5] font-medium mx-2 border-[1px] border-black/20 rounded-lg overflow-clip">
        <select
          onChange={(e) => {
            setCurrentRange(parseInt(e.target.value));
          }}
          className="focus:outline-none border-none px-2 py-1"
        >
          {availableRanges.map((e) => (
            <option value={e.range_id} selected={e.range_from === cardRange}>
              {e.range_from}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-[1.5] font-medium mx-2 flex items-center ">
        {!shouldEdit ? (
          <p>&#x20A6; {cardPrice}</p>
        ) : (
          <div className="w-[90px] border-[1px] border-black rounded-lg overflow-clip px-1 py-[2px]">
            <input
              type="number"
              className="focus:outline-none border-none"
              value={currentPrice}
              onChange={(e) => {
                console.log(e.target.value);
                setCurrentPrice(parseFloat(e.target.value) ?? 0);
              }}
            />
          </div>
        )}
        {!shouldEdit && (
          <div
            className="flex justify-center items-center mx-3 border-[2px] border-black rounded-lg px-[6px] py-[2px] cursor-pointer"
            onClick={(e) => {
              setShouldEdit(true);
            }}
          >
            <i class="fas fa-edit"></i>
            <p className="mx-1">Edit</p>
          </div>
        )}
      </div>
      <div className="flex-[1]  flex items-start justify-start cursor-pointer">
        <p
          className={`font-medium  w-full text-center text-[13px] mr-3 py-2 rounded-lg text-white ${
            cardStatus === 1 ? "bg-red-500" : "bg-green-500"
          }`}
          onClick={async (e) => {
            await changeGiftcardState();
          }}
        >
          {cardStatus === 1 ? "Deactivate" : "Activate"}
        </p>
      </div>
      <div className="flex-[1] font-light">
        <button
          className="bg-blue-700 text-white px-2 py-2 rounded-xl text-[13px]"
          onClick={async (e) => {
            await updateGifcardInfor();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditGiftCardItem;
