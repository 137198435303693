/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import Deposit from "./deposit.js";
import Withdraw from "./withdraw.js";
import Trade from "./trade.js";
import Fee from "./fee.js";
import App from "./Appstats/index.js";
import FiatReceived from "./fiat_received.js";
import Extra_Fee from "./extra_fee.js";
import GiftCardReport from "./gift_card.js";
import Order from "../Cpanel/order.js";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "deposit",
      verified: true,
    };
  }
  componentDidMount() {}

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Report</h2>
                  </div>

                  <hr />

                  <div>
                    <ul class="nav nav-pills  p-3">
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "deposit" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "deposit" })}
                        >
                          {" "}
                          Deposits
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "withdraw" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "withdraw" })}
                        >
                          {" "}
                          Withdraws
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "trade" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "trade" })}
                        >
                          {" "}
                          Trade
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "fee" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "fee" })}
                        >
                          {" "}
                          Fees
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "extra_fee" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "extra_fee" })}
                        >
                          {" "}
                          Network Fee
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "gift_cards" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "gift_cards" })}
                        >
                          {" "}
                          Gift Cards
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "app" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "app" })}
                        >
                          {" "}
                          App Stats
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "fiat-received" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "fiat-received" })}
                        >
                          {" "}
                          Fiat Received
                        </a>
                      </li>
                    </ul>

                    <div class="p-3">
                      {this.state.tab === "deposit" ? (
                        <Deposit />
                      ) : //
                      this.state.tab === "withdraw" ? (
                        <Withdraw />
                      ) : this.state.tab === "trade" ? (
                        <Trade />
                      ) : this.state.tab === "fee" ? (
                        <Fee />
                      ) : this.state.tab === "extra_fee" ? (
                        <Extra_Fee />
                      ) : this.state.tab === "app" ? (
                        <App />
                      ) : this.state.tab === "gift_cards" ? (
                        <GiftCardReport />
                      ) : this.state.tab === "fiat-received" ? (
                        <FiatReceived />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
