import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

export default class GiftCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gift_cards: [],
      isLoading: true,
      email: "",
      status: "",
      sent_status: null,
      startDate: null,
      endDate: null,
      failedResponses: [
        {
          title: "",
          message: "",
        },
        {
          title: "The picture is not clear. Kindly reupload",
          message:
            "Unfortunately, your gift card submission failed because the picture provided is not clear. Please reupload a clear picture to proceed. Thank you for your cooperation.",
        },
        {
          title: "The card type offer is not available.",
          message:
            "We regret to inform you that the transaction has failed because the card type offer is not currently available. Please check our available offers and try again.",
        },
        {
          title: "No picture uploaded. Kindly take note and reupload",
          message:
            "Your transaction failed because no picture was uploaded. Kindly take note and reupload the required picture to proceed.",
        },
        {
          title: "Card no longer active",
          message: "The card uploaded is no longer active",
        },
      ],
      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,

      gift_card: {},
      otp_sent: false,
      amount_received: 0,
      auth_code: "",
      pin: "",
      isSubmitting: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSubmittedCards();
  }

  handleDownload = (e) => {
    e.preventDefault();
    fetch(e.target.src, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.jpg");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log("Error downloading image:: ", err);
      });
  };

  getSubmittedCards = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/gift_cards", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          status: this.state.status,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            gift_cards: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handleChange = (evt) => {
    if (evt.target.name === "status") {
      if (evt.target.value === "2") {
        this.setState({
          summary:
            "Your transaction was successful. Thank you for the trade! We look forward to serving you again.",
        });
      } else {
        this.setState({
          summary: "",
        });
      }
    }
    this.setState({ [evt.target.name]: evt.target.value });
  };

  statusMessage = {
    0: "Pending",
    1: "InReview",
    2: "Successful",
    3: "Rejected",
    default: "Unknown status",
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getgift_cards();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (
      (this.state.status.toString() === "2" || this.state.status.toString() === "3") &&
      !this.state.summary
    ) {
      alert("You need to add a message before sending");
      return;
    }
    if (this.state.status === "2" || this.state.status === "3") {
      const response = window.confirm(
        this.state.status === "2"
          ? "By clicking continue, you will be crediting this user the transaction value."
          : "By clicking continue, you will be rejecting this user's transaction."
      );
      if (!response) {
        return;
      }
    }
    let card_id = this.state.gift_card.id,
      summary = this.state.summary,
      status = this.state.status;

    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/gift_card", {
        token: localStorage.getItem("supportToken"),
        summary,
        status,
        card_id,
      })
      .then((response) => {
        if (response.data.message == "sucessful") {
          this.setState(
            {
              currentPage: 1,
              paginationLoaded: false,
              status: null,
              summary: null,
              gift_card: {},
            },
            () => {
              this.getSubmittedCards();
              window.$("#cardModal").modal("hide");
            }
          );
        } else {
          console.log("NOT SUCCESSFUL");
        }
      });
  };

  handleViewCard = (gift_card) => {
    this.setState({ gift_card,
                  status:gift_card.status,
                  summary:gift_card.status_summary,
                
                },()=>{
      window.$("#cardModal").modal("show");
    });
  }

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Gift Card Logs</h2>
                    <button
                      className="btn btn-primary ml-auto"
                      onClick={() => {
                        window.$("#filterModal").modal("show");
                      }}
                      style={{ float: "right" }}
                    >
                      Filter
                    </button>
                    <div
                      className="modal fade"
                      id="staffModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className=" form-inline float-right"></div>
                    </div>
                  </div>
                  <hr className="dash-hr" />
                  {!this.state.isLoading ? (
                    <div className="table-responsive">
                      {this.state.gift_cards.length > 0 ? (
                        <div>
                          <div className="pl-2 ml-5">{paginationElement}</div>
                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <thead>
                                <th>S/N</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Card</th>
                                <th>Country</th>
                                <th>Type</th>
                                <th>Card Value</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                              </thead>
                              <tbody>
                                {this.state.gift_cards.map((gift_card, index) => {
                                  return (
                                    <tr key={index}>
                                      <th>{index + 1}</th>
                                      <th scope="row dash-td" style={{ width: "1%" }}>
                                        <div className="media align-items-center">
                                          <p className="text-muted trans-date">
                                            {moment(gift_card.created_at).format(
                                              "MMM Do"
                                            )}

                                            <br />
                                            {moment(gift_card.created_at).format(
                                              "hh:mm A"
                                            )}
                                          </p>
                                        </div>
                                      </th>
                                      <td className="dash-td" style={{ width: "9%" }}>
                                        <div>
                                          <a
                                            target="_blank"
                                            href={"/user/" + gift_card.email}
                                          >
                                            {gift_card.email}
                                          </a>
                                        </div>
                                      </td>

                                      <td className="dash-td">
                                        <p>{gift_card.card_name}</p>
                                      </td>
                                      <td className="dash-td">
                                        <p>{gift_card.country}</p>
                                      </td>
                                      <td className="dash-td">
                                        <p>{gift_card.receipt_type}</p>
                                      </td>

                                      <td className="dash-td">
                                        <NumberFormat
                                          value={gift_card.rate}
                                          className="card-bal-your text-success"
                                          displayType={"text"}
                                          prefix={"$ "}
                                          decimalScale={0}
                                          thousandSeparator={true}
                                        />
                                      </td>
                                      <td className="dash-td">
                                        <span className="card-bal-your text-success px-1">
                                          &#x20A6;
                                        </span>
                                        <NumberFormat
                                          value={gift_card.amount * gift_card.rate}
                                          className="card-bal-your text-success"
                                          displayType={"text"}
                                          // prefix={"&#x20A6 "}
                                          decimalScale={0}
                                          thousandSeparator={true}
                                        />
                                      </td>

                                      <td className="dash-td">
                                        {gift_card.status == 0 ? (
                                          <div>
                                            <button
                                              className="success btn btn-sm btn-secondary"
                                              style={{
                                                backgroundColor: "yellow",
                                              }}
                                            >
                                              Pending
                                            </button>
                                          </div>
                                        ) : gift_card.status == 1 ? (
                                          <button className="success btn btn-sm btn-warning">
                                            In Review
                                          </button>
                                        ) : gift_card.status == 2 ? (
                                          <button
                                            className="success btn btn-sm btn-success"
                                            style={{ width: "100px" }}
                                          >
                                            Successful
                                          </button>
                                        ) : gift_card.status == 3 ? (
                                          <button className="success btn btn-sm btn-danger">
                                            Rejected
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td>
                                        <button
                                          className="success btn btn-sm btn-primary"
                                          style={{ width: "100px" }}
                                          onClick={() => this.handleViewCard(gift_card)}
                                        >
                                          View Card
                                        </button>{" "}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="p-5 ml-5">{paginationElement}</div>
                        </div>
                      ) : (
                        <div className="text-center no-data full-width-data">
                          <p className="text-muted no-trans-text-o">No gift_cards yet!</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-center">
                      <p style={{ padding: 100 }}>Loading...</p>
                    </div>
                  )}
                </div>

                <div
                  className="modal fade"
                  id="filterModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="filterModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="filterModalLabel">
                          Filter gift_cards
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div
                  className="modal fade"
                  id="cardModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="cardModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                  >
                    <div
                      className=" modal-content modal-dialog modal-lg flex flex-col"
                      autoComplete="off"
                    >
                      {/* text and Image section */}
                      <div className="modal-body d-flex modal-lg flex flex-row">
                        <div className="flex-1 ">
                          <h5 className="modal-title pb-2" id="cardModalLabel">
                            Gift Card Details
                          </h5>

                          <div className=" border rounded p-2">
                            <div className=" form-group">
                              <label> Card</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.card_name}
                              </p>
                              {/* <input
                                value={this.state.gift_card.card_name}
                                className="form-control"
                              /> */}
                            </div>

                            <div className="form-group">
                              <label>Country</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.country}
                              </p>
                            </div>
                            <div className="form-group">
                              <label>Type</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.receipt_type}
                              </p>
                            </div>

                            <div className=" form-group">
                              <label>Range</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.card_range}
                              </p>
                            </div>
                            <div className=" form-group">
                              <label>Card Value</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.amount}
                              </p>
                            </div>
                            <div className=" form-group">
                              <label>Rate</label>
                              <p className="border-gray-300 border-[1px] p-2 w-full rounded-md">
                                {this.state.gift_card.rate}
                              </p>
                            </div>
                          </div>
                          <div className="form-group">
                            <label> Status </label>
                            <select
                              className="form-control"
                              name="status"
                              onChange={this.handleChange}
                              disabled={
                                this.state.gift_card.status === 2 ||
                                this.state.gift_card.status === 3
                              }
                              // value={ this.state?.gift_card?.status}
                            >
                              <option selected>
                                {" "}
                                {this.statusMessage[this.state.gift_card.status]}
                              </option>

                              {this.state.gift_card.status != 0 ? (
                                <option value={0}>Pending</option>
                              ) : null}
                              {this.state.gift_card.status != 1 ? (
                                <option value={1}>InReview</option>
                              ) : null}

                              {this.state.gift_card.status != 3 ? (
                                <option value={3}>Rejected</option>
                              ) : null}
                              {this.state.gift_card.status != 2 ? (
                                <option value={2}>Successful</option>
                              ) : null}
                            </select>
                          </div>
                        </div>

                        <div className="flex-1  px-[15px]">
                          <h5 className="modal-title pb-2" id="cardModalLabel">
                            Gift Card Uploads
                          </h5>
                          <div className="pt-2 h-full">
                            {this.state.gift_card.images ? (
                              <div>
                                {this.state.gift_card.images
                                  .split(",")
                                  .map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="cursor-pointer  flex  flex-column w-full h-[300px] mb-[30px] rounded-lg overflow-clip"
                                      >
                                        <img
                                          onClick={(e) => this.handleDownload(e)}
                                          className="object-cover w-full h-[80%]"
                                          src={
                                            ConstantVar.API_BASE_URL +
                                            "/static/gift_cards" +
                                            item
                                          }
                                          alt={`${index}_image`}
                                        />
                                        <div
                                          onClick={(e) => this.handleDownload(e)}
                                          className="w-full h-[50px] my-[10px] rounded-lg flex  bg-blue-800 text-white flex-col items-center justify-center"
                                        >
                                          <p className="text-center ">Download</p>
                                        </div>

                                        {/* <p className="text-primary">
                                    Click on the image to download{" "}
                                  </p> */}
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div className="w-full h-[100px]">
                                <p>No Image(s) uploaded</p>
                              </div>
                            )}

                            <div className="form-group">
                              <label>Customer note</label>
                              <p className="bg-light rounded p-3">
                                {!this.state.gift_card.card_note
                                  ? "No note"
                                  : this.state.gift_card.card_note}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* summary section */}

                      {this.state.status == "3" && this.state.gift_card.status !== 3 && (
                        <div className="mx-[20px]">
                          <p className="font-bold">Rejection Reason (Optional)</p>
                          <select
                            onChange={(e) => {
                              this.setState({
                                summary: JSON.parse(e.target.value).message,
                              });
                            }}
                            className="border-[2px] border-black rounded-md p-2 mt-2"
                          >
                            {this.state.failedResponses.map((value) => {
                              return (
                                <option value={JSON.stringify(value)} className="">
                                  {value.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      <div className="modal-body modal-lg ">
                        <label> Status Summary</label>
                        <textarea
                          className="form-control"
                          placeholder="Type here..."
                          name="summary"
                          rows="4"
                          disabled={
                            this.state.isSubmitting ||
                            this.state.gift_card.status === 2 ||
                            this.state.gift_card.status === 3
                          }
                          required={true}
                          onChange={this.handleChange}
                          value={this.state.summary}
                        ></textarea>
                      </div>

                      {/* button */}
                      <div className="modal-footer modal-md ">
                        <button
                          onClick={(e) => this.handleSubmit(e)}
                          className="btn btn-primary btn-md"
                          disabled={
                            this.state.isSubmitting ||
                            this.state.gift_card.status === 2 ||
                            this.state.gift_card.status === 3
                          }
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
