import React, { Component } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class FiatWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdraws: [],
      isLoading: true,
      email: "",
      withdraw: "",
      status: "all",
      startDate: null,
      endDate: null,

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getWithdraws();
  }

  getWithdraws = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transactions", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
          type: "withdraw",
          status: this.state.status,
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            withdraws: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getWithdraws();
    });
  };

  confirmWithdraw = (e) => {
    e.preventDefault();

    //bind this to self
    let self = this;
    this.setState({
      isSubmitting: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transaction/paid", {
        token: localStorage.getItem("supportToken"),
        reference: this.state.withdraw.reference,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.scrollTo(0, 0);
          this.getWithdraws();
          window.$("#confirmBankWithdrawModal").modal("hide");
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2>Bank Fiat Withdraws</h2>
            <div className=" form-inline float-right">
              <button
                type="button"
                onClick={() => {
                  window.$("#filterModal").modal("show");
                }}
                class="btn btn-primary btn-sm"
              >
                Filter
              </button>
            </div>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.withdraws.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>
                              Beneficiary <br /> Account
                            </th>
                            <th>Account</th>
                            <th>
                              Withdrawal <br />
                              Amount
                            </th>

                            <th>Status</th>
                          </thead>
                          <tbody>
                            {this.state.withdraws.map((withdraw, index) => {
                              return (
                                <tr>
                                  <th>{index + 1}</th>
                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(withdraw.created_at).format("MMM Do")}

                                        <br />
                                        {moment(withdraw.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td" style={{ width: "9%" }}>
                                    <div>
                                      <a target="_blank" href={"/user/" + withdraw.email}>
                                        {withdraw.email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="">
                                      <p>
                                        {" "}
                                        {withdraw.u_account_name} (
                                        {withdraw.u_account_number}) <br />
                                        {withdraw.u_bank_name}
                                      </p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <p>@{withdraw.v_account}</p>
                                  </td>

                                  <td className="dash-td">
                                    <NumberFormat
                                      value={withdraw.amount}
                                      className="card-bal-your text-danger"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </td>

                                  <td className="dash-td">
                                    {withdraw.status === 0 ? (
                                      <div>
                                        {" "}
                                        <button
                                          className="success btn btn-sm text-dark"
                                          style={{
                                            backgroundColor: "yellow",
                                            width: "100px",
                                          }}
                                        >
                                          Pending
                                        </button>
                                        {withdraw.processing_method === "manual" && (
                                          <button
                                            className="success btn btn-sm btn-primary"
                                            onClick={() => {
                                              this.setState({
                                                withdraw: withdraw,
                                                isSubmitting: false,
                                                isLoading: false,
                                              });
                                              window
                                                .$("#confirmBankWithdrawModal")
                                                .modal("show");
                                            }}
                                          >
                                            Mark as paid
                                          </button>
                                        )}
                                      </div>
                                    ) : withdraw.status === 1 ? (
                                      <button
                                        className="success btn btn-sm btn-info"
                                        style={{ width: "100px" }}
                                      >
                                        processing
                                      </button>
                                    ) : withdraw.status === 4 ? (
                                      <div>
                                        <button
                                          className="success btn btn-sm btn-success"
                                          style={{ width: "100px" }}
                                        >
                                          successful
                                        </button>

                                        {withdraw.confirmed == null ? (
                                          <>
                                            {withdraw.ticket_id !== null && (
                                              <a
                                                target="_blank"
                                                href={
                                                  "/tickets?ticket_id=" +
                                                  withdraw.ticket_id
                                                }
                                              >
                                                <button
                                                  className="success btn btn-sm btn-primary"
                                                  style={{ width: "100px" }}
                                                >
                                                  View Ticket
                                                </button>{" "}
                                              </a>
                                            )}
                                          </>
                                        ) : (
                                          <button
                                            className="success btn btn-sm btn-info"
                                            style={{ width: "100px" }}
                                          >
                                            Confirmed
                                          </button>
                                        )}
                                      </div>
                                    ) : withdraw.status === 5 ? (
                                      <button
                                        className="success btn btn-sm btn-secondary"
                                        style={{ width: "100px" }}
                                      >
                                        reversed
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No withdrawal yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>
        <div
          class="modal fade"
          id="confirmBankWithdrawModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmBankWithdrawModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirmBankWithdrawModalLabel">
                  Mark as paid
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.confirmWithdraw} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Beneficiary Account</label>
                    <p className="text-muted">
                      {this.state.withdraw.u_account_name}
                      <br />({this.state.withdraw.u_account_number})<br />
                      {this.state.withdraw.u_bank_name}
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Processed by @{this.state.withdraw.v_account}</label>
                  </div>

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Confirm Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">
                  Filter Withdrawals
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  this.setState(
                    {
                      currentPage: 1,
                      paginationLoaded: false,
                    },
                    () => {
                      window.$("#filterModal").modal("hide");
                      this.getWithdraws();
                    }
                  );
                }}
                autoComplete="off"
              >
                <div class="modal-body">
                  <div className=" form-group">
                    <label> Per Page</label>
                    <input
                      type="number"
                      name="perPage"
                      placeholder="Per Page"
                      value={this.state.perPage}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label> Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="successful">Successful</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className=" form-group">
                    <label> Email Address</label>
                    <input
                      className="form-control"
                      placeholder="Email Address:"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiatWithdraw;
