import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import NumberFormat from "react-number-format";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      wallet_deposits: [],
      bank_deposits: [],
      token_deposits: [],
      startDate: null,
      endDate: null,
      email: "",
    };
  }
  componentDidMount() {
    this.getDepositReport();
  }

  getDepositReport = () => {
    this.setState({
      wallet_deposits: [],
      bank_deposits: [],
      token_deposits: [],
      isSubmitting: true,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/deposit", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          email: this.state.email,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            wallet_deposits: response.data.data.wallet,
            bank_deposits: response.data.data.bank,
            token_deposits: response.data.data.token,
            isSubmitting: false,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getFileName = (filename) => {
    let from = this.state.startDate
      ? `_${this.state.startDate}_${this.state.endDate}`
      : `_${moment().format("YYYY-MM-DD")}`;

    return `${filename}${from}.csv`;
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Deposit</h3>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <hr className="dash-hr" />

              <div className="p-5">
                <div className="form-inline">
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                    placeholder="Filter by email"
                  />
                  <label>Start Date </label>{" "}
                  <input
                    type="date"
                    name="startDate"
                    value={this.state.startDate}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                  />
                  <label>End Date </label>{" "}
                  <input
                    type="date"
                    name="endDate"
                    value={this.state.endDate}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                  />
                  <button className="btn btn-primary" onClick={this.getDepositReport}>
                    Continue
                  </button>
                </div>

                {!this.state.isSubmitting ? (
                  <div className="table-responsive mt-5">
                    <table className="table">
                      <thead>
                        <th>Type</th>
                        <th>No of Transactions</th>
                        <th>Total</th>
                        <th>...</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fiat (Wallet Deposits)</td>
                          <td>
                            {" "}
                            <NumberFormat
                              value={this.state.wallet_deposits.length}
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={parseFloat(
                                this.state.wallet_deposits.reduce(
                                  (a, b) => a + b.amount,
                                  0
                                )
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={","}
                              suffix=" NGN"
                            />
                          </td>
                          <td>
                            <p>
                              {this.state.wallet_deposits.length > 0 ? (
                                <CSVLink
                                  data={this.state.wallet_deposits}
                                  className="btn btn-info"
                                  filename={this.getFileName("wallet_deposits")}
                                >
                                  Download report
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>Fiat (Bank Deposits)</td>
                          <td>
                            {" "}
                            <NumberFormat
                              value={this.state.bank_deposits.length}
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={parseFloat(
                                this.state.bank_deposits.reduce((a, b) => a + b.amount, 0)
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={","}
                              suffix=" NGN"
                            />
                          </td>
                          <td>
                            <p>
                              {this.state.bank_deposits.length > 0 ? (
                                <CSVLink
                                  data={this.state.bank_deposits}
                                  className="btn btn-info"
                                  filename={this.getFileName("bank_deposits")}
                                >
                                  Download report
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <th>Total Fiat Deposits</th>
                          <td>
                            {" "}
                            <NumberFormat
                              value={
                                this.state.wallet_deposits.length +
                                this.state.bank_deposits.length
                              }
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                            />
                          </td>

                          <td>
                            <NumberFormat
                              value={parseFloat(
                                this.state.wallet_deposits.reduce(
                                  (a, b) => a + b.amount,
                                  0
                                ) +
                                  this.state.bank_deposits.reduce(
                                    (a, b) => a + b.amount,
                                    0
                                  )
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                              suffix=" NGN"
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>Token Deposits</th>
                          <td>
                            {" "}
                            <NumberFormat
                              value={this.state.token_deposits.length}
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={parseFloat(
                                this.state.token_deposits.reduce(
                                  (a, b) => a + b.amount * b.price,
                                  0
                                )
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={","}
                              className="h3"
                              suffix=" USD"
                            />
                          </td>
                          <td>
                            <p>
                              {this.state.token_deposits.length > 0 ? (
                                <CSVLink
                                  data={this.state.token_deposits}
                                  className="btn btn-info"
                                  filename={this.getFileName("token_deposits")}
                                >
                                  Download report
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="p-5 text-center">
                    <h3>Loading....</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Deposit;
