/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import Block from "./block/index.js";
import NINLookup from "./nin_lookup.js";
import ResetUserDP from "./reset_user_dp.js";
import IDCardUpload from "./id_upload.js";
import P2pAccount from "./p2pAccount/index.js";
import ReferralBonuses from "./referral_bonuses.js";
import Balance from "./balances.js";
import Fees from "./fee.js";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "p2p_accounts",
    };
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Account</h2>
                  </div>

                  <hr />

                  <ul class="nav nav-pills  p-3">
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "nin-lookup" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "nin-lookup" })}
                      >
                        {" "}
                        NIN Lookup
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "block" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "block" })}
                      >
                        Block
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "reset-user-dp" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "reset-user-dp" })}
                      >
                        Reset User DP
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "id-card-upload" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "id-card-upload" })}
                      >
                        IDCard Upload
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "p2p_accounts" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "p2p_accounts" })}
                      >
                        P2P Accounts
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "referral-bonuses" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "referral-bonuses" })}
                      >
                        Referral Bonuses
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "balances" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "balances" })}
                      >
                        Balances
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "fees" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "fees" })}
                      >
                        Withdrawal Fee
                      </a>
                    </li>
                  </ul>

                  <div class="p-3">
                    {this.state.tab === "nin-lookup" ? (
                      <NINLookup />
                    ) : this.state.tab === "block" ? (
                      <Block />
                    ) : this.state.tab === "reset-user-dp" ? (
                      <ResetUserDP />
                    ) : this.state.tab === "id-card-upload" ? (
                      <IDCardUpload />
                    ) : this.state.tab === "p2p_accounts" ? (
                      <P2pAccount />
                    ) : this.state.tab === "referral-bonuses" ? (
                      <ReferralBonuses />
                    ) : this.state.tab === "fees" ? (
                      <Fees />
                    ) : this.state.tab === "balances" ? (
                      <Balance />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
