import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

class Fees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      startDate: null,
      endDate: null,
      total: 0,
      feeRecords: [],
      generate: null,
    };
  }
  componentDidMount() {
    this.getWithdrawalFee();
  }

  getWithdrawalFee = () => {
    this.setState({
      isSubmitting: true,
      generate: null,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/app", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
          type: "fees",
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            total: response?.data?.data?.reduce((a,b)=> a+b.fee,0),
            feeRecords:response?.data?.data,
            isSubmitting: false,
          });
        }
      });
  };



  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  

  render() {
    return (
    <div className="card shadow">
      <div className="card-header border-0">
        <h2 className="mb-0 d-inline">
          Fees {" "}
            <NumberFormat
              value={this.state.total}
              displayType={"text"}
              thousandSeparator={","}
              prefix="NGN "
              className={`h3 ${this.state.total == 0 ? 'text-danger' :  'text-success'}`}
            />
          </h2>
          <hr className="dash-hr" />

          <div className="p-5">
            <div className="form-inline">
              <label>Start Date </label>{" "}
              <input
                type="date"
                name="startDate"
                value={this.state.startDate}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <label>End Date </label>{" "}
              <input
                type="date"
                name="endDate"
                value={this.state.endDate}
                className="form-control ml-2 mr-2"
                onChange={this.handleChange}
              />
              <button className="btn btn-primary" onClick={this.getWithdrawalFee}>
                Continue
              </button>
            </div>

            {!this.state.isSubmitting ? (
              <div className="table-responsive mt-5 p-5">
                                {this.state.feeRecords.length > 1 ?

               <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Created At</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Amount (NGN)</th>
                      <th scope="col">Fee (NGN)</th>
                      <th scope="col">...</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.feeRecords.map((account) => (
                      <tr>
                        <td>
                          {" "}
                          {moment(account.created_at).format("YYYY MM DD,  H:mm A")}
                        </td>

                        <td>
                          <a target="_blank" href={"/user/" + account.email}>
                            {account.email}
                          </a>
                        </td>
                        <td>{account.amount}</td>
                        <td>{account.fee}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                  : 
                  <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No Fee yet!</p>
                    </div>
  }
              </div>
            ) : (
              <div className="p-5 text-center">
                <h3>Loading....</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Fees;
