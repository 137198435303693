import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import TokenWithdraws from "./token_withdraw.js";
import ManualTokenWithdraws from "./manual_token_withdraw.js";
import WalletFiatWithdraws from "./wallet_fiat_withdraw.js";
import BankFiatWithdraws from "./bank_fiat_withdraw.js";

class Withdrawals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "wallet_fiat",
    };
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Withdraws</h2>
                    <ul class="nav nav-pills  p-3">
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "wallet_fiat" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "wallet_fiat" })}
                        >
                          {" "}
                          Wallet Fiat Withdraws
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "bank_fiat" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "bank_fiat" })}
                        >
                          {" "}
                          Bank Fiat Withdraws
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "auto-token" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "auto-token" })}
                        >
                          Automatic Token Withdraws
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "manually-verified-token"
                              ? "active"
                              : "")
                          }
                          href="#"
                          onClick={() =>
                            this.setState({
                              tab: "manually-verified-token",
                            })
                          }
                        >
                          Manually Verified Token Withdraws
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div>
                    {(() => {
                      if (this.state.tab === "auto-token")
                        return <TokenWithdraws />;
                    })()}

                    {(() => {
                      if (this.state.tab === "manually-verified-token")
                        return <ManualTokenWithdraws />;
                    })()}

                    {(() => {
                      if (this.state.tab === "wallet_fiat")
                        return <WalletFiatWithdraws />;
                    })()}

                    {(() => {
                      if (this.state.tab === "bank_fiat")
                        return <BankFiatWithdraws />;
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Withdrawals;
