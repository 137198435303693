import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class ResetUserDP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  ResetUserDP = () => {
    //validate
    if (this.state.email.length < 5) {
      alert("Enter User email Address ");
      return;
    }
    this.setState({
      isLoading: true,
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/reset_user_dp", {
        token: localStorage.getItem("supportToken"),
        email: this.state.email,
        action: "reset",
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            isLoading: false,
          });
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        alert(err.response.data.message);
      });
  };

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">Reset User DP</h2>
          </div>

          <div className="form-inline p-3">
            <div className="input-group ">
              <input
                required
                name="email"
                className="form-control"
                placeholder="Enter User Email Address"
                value={this.state.email}
                onChange={this.handleChange}
                type="text"
              />
            </div>

            <button
              disabled={this.state.isLoading}
              onClick={() => this.ResetUserDP()}
              className="btn btn-success ml-3"
            >
              Reset Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetUserDP;
