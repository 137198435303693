import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class AppUsages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      startDate: null,
      endDate: null,
      app_usages: [],
    };
  }
  componentDidMount() {
    this.getAppUsagesReport();
  }

  getAppUsagesReport = () => {
    this.setState({
      isSubmitting: true,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/app", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate,
          start_date: this.state.startDate,
          type: "app_usages",
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            app_usages: response.data.data,
            isSubmitting: false,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <div className="p-5">
        <div className="form-inline">
          <label>Start Date </label>{" "}
          <input
            type="date"
            name="startDate"
            value={this.state.startDate}
            className="form-control ml-2 mr-2"
            onChange={this.handleChange}
          />
          <label>End Date </label>{" "}
          <input
            type="date"
            name="endDate"
            value={this.state.endDate}
            className="form-control ml-2 mr-2"
            onChange={this.handleChange}
          />
          <button className="btn btn-primary" onClick={this.getAppUsagesReport}>
            Continue
          </button>
        </div>

        {!this.state.isSubmitting ? (
          <div className="table-responsive mt-5">
            <table className="table">
              <thead>
                <th>S/N</th>
                <th>Date</th>
                <th>No of usages</th>
              </thead>
              <tbody>
                {this.state.app_usages.map((app_usage, index) => {
                  return (
                    <tr>
                      <td>{(index = 1)}</td>
                      <th>{moment(app_usage._date).format("YYYY-MM-DD")}</th>
                      <td>{app_usage._count} users</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="p-5 text-center">
            <h3>Loading....</h3>
          </div>
        )}
      </div>
    );
  }
}

export default AppUsages;
