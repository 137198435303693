import React, { Component } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import * as ConstantVar from "../../constants_var.js";
import personimg from "./assets/img/person.jpg";
import moment from "moment/moment";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        asset_bal: {
          nairaVal: 0,
          usdVal: 0,
        },
      },
      bankAccount: {},
      savingsBal: 0,
    };
  }
  componentDidMount() {
    let savingsBal = this.props.savings_plans.reduce((accumulator, currentValue) => {
      if (currentValue.status === 0 || currentValue.status === 1) {
        return accumulator + currentValue.amount_invested + currentValue.current_yield;
      } else {
        return accumulator;
      }
    }, 0);

    this.setState({
      user: this.props.info,
      savingsBal,
      bankAccount: this.props.bankAccount !== undefined ? this.props.bankAccount : {},
    });
  }

  render() {
    return (
      <div
        className="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="card bg-secondary shadow">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h3 className="mb-0">
                      Wallet Balance:{" "}
                      <NumberFormat
                        decimalScale={2}
                        className="text-success"
                        value={this.state.user.available_bal}
                        prefix={"NGN "}
                        displayType={"text"}
                        thousandSeparator={","}
                      />
                    </h3>
                  </div>

                  <div className="col-4">
                    <h3 className="mb-0">
                      Savings Balance:{" "}
                      <NumberFormat
                        decimalScale={2}
                        className="text-success"
                        value={this.state.savingsBal}
                        prefix={"USDT "}
                        displayType={"text"}
                        thousandSeparator={","}
                      />
                    </h3>
                  </div>

                  <div className="text-right col-4">
                    <h3 className="mb-0">
                      Asset Balance:{" "}
                      <NumberFormat
                        decimalScale={2}
                        className="text-success"
                        value={this.state.user.asset_bal.nairaVal}
                        prefix={"NGN "}
                        displayType={"text"}
                        thousandSeparator={","}
                      />
                      <br />
                      <small>
                        (
                        <NumberFormat
                          decimalScale={2}
                          className=""
                          value={this.state.user.asset_bal.usdVal}
                          prefix={"$"}
                          displayType={"text"}
                          thousandSeparator={","}
                        />
                        )
                      </small>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={this.updateUserDetails}>
                  <h6 className="heading-small text-muted mb-4">User information</h6>
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">
                            First name
                          </label>
                          <input
                            type="text"
                            name="fname"
                            disabled
                            className="form-control bg-readonly form-control-alternative"
                            value={this.state.user.fname}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-email">
                            Last name
                          </label>
                          <input
                            type="text"
                            name="lname"
                            disabled
                            className="form-control bg-readonly form-control-alternative bg-light"
                            value={this.state.user.lname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-phone">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            id="input-phone"
                            name="phone"
                            disabled
                            className="form-control bg-readonly form-control-alternative"
                            value={this.state.user.phone}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-last-name">
                            Email
                          </label>
                          <input
                            type="text"
                            id="input-last-name"
                            className="form-control bg-readonly form-control-alternative"
                            disabled
                            value={this.state.user.email}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-country">
                            Date of birth
                          </label>
                          <input
                            type="text"
                            id="input-dob"
                            name="dob"
                            disabled
                            className="form-control bg-readonly form-control-alternative"
                            value={this.state.user.dob}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-country">
                            Username{" "}
                          </label>
                          <input
                            type="text"
                            id="input-dob"
                            name="username"
                            disabled
                            className="form-control bg-readonly form-control-alternative"
                            value={this.state.user.username}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-country">
                            Bank Account
                          </label>
                          <div
                            className="bg-white form-control"
                            style={{
                              height: "60px",
                            }}
                          >
                            <div>
                              {this.state.bankAccount.account_name} <br />
                              <smal>Account Name</smal>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-country">
                            &nbsp;
                          </label>
                          <div
                            className="bg-white form-control"
                            style={{
                              height: "60px",
                            }}
                          >
                            <div>
                              {this.state.bankAccount.account_number} {" "}
                              {this.state.bankAccount.bank_name}  <br />
                              <smal>Account Number</smal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="card card-profile shadow">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-3 col-sm-6 order-sm-4 order-lg-2"> */}
                  <div className="col-sm-6 order-sm-4 order-lg-2">
                    <div className="card-profile-image">
                      <a href="#">
                        <img
                          alt="dp"
                          className="rounded-circle"
                          src={
                            // this.state.user.dp_uploaded_at &&
                            ConstantVar.API_BASE_URL +
                            "/static/profile_pics/" +
                            this.state.user.username +
                            "/dp.png" 
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = personimg;
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-0 pt-4 mt-5">
                  <div className="text-center">
                    <h3 className="margin-proname" style={{ paddingTop: 30 }}>
                      {this.state.user.fname} {this.state.user.lname}
                    </h3>
                    <div className="font-weight-300">{this.state.user.email}</div>
                    <div className="mt-2 font-weight-bold">
                      {this.state.user.nin || this.state.user.bvn}
                    </div>
                    <div className="font-weight-300">{moment(this.state.user.created_at).format("MMM Do YYYY")}</div>
                    <hr className="my-4" />
                  </div>

                  <div className="row p-4 mt-5">
                    <div className="col-9">
                      {" "}
                      <b>Email Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.email_verified_at
                            ? "fa fa-check text-success"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>
                    <div className="col-9">
                      {" "}
                      <b>NIN Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.nin_verified_at
                            ? "fa fa-check text-success"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>
                    <div className="col-9">
                      {" "}
                      <b>BVN Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.bvn_verified_at
                            ? "fa fa-check text-success"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>

                    <div className="col-9">
                      {" "}
                      <b>ID Card Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.idc_verified_at
                            ? "fa fa-check text-success"
                            : this.state.user.idc_uploaded_at
                            ? "fa fa-upload text-warning"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>
                    <div className="col-9">
                      {" "}
                      <b>DP Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.dp_verified_at
                            ? "fa fa-check text-success"
                            : this.state.user.dp_verified_at
                            ? "fa fa-upload text-warning"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>

                    <div className="col-9">
                      {" "}
                      <b>Phone Number Verified</b>
                    </div>
                    <div className="col-3 text-right">
                      <span
                        className={
                          this.state.user.phone_verified_at
                            ? "fa fa-check text-success"
                            : "fa fa-times text-danger"
                        }
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetail;