/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import MasterAccount from "./master_account.js";
import UserAsset from "./user_asset.js";
import Order from "./order.js";
import Transfer from "./transfer.js";
import Trade from "./trade.js";
import TradeInfo from "./trade_info.js";
import DebitAccount from "./debit_account.js";
import SavingsAccount from "./savings_account.js";
import WithdrawLimit from "./withdraw_limit.jsx";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "master-account",
      verified: false,
    };
  }
  componentDidMount() {
    // send the otp to user email

    if (!this.state.verified)
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/auth_code", {
          token: localStorage.getItem("supportToken"),
        })
        .then((response) => {
          if (response.data.status === "true") {
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          alert("something went wrong");
        });
  }

  confirmOTP = (otp) => {
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/auth_code/verify", {
        token: localStorage.getItem("supportToken"),
        auth_code: otp,
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            otp: otp,
            verified: true,
          });
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  render() {
    let clearance = localStorage.getItem("supportClearance");

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">CPanel</h2>
                  </div>

                  <hr />
                  {this.state.verified ? (
                    <div>
                      <ul className="nav nav-pills  p-3">
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "master-account" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "master-account" })}
                          >
                            {" "}
                            Master Account
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "user-assets" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "user-assets" })}
                          >
                            {" "}
                            User Assets
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " + (this.state.tab === "orders" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "orders" })}
                          >
                            {" "}
                            Orders
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "transfers" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "transfers" })}
                          >
                            {" "}
                            Transfers
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " + (this.state.tab === "trades" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "trades" })}
                          >
                            {" "}
                            Trades
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "trade_info" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "trade_info" })}
                          >
                            {" "}
                            Trade Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "withdraw_limit" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "withdraw_limit" })}
                          >
                            {" "}
                            Withdraw Limit
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "debit-account" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "debit-account" })}
                          >
                            Debit Account
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className={
                              "nav-link " +
                              (this.state.tab === "savings-account" ? "active" : "")
                            }
                            href="#"
                            onClick={() => this.setState({ tab: "savings-account" })}
                          >
                            Savings Account
                          </a>
                        </li>
                      </ul>

                      <div className="p-3">
                        {this.state.tab === "master-account" && clearance === "admin" ? (
                          <MasterAccount />
                        ) : this.state.tab === "user-assets" && clearance === "admin" ? (
                          <UserAsset />
                        ) : this.state.tab === "orders" && clearance === "admin" ? (
                          <Order />
                        ) : this.state.tab === "transfers" && clearance === "admin" ? (
                          <Transfer />
                        ) : this.state.tab === "trades" && clearance === "admin" ? (
                          <Trade />
                        ) : this.state.tab === "trade_info" && clearance === "admin" ? (
                          <TradeInfo />
                        ) : this.state.tab === "debit-account" &&
                          clearance === "admin" ? (
                          <DebitAccount />
                        ) : this.state.tab === "savings-account" &&
                          clearance === "admin" ? (
                          <SavingsAccount />
                        ) : this.state.tab === "withdraw_limit" ? (
                          <WithdrawLimit />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4 mx-auto">
                      <div className="form-group ">
                        <label>Enter OTP</label>
                        <input
                          required
                          name="otp"
                          className="form-control"
                          placeholder="Enter otp"
                          onChange={(evt) => {
                            let otp = evt.target.value;
                            if (otp.length === 6) {
                              // get user full name
                              this.confirmOTP(otp);
                            }
                          }}
                          type="text"
                        />
                        <small>
                          An otp has been sent to your email to perform this activity
                        </small>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
