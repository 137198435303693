/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import P2pAccount from "./accounts";
import DepositTrx from "./aggregate_trxs";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "account",
    };
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <ul class="nav nav-pills  p-3">
              <li class="nav-item">
                <a
                  className={"nav-link " + (this.state.tab === "account" ? "active" : "")}
                  href="#"
                  onClick={() => this.setState({ tab: "account" })}
                >
                  {" "}
                  Accounts
                </a>
              </li>
              <li class="nav-item">
                <a
                  className={"nav-link " + (this.state.tab === "trx" ? "active" : "")}
                  href="#"
                  onClick={() => this.setState({ tab: "trx" })}
                >
                  Transactions
                </a>
              </li>
            </ul>

            <div class="p-3">
              {this.state.tab === "account" ? (
                <P2pAccount />
              ) : this.state.tab === "trx" ? (
                <DepositTrx />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
