import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import axios from "axios";
import { API_BASE_URL } from "../../constants_var";
import moment from "moment";

const Championsboard = () => {
  const [leaderData, setLeaderData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [month, setMonth] = useState("");

  async function fetchLeaders(date) {
    const currentDate = date !== undefined ? date : moment().format("YYYY-MM");
    console.log(date);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/account/leader_board?month=${currentDate}`
      );
      if (response.data && response.data.data) {
        setLeaderData(response.data.data);
      }
    } catch (err) {}
  }

  useEffect(() => {
    fetchLeaders();
  }, []);
  return (
    <div>
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">
                Filter Month
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={async (evt) => {
                evt.preventDefault();
                await fetchLeaders(month);
                window.$("#filterModal").modal("hide");
              }}
              autoComplete="off"
            >
              <div class="modal-body">
                {/* <div className=" form-group">
                  <label> Per Page</label>
                  <input
                    type="number"
                    name="perPage"
                    placeholder="Per Page"
                    // value={this.state.perPage}
                    className="form-control"
                    // onChange={this.handleChange}
                  />
                </div> */}

                <div className="form-group">
                  <label>Select Month </label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    onChange={(event) => {
                      setMonth(moment(event.target.value).format("YYYY-MM"));
                    }}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-primary btn-sm"
                  // disabled={this.state.isSubmitting}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Sidebar />
      <div className="main-content main-content-my">
        <Navbar />
        <div className="header bg-primary pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body"></div>
          </div>
        </div>
        <div className="container-fluid mt--7">
          <div className="row">
            <div className="col">
              <div className="card shadow">
                <div className="card-header border-0 d-flex justify-content-between">
                  <h2
                    className="mb-0 d-inline"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Champions Board
                  </h2>
                  <button
                    type="button"
                    onClick={() => {
                      window.$("#filterModal").modal("show");
                    }}
                    class="btn btn-primary btn-sm"
                  >
                    Filter
                  </button>
                </div>

                <hr />
                <table
                  className="container"
                  style={{
                    maxWidth: "100%",
                    overflowX: "scroll",
                  }}
                >
                  <thead className="my-5">
                    <tr
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <td>Position</td>
                      <td>Email</td>
                      <td>Username</td>
                      <td>Frequency</td>
                      <td>Volume ($)</td>
                    </tr>
                  </thead>

                  <tbody>
                    {!isLoading &&
                      leaderData !== undefined &&
                      leaderData.leaders.map((value, index) => {
                        return (
                          <tr
                            style={{
                              height: 50,
                              borderBottom: "1px solid #e1e1e1",
                            }}
                          >
                            <td>{value.position}</td>
                            <td>{value.email}</td>
                            <td>{value.username}</td>
                            <td>{value.frequency}</td>
                            <td>{value.volume.toFixed(2)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* <div>
                  <ul class="nav nav-pills  p-3">
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "deposit" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "deposit" })}
                      >
                        {" "}
                        Deposits
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "withdraw" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "withdraw" })}
                      >
                        {" "}
                        Withdraws
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "trade" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "trade" })}
                      >
                        {" "}
                        Trade
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "fee" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "fee" })}
                      >
                        {" "}
                        Fees
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "extra_fee" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "extra_fee" })}
                      >
                        {" "}
                        Network Fee
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " + (this.state.tab === "app" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "app" })}
                      >
                        {" "}
                        App Stats
                      </a>
                    </li>

                    <li class="nav-item">
                      <a
                        className={
                          "nav-link " +
                          (this.state.tab === "fiat-received" ? "active" : "")
                        }
                        href="#"
                        onClick={() => this.setState({ tab: "fiat-received" })}
                      >
                        {" "}
                        Fiat Received
                      </a>
                    </li>
                  </ul>

                  <div class="p-3">
                    {this.state.tab === "deposit" ? (
                      <Deposit />
                    ) : this.state.tab === "withdraw" ? (
                      <Withdraw />
                    ) : this.state.tab === "trade" ? (
                      <Trade />
                    ) : this.state.tab === "fee" ? (
                      <Fee />
                    ) : this.state.tab === "extra_fee" ? (
                      <Extra_Fee />
                    ) : this.state.tab === "app" ? (
                      <App />
                    ) : this.state.tab === "fiat-received" ? (
                      <FiatReceived />
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Championsboard;
