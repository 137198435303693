import React, { Component } from "react";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import TokenDeposits from "./token.js";
import WalletFiatDeposits from "./wallet_fiat.js";
import BankFiatDeposits from "./bank_fiat.js";

class Withdrawals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "wallet-fiat",
    };
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">Deposits</h2>
                    <ul class="nav nav-pills  p-3">
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " +
                            (this.state.tab === "wallet-fiat" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "wallet-fiat" })}
                        >
                          {" "}
                          Wallet Fiat Deposits
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "bank-fiat" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "bank-fiat" })}
                        >
                          {" "}
                          Bank Fiat Deposits
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          className={
                            "nav-link " + (this.state.tab === "token" ? "active" : "")
                          }
                          href="#"
                          onClick={() => this.setState({ tab: "token" })}
                        >
                          Token Deposits
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div>
                    {(() => {
                      if (this.state.tab === "token") return <TokenDeposits />;
                    })()}

                    {(() => {
                      if (this.state.tab === "wallet-fiat") return <WalletFiatDeposits />;
                    })()}

                    {(() => {
                      if (this.state.tab === "bank-fiat") return <BankFiatDeposits />;
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Withdrawals;
