import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

import AppUsage from "./app_usages.js";
import RegisteredUsers from "./registered.js";
import VerifiedUsers from "./verified.js";
import TransactedUsers from "./transacted.js";
import UnverifiedUsers from "./unverified.js";
import Fees from "../fee.js";
import UntransactedUsers from "./untransacted.js";
import VerifiedUntransactedUsers from "./verified-untransacted.js";
import TodayVerifiedUsers from "./today_verified";
import TodayUnverifiedUsers from "./today_unverified";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "registered-users",
    };
  }

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">App Stats</h3>
              </div>

              <hr />

              <div>
                <ul class="nav nav-pills  p-3">
                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " + (this.state.tab === "app-usages" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "app-usages" })}
                    >
                      {" "}
                      App Usages(Daily)
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "registered-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "registered-users" })}
                    >
                      {" "}
                      Registered Users
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "verified-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "verified-users" })}
                    >
                      {" "}
                      Verified Users
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "transacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "transacted-users" })}
                    >
                      {" "}
                      Transacted Users
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "untransacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "untransacted-users" })}
                    >
                      {" "}
                      Untransacted Users
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "verified-untransacted-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() =>
                        this.setState({ tab: "verified-untransacted-users" })
                      }
                    >
                      {" "}
                      Verified Untransacted Users
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " +
                        (this.state.tab === "unverified-users" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "unverified-users" })}
                    >
                      {" "}
                      Unverified Users
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className={
                        "nav-link " + (this.state.tab === "fees" ? "active" : "")
                      }
                      href="#"
                      onClick={() => this.setState({ tab: "fees" })}
                    >
                      {" "}
                      Fees
                    </a>
                  </li>
                </ul>

                <div class="p-3">
                  {this.state.tab === "app-usages" ? (
                    <AppUsage />
                  ) : this.state.tab === "registered-users" ? (
                    <RegisteredUsers />
                  ) : this.state.tab === "verified-users" ? (
                    <VerifiedUsers />
                  ) : this.state.tab === "transacted-users" ? (
                    <TransactedUsers />
                  ) : this.state.tab === "fees" ? (
                    <Fees />
                  ) : this.state.tab === "unverified-users" ? (
                    <UnverifiedUsers />
                  ) : this.state.tab === "untransacted-users" ? (
                    <UntransactedUsers />
                  ) : this.state.tab === "verified-untransacted-users" ? (
                    <VerifiedUntransactedUsers />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
