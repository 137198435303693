import React, { Component } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment/moment";

class SavingsPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savings_plans: [],
    };
  }
  componentDidMount() {
    this.setState({
      savings_plans: this.props.savings_plans,
    });
  }

  render() {
    return (
      <div
        className="tab-pane fade"
        id="nav-savings"
        role="tabpanel"
        aria-labelledby="nav-savings-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Savings Plans</h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />

              <div className="table-responsive">
                {this.state.savings_plans.length > 0 ? (
                  <div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead>
                          <th>Date created</th>
                          <th>Amount Invested</th>
                          <th>Current Yield</th>
                          <th>Total Yield</th>
                          <th>Duration</th>
                          <th>Maturity Date</th>
                          <th>Status</th>
                        </thead>
                        <tbody>
                          {this.state.savings_plans.map((saving) => {
                            return (
                              <tr>
                                <td scope="row dash-td" style={{ width: "1%" }}>
                                  <div>
                                    {moment(saving.created_at).format("YYYY-MM-DD")}
                                  </div>
                                </td>

                                <td className="dash-td">
                                  <NumberFormat
                                    value={saving.amount_invested}
                                    className="card-bal-your h4"
                                    displayType={"text"}
                                    prefix={"USDT "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </td>

                                <td className="dash-td">
                                  <NumberFormat
                                    value={saving.amount_invested + saving.current_yield}
                                    className="card-bal-your h4"
                                    displayType={"text"}
                                    prefix={"USDT "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <td className="dash-td">
                                  <NumberFormat
                                    value={saving.total_yield}
                                    className="card-bal-your h4"
                                    displayType={"text"}
                                    prefix={"USDT "}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                  />
                                </td>

                                <td className="dash-td">
                                  <div>{saving.duration} days</div>
                                </td>

                                <td className="dash-td">
                                  <div>
                                    {moment(saving.maturity_date).format("YYYY-MM-DD")}
                                  </div>
                                </td>

                                <td className="dash-td">
                                  {saving.status === 0 ? (
                                    <div>
                                      <button className=" btn btn-sm btn-secondary">
                                        active
                                      </button>
                                    </div>
                                  ) : saving.status === 4 ? (
                                    <button
                                      className="success btn btn-sm btn-success"
                                      style={{ width: "100px" }}
                                    >
                                      withdrew
                                    </button>
                                  ) : saving.status === 1 ? (
                                    <button className="btn-primary btn btn-sm btn-primary">
                                      matured
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="text-center no-data full-width-data">
                    <p className="text-muted no-trans-text-o">No savings yet!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SavingsPlans;
