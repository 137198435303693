import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import defaultCoinIcon from "../../components/assets/img/default_coin_icon.png";
import NumberFormat from "react-number-format";
import moment from "moment";

class GiftCardReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      report: undefined,
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    this.getGiftCardReport();
  }

  getGiftCardReport = () => {
    this.setState({
      fiatReceived: {},
      isSubmitting: true,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/report/gift_cards/", {
        params: {
          token: localStorage.getItem("supportToken"),
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        console.log("RESP:: ",response)
        if (response.data.status === "true") {
          this.setState({
            report: response.data.data,
            isSubmitting: false,
          });
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="nav-assets"
        role="tabpanel"
        aria-labelledby="nav-assets-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="mb-0 d-inline">Gift Cards Received</h3>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <hr className="dash-hr" />

              <div className="p-5">
                <div className="form-inline">
                  <label>Start Date </label>{" "}
                  <input
                    type="date"
                    name="startDate"
                    value={this.state.startDate}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                  />
                  <label>End Date </label>{" "}
                  <input
                    type="date"
                    name="endDate"
                    value={this.state.endDate}
                    className="form-control ml-2 mr-2"
                    onChange={this.handleChange}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={this.getGiftCardReport}
                  >
                    Continue
                  </button>
                </div>

                {!this.state.isSubmitting ? (
                  <div className="table-responsive mt-5">
                    <table className="table">
                      <thead>
                        <th>Type</th>
                        <th>Number of Transactions</th>
                        <th>Total Amount</th>
                      </thead>
                      <tbody>
                        {
                          this.state.report && this.state.report.map(item =>

                        <tr>
                          <td>{item.receipt_type}</td>
                          <td>{item.receipt_count}</td>
                          <td>{item.total_amount} NGN</td>
                          
                         
                        </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="p-5 text-center">
                    <h3>Loading....</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardReport;
