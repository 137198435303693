import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

import UserDetail from "./detail.js";
import TransactionComponent from "./transaction.js";
import AssetComponent from "./asset.js";
import SavingsPlansComponent from "./savings.js";
// import ReactPaginate from "react-paginate";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
        transactions: [],

      user: {
        info: {},
        p2p_withdraws: [],
        p2p_deposits: [],
        token_withdraws: [],
        token_deposits: [],
        transactions: [],
        assets: [],

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      pageCount: 0,
      _from: 0,
      _to: 0,

      },

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,

      is_staff: localStorage.getItem("supportClearance") === "admin" ? false : true,
    };
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    this.getEmail();
  }
  
  
    async getEmail(currPage){
    //get user email
    let email = this.props.match.params.email,
      self = this;
let finalResponse ;
 const response=  await  axios
      .get(ConstantVar.API_BASE_URL + "/admin/user", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: email,
          per_page: this.state.perPage,
          current_page: currPage??this.state.currentPage,

        },
      })
      
      if (response.data.status === "true") {
        finalResponse =response.data.data.transactions;
        self.setState({
          transactions:response.data.data.transactions,
          user: response.data.data,
          pageLoaded: true,
          _from: response.data.data.transactions.pagination.from,
          _to: response.data.data.transactions.pagination.to,
            });

            if (!this.state.paginationLoaded) {
            let total = response.data.data.transactions.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.transactions.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,

          }

        );
      }}
      return finalResponse;
  }
// }

  handlePagination = async(data) => {
    const selectedPage = data.selected;
    console.log(selectedPage)
    this.setState({ currentPage: selectedPage + 1 });
    return await this.getEmail(selectedPage + 1 );
  };

  render() {
    return (
      <div /*style={{overflowY: "scroll"}}*/>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row">
                  <nav>
                    <div className="nav " id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link profile-click-m active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        href="#nav-home"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Details</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#nav-transactions"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Transactions</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-assets-tab"
                        data-toggle="tab"
                        href="#nav-assets"
                        role="tab"
                        aria-controls="nav-assets"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Assets</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>

                      <a
                        className="nav-item profile-click-m nav-link"
                        id="nav-savings-tab"
                        data-toggle="tab"
                        href="#nav-savings"
                        role="tab"
                        aria-controls="nav-assets"
                        aria-selected="false"
                      >
                        <div className="card card-stats">
                          <div className="card-body navigation">
                            <div className="row">
                              <div className="col">
                                <h5>Savings</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt--7 promargin-m">
            <div className="row">
              <div className="col-xl-12 order-xl-1">
                <div className="tab-content" id="nav-tabContent">
                  {(() => {
                    if (this.state.pageLoaded)
                      return (
                        <UserDetail
                          info={this.state.user.info}
                          bankAccount={this.state.user.bankAccount}
                          savings_plans={this.state.user.savings_plans}
                        />
                      );
                  })()}

                  {(() => {    
                    if (this.state.pageLoaded)
                      return (
                        <TransactionComponent
                          transactions={this.state.transactions}
                          pageCount={this.state.pageCount}
                          currentPage={this.state.currentPage}
                          handlePagination={this.handlePagination}
                        />
                      );
                  })()}

                  {(() => {
                    if (this.state.pageLoaded)
                      return <AssetComponent assets={this.state.user.assets} />;
                  })()}

                  {(() => {
                    if (this.state.pageLoaded)
                      return (
                        <SavingsPlansComponent
                          savings_plans={this.state.user.savings_plans}
                        />
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
