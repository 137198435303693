import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

import moment from "moment";

class IdentificationUploads extends Component {
  constructor(props,context) {
    super(props,context);
    this.state = {
      reason: "",
      isLoading: true,
      page: "request",
      tab: "id-card",
      user: {
        idc_uploaded_at: null,
        idc_verified_at: null,
      },
      id_cards: [],
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getIdCardUploadInfo = () => {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/user/", {
        params: {
          token: localStorage.getItem("supportToken"),
          get_idcard_upload_info: "1",
          email: this.state.email,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            user: response.data.data,
            page: "overview",
          });
        }
      });
  };

  getIdCardVerificationsRequest = () => {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/id_card/request", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            id_cards: response.data.data,
          });
        }
      });
  };

  confirm = (action) => {
    if (action === "reject" && this.state.reason.length < 3) {
      alert("please provide the reason for rejection");
      return;
    }
    var status = window.confirm("Are you sure you want tp perform this operation?");
    if (status && this.state.tab != "dp") {
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/id_card/verify", {
          token: localStorage.getItem("supportToken"),
          email: this.state.user.email,
          action: action,
          reason: this.state.reason,
        })
        .then((response) => {
          alert(response.data.message);
          if (response.data.status === "true") {
            this.setState({
              page: "request",
              reason: "",
            });
            window.location.href = `/user/${this.state.user.email}`

          }
        });
    }else{
      
    axios
    .post(ConstantVar.API_BASE_URL + "/admin/reset_user_dp", {
      token: localStorage.getItem("supportToken"),
      email: this.state.user.email,
      action,
    })
    .then((response) => {
      if (response.data.status === "true") {
        this.setState({
          isLoading: false,
        },() => {
          alert(response.data.message);
          window.location.href = `/user/${this.state.user.email}`
        });

      }
    })
    .catch((err) => {
      this.setState({
        isLoading: false,
      });
      alert(err.response.data.message);
    });
    }

  };

  componentDidMount() {
    this.getIdCardVerificationsRequest();
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">
              {" "}
              {this.state.page === "overview" && (
                <a
                  className="fa fa-arrow-left mr-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      page: "request",
                    });
                  }}
                ></a>
              )}
              {/* IDCard  */}
              Uploads{" "}
              <div className="float-right form-inline">
                <input
                  type="text"
                  style={{ width: "300px" }}
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Email "
                  name="email"
                />
                <button className="btn btn-primary" onClick={this.getIdCardUploadInfo}>
                  Open
                </button>
              </div>
            </h2>
          </div>

          <hr className="dash-hr" />
          {this.state.page === "overview" ? (
            <div>
              <div className=" p-3 ">
                    <button
                      className={this.state.tab === "id-card" ? "btn btn-info" : "btn"}
                      style={{
                        width: "150px",
                      }}
                      onClick={() => {
                        this.setState({
                          tab: "id-card",
                        });
                        // this.handleActivity();
                      }}
                    >
                      ID Card
                    </button>
                    <button
                      className={this.state.tab === "dp" ? "btn btn-info" : "btn"}
                      style={{
                        width: "150px",
                      }}
                      onClick={() => {
                        this.setState({
                          tab: "dp",
                        });
                      }}
                    >
                      Profile Pics
                    </button>

              </div>

              {this.state.tab == "id-card"
              &&
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="p-5 mt-4 text-center">
                      {this.state.user.idc_uploaded_at &&
                      this.state.user.idc_verified_at === null ? (
                        <div>
                          <button
                            className="btn btn-success mr-4"
                            onClick={() => this.confirm("approve")}
                          >
                            APPROVE
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => this.confirm("reject")}
                          >
                            REJECT
                          </button>
                          <div className="form-group  mt-3 text-center">
                            <textarea
                              rows="3"
                              className="form-control"
                              name="reason"
                              placeholder="Reason for rejection here"
                              value={this.state.reason}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      ) : //

                      // SHOW Button to Request identification upload

                      this.state.user.idc_uploaded_at === null &&
                        this.state.user.idc_verified_at === null &&
                        this.state.user.allow_idc_upload === 0 ? (
                        <button
                          className="btn btn-block btn-danger mr-4"
                          onClick={() => this.confirm("request")}
                        >
                          REQUEST FOR ID CARD
                        </button>
                      ) : // SHOW Button to reset identification upload
                      this.state.user.idc_uploaded_at &&
                        this.state.user.idc_verified_at ? (
                        <button
                          className="btn btn-block btn-warning mr-4"
                          onClick={() => this.confirm("reset")}
                        >
                          RESET ID CARD UPLOAD & VERIFICATION"
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 bg-dark text-white p-4">
                    {this.state.user.idc_uploaded_at ? (
                      <div>
                        <h5 className="text-white mb-3 ">Front Page</h5>
                        <img
                          style={{
                            maxWidth: "100%",
                          }}
                          src={`${ConstantVar.API_BASE_URL}/static/id_cards/${this.state.user.username}/front.png`}
                        />
                        <h5 className="text-white mt-3">Back Page</h5>
                        <img
                          style={{
                            maxWidth: "100%",
                          }}
                          src={`${ConstantVar.API_BASE_URL}/static/id_cards/${this.state.user.username}/back.png`}
                        />
                      </div>
                    ) : (
                      <b>No upload yet!</b>
                    )}
                  </div>
                </div>
              </div>
 }
                      {this.state.tab == "dp"
                      &&
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="p-5 mt-4 text-center">
                                {this.state.user.dp_uploaded_at &&
                                // SHOW Button to reset identification upload
                                <>
                                
                                  <button
                                    className="btn btn-block btn-warning mr-4"
                                    onClick={() => this.confirm("reset")}
                                  >
                                    RESET DP
                                  </button>

                                    {
                                      !this.state.user.dp_verified_at
                                      &&
                                      <button
                                        className="btn btn-block btn-success mr-4"
                                        onClick={() => this.confirm("approve")}
                                        >
                                        APPROVE DP
                                      </button>
                                    }
                                </>
                                }
                              </div>
                            </div>

                            <div className="col-md-6 bg-dark text-white p-4">
                              {this.state.user.dp_uploaded_at ? (
                                <div>
                                  <h5 className="text-white mb-3 ">Front Page</h5>
                                  <img
                                    style={{
                                      maxWidth: "100%",
                                    }}
                                    src={`${ConstantVar.API_BASE_URL}/static/profile_pics/${this.state.user.username}/dp.png`}
                                  />
                                 
                                </div>
                                ) : (
                                  <b>No upload yet!</b>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      }


            </div>
          ) : this.state.page === "request" ? (
            <div>
              <h4 className="p-4">Requests</h4>
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Uploaded At</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Number</th>
                      <th scope="col">...</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.id_cards.map((account) => (
                      <tr>
                        <td>
                          {" "}
                          {moment(account.idc_uploaded_at).format("YYYY MM DD,  H:mm A")}
                        </td>

                        <td>
                          <a target="_blank" href={"/user/" + account.email}>
                            {account.email}
                          </a>
                        </td>
                        <td>
                          {account.lname} {account.fname}{" "}
                        </td>
                        <td>{account.idc_type}</td>
                        <td>{account.idc_number}</td>
                        <td>
                          <button
                            className="btn btn-info"
                            onClick={() =>
                              this.setState({
                                user: account,
                                page: "overview",
                              })
                            }
                          >
                            Open
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default IdentificationUploads;
