import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";
import * as ConstantVar from "../../constants_var.js";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      users: [],
      search_text: "",

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  getUsers = () => {
    //bind this to self
    let self = this;
    this.setState({
      isLoading: true,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/users", {
        params: {
          token: localStorage.getItem("supportToken"),
          current_page: this.state.currentPage,
          per_page: this.state.perPage,
          search_text: this.state.search_text,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            users: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getUsers();
    });
  };

  resetSearch = () => {
    this.setState(
      {
        // set loading
        isLoading: true,
        search_text: "",
        currentPage: 1,
        paginationLoaded: false,
        totalEntries: 0,
      },
      () => this.getUsers()
    );
  };

  searchUser = (search_text) => {
    if (search_text.length < 3) {
      return false;
    }

    this.setState(
      {
        // set loading
        isLoading: true,
        search_text: search_text,
        currentPage: 1,
        paginationLoaded: false,
        totalEntries: 0,
      },
      () => this.getUsers()
    );
  };

  componentDidMount() {
    this.getUsers();
  }

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar name="user" func={this.searchUser} />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0 d-inline">
                      All Users ({this.state.totalEntries}){/*  */}
                      {/* reset search   */}
                      {this.state.search_text.length > 3 ? (
                        <button
                          onClick={() => {
                            this.resetSearch();
                          }}
                          className="btn btn-sm btn-primary ml-auto"
                          style={{ float: "right", cursor: "pointer" }}
                        >
                          Reset{" "}
                        </button>
                      ) : (
                        ""
                      )}
                    </h2>
                  </div>
                  <hr className="dash-hr" />
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div style={{ padding: 50 }} className="text-center">
                          <div>
                            <p>Loading...</p>
                          </div>
                        </div>
                      );
                    else
                      return this.state.users.length ? (
                        <div>
                          <div className="pl-2 ml-5">{paginationElement}</div>

                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <tbody>
                                {this.state.users.map((user, index) => {
                                  return (
                                    <tr key={index}>
                                      <th
                                        scope="row dash-td"
                                        style={{ width: "1%" }}
                                      >
                                        <div className="media align-items-center">
                                          <div className="phone-bckg">
                                            <i className="fas fa-user-alt phone-color" />
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        className="dash-td"
                                        style={{ width: "30%" }}
                                      >
                                        <div className="media align-items-center">
                                          <p className="margin-user-text">
                                            {user.fname} {user.lname}
                                          </p>
                                        </div>
                                      </th>
                                      <td className="dash-td">
                                        <p className="margin-user-text">
                                          <a
                                            target="_blank"
                                            href={"/user/" + user.email}
                                          >
                                            {user.email}
                                          </a>
                                        </p>
                                      </td>
                                      <td className="dash-td">
                                        <p className="margin-user-text">
                                          {user.username}
                                        </p>
                                      </td>
                                      <td className="dash-td">
                                        <p className="margin-user-text">
                                          {user.phone}
                                        </p>
                                      </td>
                                      <td className="dash-td">
                                        <span className="float-right font-weight-normal">
                                          {user.gender}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <div className="p-5 ml-5">{paginationElement}</div>
                          <h2 style={{ textAlign: "center" }} className="mb-3">
                            {" "}
                            <small>
                              Showing {this.state._from + 1} to {this.state._to}{" "}
                              of {this.state.totalEntries} entries (filtered
                              from {this.state.users.length} total entries)
                            </small>
                          </h2>
                        </div>
                      ) : (
                        <h4 class="p-3 text-center">No users found</h4>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
