import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import EmailEditor from "react-email-editor";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.createBlogRef = React.createRef();
    this.updateBlogRef = React.createRef();
    this.state = {
      isLoading: true,
      blogs: [],
      featured_image: null,

      post: {},
      page: "overview",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBlog();
  }

  onDrop = (evt) => {
    let fileInput = evt.target;
    let self = this;
    if (fileInput.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      var url = window.URL || window.webkitURL;
      var image = new Image();
      image.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        self.setState({
          featured_image: canvas.toDataURL(),
        });
      };
      image.onerror = function () {
        alert("Upload Error", "Invalid image");
      };
      image.src = url.createObjectURL(fileInput.files[0]);
    } else {
      alert("Upload Error", "Not an image");
    }
  };

  handleEditChange = (evt) => {
    const post = this.state.post;
    post[evt.target.name] = evt.target.value;
    this.setState({ post: post });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getBlog = () => {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/blog", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        this.setState({
          blogs: response.data.data,
        });
      });
  };

  createPost = async (e) => {
    e.preventDefault();

    if (e.target.title.value.length < 10) {
      alert("blog title is too short");
      return;
    }

    if (e.target.caption.value.length < 15) {
      alert("blog caption is too short");
      return;
    }

    if (this.state.featured_image === null) {
      alert("featured image is not uploaded");
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    this.createBlogRef.current.exportHtml((data) => {
      const { design, html } = data;
      console.log(design);
      console.log(html);

      axios
        .post(ConstantVar.API_BASE_URL + "/admin/blog", {
          content: html,
          design,
          title: this.state.title,
          caption: this.state.caption,
          featured_image: this.state.featured_image,
          token: localStorage.getItem("supportToken"),
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert("blog post created successfully");
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    });
  };

  updatePost = (e) => {
    e.preventDefault();

    const { title, _id, caption } = this.state.post;

    if (title.length < 10) {
      alert("blog title is too short");
      return;
    }

    if (caption.length < 15) {
      alert("blog caption is too short");
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    this.updateBlogRef.current.exportHtml((data) => {
      const { design, html } = data;
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/update_blog", {
          blog_id: _id,
          content: html,
          design,
          title,
          caption,
          featured_image: this.state.featured_image,
          token: localStorage.getItem("supportToken"),
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert("blog post updated successfully");
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    });
  };

  deletePost = (post) => {
    const { _id } = post;
    if (window.confirm("Are sure you want to remove this blog post?")) {
      this.setState({
        isSubmitting: true,
      });

      axios
        .post(ConstantVar.API_BASE_URL + "/admin/delete_blog", {
          blog_id: _id,
          token: localStorage.getItem("supportToken"),
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert("blog post removed successfully");
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    }
  };

  enablePost = (post) => {
    const { _id } = post;
    if (window.confirm("Are sure you want to perform this operation?")) {
      this.setState({
        isSubmitting: true,
      });

      axios
        .post(ConstantVar.API_BASE_URL + "/admin/enable_blog", {
          blog_id: _id,
          token: localStorage.getItem("supportToken"),
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert("Operation successful.");
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    }
  };

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  loadPost = (post) => {
    this.setState(
      {
        post: post,
        page: "edit",
      },
      async () => {
        // load
        await this.delay(5000);
        this.updateBlogRef.current.editor.loadDesign(JSON.parse(post.design));
      }
    );
  };

  render() {
    return (
      <div
        className="tab-pane fade"
        id="nav-blog-screen"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        {this.state.page === "overview" && (
          <div className="card shadow p-5">
            <h2>
              Blog Posts
              <div className="form-inline float-right">
                {" "}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      page: "new",
                    });
                  }}
                >
                  New Post
                </button>
              </div>
            </h2>

            <div className="mt-4  table table-responsive">
              <table
                className="table align-items-center table-flush"
                style={{ padding: "50px" }}
              >
                <thead>
                  <th>Created</th>
                  <th>Title</th>
                  <th>By</th>
                  <th>...</th>
                </thead>
                <tbody>
                  {this.state.blogs.map((post, index) => {
                    return (
                      <tr>
                        <th scope="row dash-td" style={{ width: "1%" }}>
                          {moment(post.created_at).format("yyyy-MM-DD")}
                        </th>
                        <td
                          className="dash-td"
                          style={{
                            width: "10%",
                          }}
                        >
                          {post.title}
                        </td>

                        <td className="dash-td">{post._by}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary mr-3"
                            disabled={this.state.isSubmitting}
                            onClick={() => {
                              this.loadPost(post);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-secondary mr-3"
                            disabled={this.state.isSubmitting}
                            onClick={() => {
                              this.enablePost(post);
                            }}
                          >
                            {post.status === 0 ? "Publish" : "UnPublish"}
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            disabled={this.state.isSubmitting}
                            onClick={() => {
                              this.deletePost(post);
                            }}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {this.state.page === "new" && (
          <div className="card shadow p-5">
            <h2>
              {" "}
              <a
                className="fa fa-arrow-left mr-2"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    page: "overview",
                  });
                }}
              ></a>
              New Post
            </h2>

            <form onSubmit={this.createPost} autoComplete="off">
              <div class="form-group">
                <label>Title</label>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Blog Title"
                  name="title"
                  required
                  onChange={this.handleChange}
                />
              </div>

              <div className="row">
                <div class="form-group col-md-6">
                  <label>Caption</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="Blog caption"
                    name="caption"
                    required
                    onChange={this.handleChange}
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Featured Image</label>

                  <input
                    name="image"
                    type="file"
                    onChange={this.onDrop}
                    class="form-control form-control-sm"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Content</label>
                <EmailEditor ref={this.createBlogRef} />
              </div>

              <div className="form-group float-right">
                <button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  class="btn btn-primary"
                >
                  Create Post
                </button>
              </div>
            </form>
          </div>
        )}

        {this.state.page === "edit" && (
          <div className="card shadow p-5">
            <h2>
              <a
                className="fa fa-arrow-left mr-2"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    page: "overview",
                  });
                }}
              ></a>{" "}
              Edit Post
            </h2>
            <form onSubmit={this.updatePost} autoComplete="off">
              <div class="form-group">
                <label>Title</label>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Blog Title"
                  name="title"
                  value={this.state.post.title}
                  onChange={this.handleEditChange}
                  required
                />
              </div>

              <div className="row">
                <div class="form-group col-md-6">
                  <label>Caption</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="Blog Title"
                    name="caption"
                    value={this.state.post.caption}
                    onChange={this.handleEditChange}
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Featured Image</label>
                  <input
                    name="image"
                    type="file"
                    onChange={this.onDrop}
                    class="form-control form-control-sm"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Content</label>
                <EmailEditor ref={this.updateBlogRef} />
              </div>

              <div class="form-group mt-4 float-right">
                <button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  class="btn btn-primary"
                >
                  Update Post
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default Blog;
