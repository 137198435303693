import axios from "axios";
import React, { useEffect, useState } from "react";

import * as ConstantVar from "../../constants_var";
const WithdrawLimit = () => {
  const [data, setData] = useState(undefined);
  async function fetchData() {
    const response = await axios.get(
      `${ConstantVar.API_BASE_URL}/admin/otc_limits?token=${localStorage.getItem(
        "supportToken"
      )}`
    );
    setData(response.data.message);
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="w-full h-[400px]   overflow-scroll">
      <div className="flex w-full  font-extrabold bg-blue-600  text-white">
        <p className="min-w-[100px] font-semibold bg-blue-600 px-3 py-2">S/N</p>
        <p className="min-w-[100px] font-semibold bg-blue-600 py-2 ">Asset</p>
        <p className="min-w-[100px] font-semibold bg-blue-600 py-2">Network</p>
        <p className="min-w-[450px] font-semibold bg-blue-600 px-2 py-2">Address</p>
        <p className="min-w-[200px] font-semibold bg-blue-600 px-2 py-2">
          Min. Withdrawal
        </p>
      </div>

      {data !== undefined ? (
        data.map((value, index) => {
          {
            return (
              <div className="flex w-full    text-black py-2">
                <p className="min-w-[100px] px-2">{index + 1}</p>
                <p className="min-w-[100px]  ">{value.asset}</p>
                <p className="min-w-[100px]   ">{value.network}</p>
                <p className="min-w-[450px]  px-2">{value.wallet_address}</p>
                <p className="min-w-[200px]  px-2 border-[1px] border-black rounded-md">
                  <input
                    defaultValue={value.min_transfer}
                    type="number"
                    className="focus:outline-none border-none w-full"
                    onChange={(e) => {
                      data[index].min_transfer = parseFloat(e.target.value);
                      setData(data);
                    }}
                  />
                </p>
              </div>
            );
          }
        })
      ) : (
        <div className="w-full h-[150px] flex justify-center items-center">Loading</div>
      )}
      <button
        className="my-[30px] bg-blue-600 text-white px-[10px] py-[5px] rounded-md"
        onClick={async (e) => {
          if (window.confirm("Click ok to proceed with the update.")) {
            const response = await axios.patch(
              `${ConstantVar.API_BASE_URL}/admin/otc_limits?token=${localStorage.getItem(
                "supportToken"
              )}`,
              {
                data,
                token: localStorage.getItem("supportToken"),
              }
            );
            alert("Successfully Updated");
          }
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default WithdrawLimit;
