import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import NumberFormat from "react-number-format";
import moment from "moment";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";

class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      broadcast_messages: [],
    };
    this.createMessage = this.createMessage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //this.getMessages();
  }

  getMessages = () => {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/broadcast_message", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        this.setState({
          broadcast_messages: response.data.data,
        });
      });
  };

  createMessage(e) {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });
    if (e.target.body.value.length < 10) {
      alert("message content is to short");
      return;
    }

    if (window.confirm("Are you sure you want to create broadcast message?")) {
      axios
        .post(ConstantVar.API_BASE_URL + "/admin/broadcast_message", {
          message: e.target.body.value,
          title: e.target.title.value,
          url: e.target.url.value,
          pin: e.target.pin.value,
          token: localStorage.getItem("supportToken"),
          can_close: e.target.can_close.value,
        })
        .then((response) => {
          this.setState({
            isSubmitting: false,
          });

          if (response.data.status === "true") {
            alert("broadcast Message created successfully");
            // this.getMessages();
            window.location.reload();
          }
        })
        .catch((err) => {
          this.setState({
            isSubmitting: false,
          });
          alert(err.response.data.message);
        });
    }
  }

  render() {
    return (
      <div
        className="tab-pane fade"
        id="nav-broadcast-message"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div className="card shadow">
          <form
            role="form"
            style={{ padding: 50 }}
            onSubmit={this.createMessage}
            autoComplete="off"
          >
            <h2>Broadcast Messages</h2>
            <div className="form-group mb-3">
              <div className="form-group mt-3">
                <label>Can Close</label> <br />
                <select required name="can_close" className="form-control">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
                <div className="form-group mb-3">
                <input
                  required
                  autoComplete="off"
                  name="title"
                  className="form-control"
                  placeholder="Title"
                  type="text"
                />
              </div>
            
              <div className="form-group mb-3">
                <textarea
                  rows={3}
                  required
                  autoComplete="off"
                  name="body"
                  className="form-control"
                  placeholder="Message"
                />
              </div>

              <div className="form-group mb-3">
                <input
                  autoComplete="off"
                  name="url"
                  className="form-control"
                  placeholder="Read more URL"
                  type="text"
                />
              </div>
              
              <div className="form-group mb-3">
                <input
                  required
                  autoComplete="off"
                  name="pin"
                  className="form-control"
                  placeholder="Confirm PIN"
                  type="password"
                />
              </div>
            <div className="text-left">
              <button
                type="submit"
                disabled={this.state.isSubmitting}
                className="btn btn-primary"
              >
                Create Broadcast Message
              </button>
            </div>
          </form>
          {/* <table className="table align-items-center table-flush">
            <tbody>
              {this.state.broadcast_messages.map((message, index) => {
                return (
                  <tr key={index}>
                    <th scope="row dash-td" style={{ width: "1%" }}>
                      <div className="media align-items-center">
                        <div className="phone-bckg">
                          <i className="fas fa-user-alt phone-color" />
                        </div>
                      </div>
                    </th>
                    <th className="dash-td" style={{ width: "30%" }}>
                      <div className="media align-items-center">
                        <p className="margin-user-text">{message.message}</p>
                      </div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
       */}
        </div>
      </div>
    );
  }
}

export default Messaging;
