import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";

class Balances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      balances: [],
      limit: 10,
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  getBalances = () => {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/balances", {
        params: {
          token: localStorage.getItem("supportToken"),
          limit: this.state.limit,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            balances: response.data.data,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  componentDidMount() {
    this.getBalances();
  }

  render() {
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2 className="mb-0 d-inline">
              {" "}
              Balances{" "}
              <div className="float-right form-inline">
                <input
                  type="number"
                  style={{ width: "100px" }}
                  className="form-control"
                  value={this.state.limit}
                  onChange={this.handleChange}
                  placeholder="No of balances"
                  name="limit"
                />
                <button className="btn btn-primary" onClick={this.getBalances}>
                  GO
                </button>
              </div>
            </h2>
          </div>

          <hr className="dash-hr" />

          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.balances.length > 0 ? (
                    <div className="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Email Address</th>
                            <th scope="col">Lname</th>
                            <th scope="col">Fname</th>
                            <th scope="col">Balance</th>
                            <th scope="col">...</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.balances.map((account) => (
                            <tr>
                              <td>
                                <a target="_blank" href={"/user/" + account.email}>
                                  {account.email}
                                </a>
                              </td>
                              <td>{account.lname} </td>
                              <td>{account.fname} </td>

                              <td>
                                <NumberFormat
                                  value={account.available_bal}
                                  className="card-bal-your"
                                  displayType={"text"}
                                  prefix={"NGN "}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                />
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <NumberFormat
                                value={this.state.balances.reduce(
                                  (a, bal) => a + bal.available_bal,
                                  0
                                )}
                                className="h3 card-bal-your"
                                displayType={"text"}
                                prefix={"NGN "}
                                decimalScale={0}
                                thousandSeparator={true}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No balances yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>
      </div>
    );
  }
}

export default Balances;
