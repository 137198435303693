import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/index.js";
import Staff from "./pages/Staff/index.js";
import Messaging from "./pages/Messaging/index.js";
import Transactions from "./pages/Transactions/index.js";
import UserProfile from "./pages/Userprofile/index.js";
import Tickets from "./pages/Tickets/index.js";
import Users from "./pages/Users/index.js";
import Login from "./pages/Login/index.js";
import Withdrawals from "./pages/Withdrawals/index.js";
import Deposits from "./pages/Deposit/";

import NotFound from "./pages/NotFound.js";
import Report from "./pages/Report/index.js";
import Cpanel from "./pages/Cpanel/index.js";
import Account from "./pages/Account";
import ChangePassword from "./pages/ChangePassword";
import ChangePin from "./pages/ChangePin";
import SavingsPlans from "./pages/Savings";
import ManageTicket from "./pages/ManageTicket/index.js";
import ChampionsBoard from "./pages/ChampionsBoard/Championsboard.jsx";
import GiftCards from "./pages/GiftCards/index.jsx";
import EditGiftCard from "./pages/GiftCards/EditGiftCard.jsx";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: false,
    };
  }
  componentDidMount() {
    let appToken = localStorage.getItem("supportToken");

    //check if page sensitive
    if (appToken === null && window.location.pathname !== "/") {
      window.location.href = "/";
      alert("Login to continue");
    } else this.setState({ rendered: true });
  }

  render() {
    let clearance = localStorage.getItem("supportClearance");
    if (this.state.rendered)
      return (
        <Switch>
          {this.props.children}
          <Route exact path="/" component={Login} />
          {(() => {
            if (clearance === "admin" || clearance === "account")
              return <Route exact path="/overview" component={Dashboard} />;
          })()}
          {(() => {
            if (clearance === "admin")
              return <Route exact path="/staff" component={Staff} />;
          })()}
          {(() => {
            if (
              clearance != null &&
              (clearance === "admin" || clearance.includes("media"))
            )
              return <Route exact path="/messaging" component={Messaging} />;
          })()}

          <Route exact path="/savings_plans" component={SavingsPlans} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/tickets" component={Tickets} />
          <Route exact path="/withdrawals" component={Withdrawals} />
          <Route exact path="/deposits" component={Deposits} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/user/:email" component={UserProfile} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/change_password" component={ChangePassword} />
          <Route exact path="/change_pin" component={ChangePin} />

          {(() => {
            if (
              clearance != null &&
              (clearance === "admin" ||
                clearance.includes("marketting") ||
                clearance.includes("account"))
            )
              return <Route exact path="/report" component={Report} />;
          })()}
          {(() => {
            if (
              clearance != null &&
              (clearance === "admin" || clearance.includes("card_approver"))
            )
              return <Route exact path="/gift_cards" component={GiftCards} />;
          })()}
          {(() => {
            if (
              clearance != null &&
              (clearance === "admin" || clearance.includes("card_approver"))
            )
              return <Route exact path="/giftcards/edit" component={EditGiftCard} />;
          })()}

          {(() => {
            if (clearance === "admin")
              return <Route exact path="/cpanel" component={Cpanel} />;
          })()}
          {(() => {
            if (clearance === "admin")
              return <Route exact path="/manage_ticket" component={ManageTicket} />;
          })()}
          <Route exact path="/champions" component={ChampionsBoard} />

          <Route component={NotFound} />
        </Switch>
      );
    else return null;
  }
}
export default Routes;
