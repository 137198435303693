import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";
import moment from "moment";

class BlockAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      accounts: [],
    };
  }

  handleChange = (evt) => {
    this.setState({ email: evt.target.value });
  };

  componentDidMount() {
    this.getBlockedAccounts();
  }

  getBlockedAccounts() {
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/block/trx", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            accounts: response.data.data,
            pageLoaded: true,
          });
        }
      });
  }

  block = (block, email) => {
    // if the request is to block the transfer
    if (block) {
      //validate
      if (!email.length) {
        alert("Enter user email address");
        return false;
      }

      if (!email.includes("@") || !email.includes(".")) {
        alert("Enter a valid email address");
        return false;
      }
    }

    this.setState({
      isLoading: true,
      email: "",
    });

    axios
      .post(ConstantVar.API_BASE_URL + "/admin/block/", {
        token: localStorage.getItem("supportToken"),
        email: email,
        status: block,
        type: "trx",
      })
      .then((response) => {
        if (response.data.status === "true") {
          this.setState({
            isLoading: false,
          });
          this.getBlockedAccounts();
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        alert(err.response.data.message);
      });
  };

  render() {
    return (
      <div>
        <div className="form-inline p-3">
          <div className="input-group ">
            <input
              required
              name="email"
              className="form-control"
              placeholder="Enter User Email Address"
              value={this.state.email}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <button
            onClick={() => this.block(true, this.state.email)}
            className="btn btn-success"
          >
            Block
          </button>
        </div>

        <hr />

        {this.state.isLoading ? (
          <div class="text-center">
            <label>Please wait....</label>
          </div>
        ) : (
          ""
        )}

        <div>
          <h4 className="p-4">Blocked Transactions</h4>
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Email Address</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Phone</th>
                  <th>Blocked On</th>
                  <th scope="col">...</th>
                </tr>
              </thead>
              <tbody>
                {this.state.accounts.map((account) => (
                  <tr>
                    <td>
                      {" "}
                      <a target="_blank" href={"/user/" + account.email}>
                        {account.email}
                      </a>
                    </td>
                    <td>{account.fname}</td>
                    <td>{account.lname}</td>
                    <td>{account.phone}</td>
                    <td>
                      {" "}
                      {moment(account.blocked_trx_at).format("YYYY MM DD,  H:mm A")}
                    </td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={() => this.block(false, account.email)}
                      >
                        Unblock
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default BlockAccount;
