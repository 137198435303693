import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import axios from "axios";
import { API_BASE_URL } from "../../constants_var";
import EditGiftCardItem from "./EditGiftCardItem";

const EditGiftCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState(undefined);

  async function fetchGiftCardDetails(shouldLoad = true) {
    if (shouldLoad) {
      setIsLoading(true);
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/account/all-cards/support`);
      setPageData(response.data.data);
      if (shouldLoad) {
        setIsLoading(false);
      }
    } catch (err) {
      setPageData(undefined);
      if (shouldLoad) {
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    fetchGiftCardDetails();
  }, []);

  return (
    <div>
      <Sidebar />

      <div className="main-content main-content-my relative">
        <Navbar />
        <div className="header bg-primary pb-8 pt-5 pt-md-8">
          <div className="container-fluid">
            <div className="header-body"></div>
          </div>
        </div>
        {isLoading ? (
          <div className="w-full h-[50vh] flex justify-center items-center ">
            Loading. Please Wait
          </div>
        ) : pageData === undefined ? (
          <div className="w-full h-[50vh] flex justify-center items-center ">
            No data found. Refresh
          </div>
        ) : (
          <div className="bg-white w-[95%] md:w-[90%] h-[90vh] md:h-[80vh] absolute top-[130px] md:top-[17vh] left-[50%] translate-x-[-50%] rounded-3xl shadow-xl p-4">
            <p className="font-bold text-[18px] text-black font-sans my-2">
              Gift Cards Quick Action
            </p>
            <div className="w-full flex my-2">
              <p className="flex-[0.5] font-semibold mx-2">S/N</p>
              <p className="flex-[1.2] font-semibold mx-2">Card</p>
              <p className="flex-[1] font-semibold mx-2">Country</p>
              <p className="flex-[1] font-semibold mx-2">Type</p>
              <p className="flex-[1.5] font-semibold mx-2">Range</p>
              <p className="flex-[1.5] font-semibold mx-2">Rate</p>
              <p className="flex-[1] font-semibold">Status</p>
              <p className="flex-[1] font-semibold">Action</p>
            </div>
            <div className="w-full h-[90%] flex flex-col overflow-y-scroll gap-y-5">
              {pageData.cards.map((e, index) => (
                <div className="w-full h-full  flex flex-col border-b-[1px] border-black/20 py-1">
                  <EditGiftCardItem
                    index={index + 1}
                    cardName={e.card_name}
                    cardCountry={e.country_name}
                    cardType={e.receipt_name}
                    cardRange={e.range_from}
                    cardPrice={e.card_rate}
                    cardStatus={e.is_available}
                    availableRanges={pageData.ranges}
                    callBack={() => fetchGiftCardDetails(false)}
                    cardPriceId={e.price_id}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditGiftCard;
