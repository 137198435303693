import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";
import * as ConstantVar from "../../constants_var.js";
import p2pIcon from "../../components/assets/img/peer-peer.png";
import transferIcon from "../../components/assets/img/fiat_transfer.png";
import buyIcon from "../../components/assets/img/buy.png";
import sellIcon from "../../components/assets/img/sell.png";
import swapIcon from "../../components/assets/img/swap.png";
import receiveIcon from "../../components/assets/img/receive.png";

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      transactions: [],

      // search state
      sType: 0,
      email: "",
      sStartDate: "",
      sEndDate: "",

      clearance: localStorage.getItem("supportClearance"),
      supportPin: localStorage.getItem("supportPin"),

      // refund user state
      trxId: "",
      trxEmail: "",
      trxFullname: "",
      trxAmount: 0,
      pin: "",

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  getTransactions = () => {
    //bind this to self
    let self = this;
    this.setState({
      isLoading: true,
    });
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/transactions", {
        params: {
          token: localStorage.getItem("supportToken"),
          current_page: this.state.currentPage,
          per_page: this.state.perPage,
          slug: this.state.trx_type,
          email: this.state.email,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            transactions: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getTransactions();
    });
  };

  openTransactionSearchDialog = () => {
    window.$("#trxSearchModal").modal("show");
  };

  handleSearch = (evt) => {

    evt.preventDefault();

    window.$("#trxSearchModal").modal("hide");
    let type = evt.target.type.value,
      email = evt.target.email.value,
      start_date = evt.target.start_date.value,
      end_date = evt.target.end_date.value;

    this.setState({
      isLoading: true,
    });

    // if start date or end date is not set, set current date
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    if (!end_date) {
      end_date = next_date;
    }
    if (!start_date) {
      start_date = current_date;
    }

    this.setState(
      {
        paginationLoaded: false,
        start_date: start_date,
        end_date: end_date,
        email: email,
        trx_type: type,
      },()=>
      this.getTransactions()
    );
  };

  handleChange = (evt) => {
    this.setState({ sType: evt });
  };
  handleSearchTextInput = (evt) => {
    this.setState({ email: evt.target.value });
  };
  handleStartDateInput = (evt) => {
    this.setState({ sStartDate: evt.target.value });
  };
  handleEndDateInput = (evt) => {
    this.setState({ sEndDate: evt.target.value });
  };
  handlePinInput = (evt) => {
    this.setState({ pin: evt.target.value });
  };

  openRefundDialog = (trx) => {
    this.setState({
      trxId: trx.id,
      trxEmail: trx.email,
      trxFullname: trx.fname + " " + trx.lname,
      trxAmount: trx.amount,
    });
    window.$("#refundTrxModal").modal("show");
  };

  handleRefund = () => {
    window.$("#refundTrxModal").modal("hide");
    // check if the support pin is equal to provided pin
    if (this.state.pin !== this.state.supportPin) {
      alert("Pin is invalid");
      return false;
    } else {
      // refund the user
      axios
        .post(ConstantVar.API_BASE_URL + "/support/refund", {
          token: localStorage.getItem("supportToken"),
          id: this.state.trxId,
          email: this.state.trxEmail,
        })
        .then((response) => {
          if (response.data.success === "true") {
            alert("Refund successful");
            return false;
          } else {
            alert("Refund failed");
          }
        });
    }
  };

  componentDidMount() {
    this.getTransactions();
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let next_date = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    this.setState({ sStartDate: current_date, sEndDate: next_date });
  }

  render() {
    var paginationElement;
    let skipForRefundList = ["transfer", "repay", ""];

    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar name="trx" func={this.openTransactionSearchDialog} />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2 className="mb-0">Transactions</h2>
                  </div>
                  <hr className="dash-hr" />
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div style={{ padding: 50 }} className="text-center">
                          <div>
                            <p>Loading...</p>
                          </div>
                        </div>
                      );
                    else
                      return this.state.transactions.length ? (
                        <div>
                          <div className="pl-2 ml-5">{paginationElement}</div>
                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <tbody>
                                {this.state.transactions.map((trx, index) => {
                                  return (
                                    <tr>
                                      <th
                                        scope="row dash-td"
                                        style={{ width: "1%" }}
                                      >
                                        <div className="media align-items-center">
                                          {trx.slug === "fiat_deposit" ||
                                          trx.slug === "fiat_withdraw" ? (
                                            <div className="wallet-bckg">
                                              <img src={p2pIcon} width="100%" />
                                            </div>
                                          ) : trx.slug === "fiat_transfer" ||
                                            trx.slug === "fiat_spent" ? (
                                            <div className="wallet-bckg">
                                              <img
                                                src={transferIcon}
                                                width="100%"
                                              />
                                            </div>
                                          ) : trx.slug === "buy" ||
                                            trx.slug === "buy_fee" ? (
                                            <div className="tv-bckg">
                                              <img src={buyIcon} width="100%" />
                                            </div>
                                          ) : trx.slug === "sell" ||
                                            trx.slug === "sell_fee" ? (
                                            <div className="phone-bckg">
                                              <img
                                                src={sellIcon}
                                                width="100%"
                                              />
                                            </div>
                                          ) : trx.slug === "swap" ||
                                            trx.slug === "swap_fee" ? (
                                            <div className="phone-bckg">
                                              <img
                                                src={swapIcon}
                                                width="100%"
                                              />
                                            </div>
                                          ) : trx.slug === "token_received" ? (
                                            <div className="phone-bckg">
                                              <img
                                                src={receiveIcon}
                                                width="100%"
                                              />
                                            </div>
                                          ) : (
                                            <div className="phone-bckg">
                                              <i className="fas fa-money-bill-alt phone-color" />
                                            </div>
                                          )}
                                        </div>
                                      </th>
                                      <td
                                        className="dash-td"
                                        style={{ width: "9%" }}
                                      >
                                        <h4 className="text-muted trans-date">
                                          {moment(trx.created_at).fromNow()}
                                        </h4>
                                      </td>
                                      <td className="dash-td">
                                        <span className="badge-dot mr-4">
                                          <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                            {trx.type}
                                          </p>
                                          <p className="table-dash-details2 mb-0">
                                            <small>{trx.description} </small>
                                          </p>
                                        </span>
                                      </td>

                                      <td className="dash-td">
                                        {trx.direction === "credit" ? (
                                          <span className="float-right trans-credit-color font-weight-normal">
                                            {trx.asset === "NGN" ? (
                                              <NumberFormat
                                                value={trx.amount}
                                                className="card-bal-your h4 text-success"
                                                displayType={"text"}
                                                prefix={"+ NGN "}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                              />
                                            ) : (
                                              <h4 className="text-success">
                                                + {trx.amount.toFixed(8)}{" "}
                                                {trx.asset}
                                              </h4>
                                            )}
                                          </span>
                                        ) : (
                                          <span className="float-right trans-debit-color font-weight-normal">
                                            {trx.asset === "NGN" ? (
                                              <NumberFormat
                                                value={trx.amount}
                                                className="card-bal-your h4 text-danger"
                                                displayType={"text"}
                                                prefix={"- NGN "}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                              />
                                            ) : (
                                              <h4 className="text-danger">
                                                - {trx.amount.toFixed(8)}{" "}
                                                {trx.asset}
                                              </h4>
                                            )}
                                          </span>
                                        )}
                                      </td>

                                      <td className="dash-td">
                                        <a
                                          target="_blank"
                                          href={"/user/" + trx.email}
                                        >
                                          {trx.email}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="p-5 ml-5">{paginationElement}</div>
                        </div>
                      ) : (
                        <h4 class="p-3 text-center">No transactions found</h4>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="trxSearchModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="trxSearchModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="trxSearchModalLabel">
                  Search Transactions
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleSearch} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <select
                      class="form-control form-control-sm"
                      name="type"
                      onChange={this.handleChange}
                    >
                      <option value="">All Transactions</option>
                      <option value="token_deposit">Deposit (token)</option>
                      <option value="fiat_withdraw">Withdraw (fiat)</option>
                      <option value="fiat_transfer">Transfer</option>
                      <option value="fiat_deposit">Deposit (fiat)</option>
                      <option value="token_received">Withdraw (token)</option>
                      <option value="buy">Buy</option>
                      <option value="sell">Sell</option>
                      <option value="swap">Swap</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-sm"
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleSearchTextInput}
                    />
                  </div>
                  <div class="form-group row">
                    <div className="col-6 form-inline">
                      <label class="form-control-label">Start Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        name="start_date"
                        value={this.state.sStartDate}
                        onChange={this.handleStartDateInput}
                      />
                    </div>
                    <div className="col-6 form-inline">
                      <label class="form-control-label">End Date</label>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        name="end_date"
                        value={this.state.sEndDate}
                        onChange={this.handleEndDateInput}
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary btn-sm">
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="refundTrxModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="refundTrxModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="refundTrxModalLabel">
                  Confirm Refund
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>
                  Are you sure you want to refund {this.state.trxFullname} with{" "}
                  {this.state.trxAmount}
                </label>
                <div class="form-group">
                  <input
                    class="form-control form-control-sm"
                    type="password"
                    placeholder="PIN"
                    name="pin"
                    value={this.state.pin}
                    onChange={this.handlePinInput}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={this.handleRefund}
                  class="btn btn-primary btn-sm"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;
