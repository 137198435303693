import React, { Component } from "react";
import { Link } from "react-router-dom";
// import './assets/index.css';
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class TokenDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      isLoading: true,
      email: "",
      startDate: null,
      endDate: null,
      asset: null,

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDeposits();
  }

  getDeposits = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/token_deposits", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
          asset: this.state.asset,
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            deposits: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getDeposits();
    });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2>Token Deposits</h2>
            <div className=" form-inline float-right">
              <button
                type="button"
                onClick={() => {
                  window.$("#filterModal").modal("show");
                }}
                class="btn btn-primary btn-sm"
              >
                Filter
              </button>
            </div>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.deposits.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>Asset</th>
                            <th>Network</th>
                            <th>Address</th>
                            <th>Memo</th>
                            <th>Source Address</th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                          </thead>
                          <tbody>
                            {this.state.deposits.map((deposit, index) => {
                              return (
                                <tr>
                                  <th>{index + 1}</th>
                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(deposit.created_at).format("MMM Do")}

                                        <br />
                                        {moment(deposit.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td" style={{ width: "9%" }}>
                                    <div>
                                      <a target="_blank" href={"/user/" + deposit.email}>
                                        {deposit.email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="dash-td">
                                    <h3 className="">
                                      <p>{deposit.coin}</p>
                                    </h3>
                                  </td>
                                  <td className="dash-td">
                                    <p>{deposit.network}</p>
                                  </td>
                                  <td className="dash-td">
                                    <p>{deposit.address}</p>
                                  </td>
                                  <td className="dash-td">
                                    <p>{deposit.memo}</p>
                                  </td>

                                  <td className="dash-td">
                                    <p>{deposit.source_address}</p>
                                  </td>

                                  <td className="dash-td">
                                    <p>{deposit.trx_id}</p>
                                  </td>

                                  <td className="dash-td">
                                    <h3 className="text-success">
                                      <p>{deposit.amount}</p>
                                    </h3>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No deposits yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>

        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">
                  Filter Deposits
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  this.setState(
                    {
                      currentPage: 1,
                      paginationLoaded: false,
                    },
                    () => {
                      window.$("#filterModal").modal("hide");
                      this.getDeposits();
                    }
                  );
                }}
                autoComplete="off"
              >
                <div class="modal-body">
                  <div className=" form-group">
                    <label> Per Page</label>
                    <input
                      type="number"
                      name="perPage"
                      placeholder="Per Page"
                      value={this.state.perPage}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Asset</label>
                    <input
                      type="text"
                      name="asset"
                      placeholder="Asset eg BTC"
                      value={this.state.asset}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className=" form-group">
                    <label> Email Address</label>
                    <input
                      className="form-control"
                      placeholder="Email Address:"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TokenDeposit;
