import React, { Component } from "react";
import "./assets/index.css";
import NumberFormat from "react-number-format";
import moment from "moment/moment";
import p2pIcon from "../../components/assets/img/peer-peer.png";
import transferIcon from "../../components/assets/img/fiat_transfer.png";
import buyIcon from "../../components/assets/img/buy.png";
import sellIcon from "../../components/assets/img/sell.png";
import swapIcon from "../../components/assets/img/swap.png";
import receiveIcon from "../../components/assets/img/receive.png";
import ReactPaginate from "react-paginate";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: props.transactions.data,
      pageCount: Math.ceil(props.transactions.pagination.total/props.transactions.pagination.perPage),
      currentPage: props.transactions.pagination.currentPage,
      perPage:props.transactions.pagination.perPage
    };
    
  }
  

  componentDidMount() {
  }
  

  render() {
    console.log(this.state.transactions)
    return (
      <div
        className="tab-pane fade"
        id="nav-transactions"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div className="card bg-secondary shadow">
          <div className="card-header bg-white border-0">
            <div className="row align-items-center">
              <div className="col-8">
                <h3 className="mb-0">Transactions</h3>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <hr className="dash-hr" />
              <div className="table-responsive">  
              <div className="px-4">
      { <ReactPaginate
      previousLabel={"PREVIOUS"}
      nextLabel={"NEXT"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={this.state.pageCount}
      // marginPagesDisplayed={2}
      // pageRangeDisplayed={10}
      // forcePage={1}
      onPageChange={async(e)=>{
        const response =await this.props.handlePagination(e)
this.setState({transactions:response.data,
  currentPage: response.pagination.currentPage,
  perPage:response.pagination.perPage

})

        
      }}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
    />}
              </div>
                <table className="table align-items-center table-flush">
                  <thead>
                    <th>Date</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Description</th>
                    <th>Wallet. Bal</th>
                  </thead>
                  <tbody>
                    {this.state.transactions.length > 0 ? (
                      this.state.transactions.map((trx, index) => {
                        return (
                          <tr key={trx.id}>
                            <th scope="row dash-td" style={{ width: "1%" }}>
                              <div className="media align-items-center">
                                {trx.slug === "fiat_deposit" ||
                                trx.slug === "fiat_withdraw" ? (
                                  <div className="wallet-bckg">
                                    <img src={p2pIcon} width="100%" />
                                  </div>
                                ) : trx.slug === "fiat_transfer" ||
                                  trx.slug === "fiat_spent" ? (
                                  <div className="wallet-bckg">
                                    <img src={transferIcon} width="100%" />
                                  </div>
                                ) : trx.slug === "buy" ||
                                  trx.slug === "buy_fee" ? (
                                  <div className="tv-bckg">
                                    <img src={buyIcon} width="100%" />
                                  </div>
                                ) : trx.slug === "sell" ||
                                  trx.slug === "sell_fee" ? (
                                  <div className="phone-bckg">
                                    <img src={sellIcon} width="100%" />
                                  </div>
                                ) : trx.slug === "swap" ||
                                  trx.slug === "swap_fee" ? (
                                  <div className="phone-bckg">
                                    <img src={swapIcon} width="100%" />
                                  </div>
                                ) : trx.slug === "token_received" ? (
                                  <div className="phone-bckg">
                                    <img src={receiveIcon} width="100%" />
                                  </div>
                                ) : (
                                  <div className="phone-bckg">
                                    <i className="fas fa-money-bill-alt phone-color" />
                                  </div>
                                )}
                              </div>
                            </th>

                            <td className="dash-td" style={{ width: "9%" }}>
                              <h4 className="text-muted trans-date">
                                {/* {moment(trx.created_at).format("MMM Do")} <br /> */}
                                {moment(trx.created_at).format("MMM Do YYYY")} <br />
                                {moment(trx.created_at).format("h:mm:ss a")}
                              </h4>
                            </td>
                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <p className="table-dash-details2 mb-0">
                                {trx.direction === "credit" ? (
                                  <span className="float-left trans-credit-color font-weight-normal">
                                    {trx.asset === "NGN" ? (
                                      <NumberFormat
                                        value={trx.amount}
                                        className="card-bal-your h4 text-success"
                                        displayType={"text"}
                                        prefix={"+ NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    ) : (
                                      <h4 className="text-success">
                                        + {trx.amount.toFixed(8)} {trx.asset}
                                      </h4>
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </td>
                            <td
                              className="dash-td"
                              align="left"
                              style={{ width: "12%" }}
                            >
                              <p className="table-dash-details2 mb-0">
                                {trx.direction === "debit" ? (
                                  <span className="float-left trans-debit-color font-weight-normal">
                                    {trx.asset === "NGN" ? (
                                      <NumberFormat
                                        value={trx.amount}
                                        className="card-bal-your h4 text-danger"
                                        displayType={"text"}
                                        prefix={"- NGN "}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                      />
                                    ) : (
                                      <h4 className="text-danger">
                                        - {trx.amount.toFixed(8)} {trx.asset}
                                      </h4>
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </td>
                            <td className="dash-td">
                              <span className="badge-dot mr-4">
                                <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                  {trx.type}
                                </p>
                                <p className="table-dash-details2 mb-0">
                                  {trx.description}{" "}
                                </p>
                              </span>
                            </td>
                            <td className="dash-td">
                              <p className="font-weight-bold table-dash-details1 table-trans-details1 mb-0 mt-2">
                                {trx.asset === "NGN" ? (
                                  <NumberFormat
                                    value={trx.fiat_bal}
                                    className="card-bal-your"
                                    displayType={"text"}
                                    prefix={"NGN "}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center no-data full-width-data">
                            {/*<img className="no-trans-overview" src={transimg} />*/}
                            <p className="text-muted no-trans-text-o">
                              No transaction done yet!
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Transaction;
