import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./assets/img/logo.png";
import img from "./assets/img/person.jpg";
import "./assets/index.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.clear();
    window.location.href = "/";
  }
  render() {
    let clearance = localStorage.getItem("supportClearance");
    console.log(clearance);
    return (
      <div>
        <nav
          className="navbar  navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
          id="sidenav-main"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidenav-collapse-main"
              aria-controls="sidenav-main"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="navbar-brand pt-0 mobile-hide">
              <img src={logo} className="navbar-brand-img" alt="hometown-logo" /> <br />
              <h4 className="font-weight-bold mt-1">{clearance.toUpperCase()}</h4>
            </div>
            <ul className="nav align-items-center d-md-none">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="media align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="Image placeholder" src={img} />
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                  <a onClick={this.logout} className="dropdown-item">
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </a>
                  <a href="/change_password" className="dropdown-item">
                    <i className="ni ni-user-run" />
                    <span>Change Password</span>
                  </a>
                  <a href="/change_pin" className="dropdown-item">
                    <i className="ni ni-user-run" />
                    <span>Change Pin</span>
                  </a>
                </div>
              </li>
            </ul>
            <div className="collapse navbar-collapse" id="sidenav-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <a>
                      <img src={logo} />
                    </a>
                  </div>
                  <div className="col-6 collapse-close">
                    <button
                      type="button"
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#sidenav-collapse-main"
                      aria-controls="sidenav-main"
                      aria-expanded="false"
                      aria-label="Toggle sidenav"
                    ></button>
                  </div>
                </div>
              </div>
              <form className="mt-4 mb-3 d-md-none">
                <div className="input-group input-group-rounded input-group-merge">
                  <input
                    type="search"
                    className="form-control form-control-rounded form-control-prepended"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className="fa fa-search" />
                    </div>
                  </div>
                </div>
              </form>
              <hr className="hr-sidebar mobile-hide" />
              <ul className="navbar-nav">
                {(() => {
                  if (clearance === "admin" || clearance.includes("account"))
                    return (
                      <li className="nav-item">
                        <Link to="/overview" className="nav-link">
                          <p
                            className={
                              "sidebar-icon-color font-weight-bold" +
                              (window.location.href.includes("/overview")
                                ? " sidebar-icon-color-active"
                                : "")
                            }
                          >
                            {" "}
                            <i className="fas fa-tv" />{" "}
                            <span className="magin-left-sidebar">Overview</span>{" "}
                          </p>
                        </Link>
                      </li>
                    );
                })()}
                {(() => {
                  if (clearance === "admin")
                    return (
                      <li className="nav-item">
                        <Link to="/staff" className="nav-link">
                          <p
                            className={
                              "sidebar-icon-color font-weight-bold" +
                              (window.location.href.includes("/staff")
                                ? " sidebar-icon-color-active"
                                : "")
                            }
                          >
                            {" "}
                            <i className="fas fa-people-carry" />{" "}
                            <span className="magin-left-sidebar">Staff Accounts</span>{" "}
                          </p>
                        </Link>
                      </li>
                    );
                })()}
                {(() => {
                  if (clearance === "admin" || clearance.includes("media"))
                    return (
                      <li className="nav-item">
                        <Link to="/messaging" className="nav-link">
                          <p
                            className={
                              "sidebar-icon-color font-weight-bold" +
                              (window.location.href.includes("/messaging")
                                ? " sidebar-icon-color-active"
                                : "")
                            }
                          >
                            {" "}
                            <i className="fas fa-envelope" />{" "}
                            <span className="magin-left-sidebar">Messaging Center</span>{" "}
                          </p>
                        </Link>
                      </li>
                    );
                })()}

                <li className="nav-item">
                  <Link to="/users" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/users")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      <i className="fas fa-users" />{" "}
                      <span className="magin-left-sidebar">Users</span>{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/transactions" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/transactions")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-tasks" />{" "}
                      <span className="magin-left-sidebar">Transactions</span>{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/tickets" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/tickets")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-clipboard-list" />{" "}
                      <span className="magin-left-sidebar">Tickets</span>{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/manage_ticket" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/manage_ticket")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-clipboard-list" />{" "}
                      <span className="magin-left-sidebar">Manage Ticket</span>{" "}
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/withdrawals" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/withdrawals")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-list" />{" "}
                      <span className="magin-left-sidebar">Withdrawals</span>{" "}
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/savings_plans" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/savings_plans")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-list" />{" "}
                      <span className="magin-left-sidebar">Savings Plans</span>{" "}
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/deposits" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/deposits")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-list" />{" "}
                      <span className="magin-left-sidebar">Deposits</span>{" "}
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/account" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/account")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      {" "}
                      <i className="fas fa-envelope" />{" "}
                      <span className="magin-left-sidebar">Account</span>{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/champions" className="nav-link">
                    <p
                      className={
                        "sidebar-icon-color font-weight-bold" +
                        (window.location.href.includes("/champions")
                          ? " sidebar-icon-color-active"
                          : "")
                      }
                    >
                      <i className="fa fa-trophy" />{" "}
                      <span className="magin-left-sidebar">Champions Board</span>{" "}
                    </p>
                  </Link>
                </li>
                {clearance != null &&
                  (clearance === "admin" || clearance.includes("card_approver")) && (
                    <li>
                      <Link to="/gift_cards" className="nav-link">
                        <p
                          className={
                            "sidebar-icon-color font-weight-bold" +
                            (window.location.href.includes("/gift_cards")
                              ? " sidebar-icon-color-active"
                              : "")
                          }
                        >
                          {" "}
                          <i className="fas fa-envelope" />{" "}
                          <span className="magin-left-sidebar">Gift Cards</span>{" "}
                        </p>
                      </Link>
                    </li>
                  )}
                {clearance != null &&
                  (clearance === "admin" || clearance.includes("card_approver")) && (
                    <li>
                      <Link to="/giftcards/edit" className="nav-link">
                        <p
                          className={
                            "sidebar-icon-color font-weight-bold" +
                            (window.location.href.includes("/giftcards/edit")
                              ? " sidebar-icon-color-active"
                              : "")
                          }
                        >
                          {" "}
                          <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                          <span className="magin-left-sidebar">Edit Gift Cards</span>{" "}
                        </p>
                      </Link>
                    </li>
                  )}

                {(() => {
                  if (
                    clearance === "admin" ||
                    clearance.includes("marketting") ||
                    clearance.includes("account")
                  )
                    return (
                      <li className="nav-item">
                        <Link to="/report" className="nav-link">
                          <p
                            className={
                              "sidebar-icon-color font-weight-bold" +
                              (window.location.href.includes("/report")
                                ? " sidebar-icon-color-active"
                                : "")
                            }
                          >
                            {" "}
                            <i className="fas fa-info" />{" "}
                            <span className="magin-left-sidebar">Report</span>{" "}
                          </p>
                        </Link>
                      </li>
                    );
                })()}

                {(() => {
                  if (clearance === "admin")
                    return (
                      <li className="nav-item">
                        <Link to="/cpanel" className="nav-link">
                          <p
                            className={
                              "sidebar-icon-color font-weight-bold" +
                              (window.location.href.includes("/cpanel")
                                ? " sidebar-icon-color-active"
                                : "")
                            }
                          >
                            {" "}
                            <i className="fas fa-cog" />{" "}
                            <span className="magin-left-sidebar">Control Panel</span>{" "}
                          </p>
                        </Link>
                      </li>
                    );
                })()}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
