import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../../constants_var.js";

class P2pAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      isLoading: true,
      account: "",
      account_name: "",
      account_number: "",
      bank_name: "",
      type: "",
      fee: 0,
      fee_perc: 0,
      pin: "",
      processing_method: "manual",

      account: {},
      account_fee: 0,
      account_fee_perc: 0,
      account_status: "",
      update_type: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getp2pAccounts();
  }

  getp2pAccounts = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/p2p_accounts", {
        params: {
          token: localStorage.getItem("supportToken"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            accounts: response.data.data,
            isLoading: false,
            isSubmitting: false,
          });
        }
      });
  };

  addAccount = (e) => {
    e.preventDefault();

    //bind this to self
    let self = this;
    this.setState({
      isSubmitting: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_account", {
        token: localStorage.getItem("supportToken"),
        account: this.state.account_username,
        account_name: this.state.account_name,
        account_number: this.state.account_number,
        bank_name: this.state.bank_name,
        fee: this.state.fee,
        fee_perc: this.state.fee_perc,
        processing_method: this.state.processing_method,
        type: this.state.type,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.scrollTo(0, 0);
          this.getp2pAccounts();
          window.$("#addP2pAccountModal").modal("hide");
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  updateAccount = (e) => {
    e.preventDefault();

    //bind this to self
    let self = this;
    this.setState({
      isSubmitting: true,
    });
    axios
      .patch(ConstantVar.API_BASE_URL + "/admin/p2p_account", {
        token: localStorage.getItem("supportToken"),
        account: this.state.account.account,
        fee: this.state.account_fee,
        fee_perc: this.state.account_fee_perc,
        status: this.state.account_status,
        pin: this.state.pin,
        type: this.state.update_type,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.scrollTo(0, 0);
          this.getp2pAccounts();
          window.$("#updateP2pAccountModal").modal("hide");
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <div className="mt-4">
        <div className=" form-inline float-right p-3">
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => {
              this.setState({
                update_type: "fee",
              });
              window.$("#addP2pAccountModal").modal("show");
            }}
          >
            Add New
          </button>
        </div>

        {(() => {
          if (this.state.isLoading)
            return (
              <div style={{ padding: 50 }} className="text-center">
                <div>
                  <p>Loading...</p>
                </div>
              </div>
            );
          else
            return (
              <div className="table-responsive">
                {this.state.accounts.length > 0 ? (
                  <div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead>
                          <th>Account</th>
                          <th>Account Name</th>
                          <th>Account Number</th>
                          <th>Bank Name</th>
                          <th>Type</th>
                          <th>Fee(#)</th>
                          <th>Fee(%)</th>
                          <th>....</th>
                        </thead>
                        <tbody>
                          {this.state.accounts.map((account) => {
                            return (
                              <tr>
                                <th scope="row dash-td">{account.account}</th>
                                <td className="dash-td" style={{ width: "9%" }}>
                                  {account.account_name}
                                </td>
                                <td className="dash-td">
                                  <h3 className="">
                                    <p>{account.account_number}</p>
                                  </h3>
                                </td>
                                <td className="dash-td">
                                  <p>{account.bank_name}</p>
                                </td>

                                <td
                                  className={
                                    account.type === "deposit"
                                      ? "dash-td text-success"
                                      : "text-danger"
                                  }
                                >
                                  {account.type}{" "}
                                  {account.type === "deposit" && (
                                    <label className="text-dark">
                                      {" "}
                                      ({account.processing_method})
                                    </label>
                                  )}
                                </td>
                                <td className="dash-td">{account.fee}</td>
                                <td className="dash-td">{account.fee_perc}</td>

                                <td className="dash-td">
                                  {account.status === 0 ? (
                                    <button
                                      className="btn btn-sm btn-primary"
                                      disabled={this.state.isSubmitting}
                                      onClick={() => {
                                        this.setState({
                                          update_type: "status",
                                          account_status: 1,
                                          account,
                                        });
                                        window.$("#updateP2pAccountModal").modal("show");
                                      }}
                                    >
                                      Enable
                                    </button>
                                  ) : account.status === 1 ? (
                                    <button
                                      className="btn btn-sm btn-warning"
                                      disabled={this.state.isSubmitting}
                                      onClick={() => {
                                        this.setState({
                                          update_type: "status",
                                          account_status: 0,
                                          account,
                                        });
                                        window.$("#updateP2pAccountModal").modal("show");
                                      }}
                                    >
                                      Disable
                                    </button>
                                  ) : (
                                    ""
                                  )}

                                  <button
                                    className="btn btn-sm btn-primary"
                                    disabled={this.state.isSubmitting}
                                    onClick={() => {
                                      this.setState({
                                        update_type: "fee",
                                        account,
                                        account_fee: account.fee,
                                        account_fee_perc: account.fee_perc,
                                      });
                                      window.$("#updateP2pAccountModal").modal("show");
                                    }}
                                  >
                                    Update Fee
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="text-center no-data full-width-data">
                    <p className="text-muted no-trans-text-o">No Accounts yet!</p>
                  </div>
                )}
              </div>
            );
        })()}

        <div
          class="modal fade"
          id="addP2pAccountModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addP2pAccountModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addP2pAccountModalLabel">
                  Add P2P Account
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.addAccount} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Account</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Account"
                      name="account_username"
                      value={this.state.account_username}
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Account Name</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Account Name"
                      name="account_name"
                      value={this.state.account_name}
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Account Number</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Account Number"
                      name="account_number"
                      value={this.state.account_number}
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Bank Name</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Bank Name"
                      name="bank_name"
                      value={this.state.bank_name}
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label>Fee</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Fee"
                      name="fee"
                      required
                      value={this.state.fee}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Fee Percentage</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Fee %"
                      name="fee_perc"
                      required
                      value={this.state.fee_perc}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Type</label>
                    <select
                      class="form-control form-control-sm"
                      name="type"
                      value={this.state.type}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Select</option>
                      <option value="deposit">Deposit</option>
                      <option value="withdraw">Withdraw</option>
                    </select>
                  </div>

                  {this.state.type === "deposit" && (
                    <div class="form-group">
                      <label>Process Deposit</label>
                      <select
                        class="form-control form-control-sm"
                        name="processing_method"
                        value={this.state.processing_method}
                        onChange={this.handleChange}
                      >
                        <option value="manual">Manually</option>
                        <option value="auto">Automatically</option>
                      </select>
                    </div>
                  )}

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      required
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="updateP2pAccountModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateP2pAccountModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateP2pAccountModalLabel">
                  Update P2P Account
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.updateAccount} autoComplete="off">
                <div class="modal-body">
                  <h5>@{this.state.account.account}</h5>

                  {this.state.update_type === "fee" && (
                    <div>
                      <div class="form-group">
                        <label>Fee</label>
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Account"
                          name="account_fee"
                          value={this.state.account_fee}
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label>Fee Percentage</label>
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Account"
                          name="account_fee_perc"
                          value={this.state.account_fee_perc}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  )}

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default P2pAccounts;
