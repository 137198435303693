import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class FiatDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      isLoading: true,
      email: "",
      status: "all",
      startDate: null,
      endDate: null,

      //pagination state
      perPage: 50,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,

      deposit: {},
      otp_sent: false,
      amount_received: 0,
      auth_code: "",
      pin: "",
      isSubmitting: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDeposits();
  }

  getDeposits = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transactions", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          status: this.state.status,
          type: "deposit",
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
          end_date: this.state.endDate || moment().format("YYYY-MM-DD"),
          start_date: this.state.startDate || moment().format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            deposits: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  sendOTP = () => {
    //bind this to self
    this.setState({
      otp_sent: true,
    });
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transaction/auth_code", {
        token: localStorage.getItem("supportToken"),
        from: `${this.state.deposit.u_account_number} - (${this.state.deposit.u_account_name}) - ${this.state.deposit.u_bank_name}`,
        to: `${this.state.deposit.v_account_number} - (${this.state.deposit.v_account_name}) - ${this.state.deposit.v_account_name}`,
        reference: this.state.deposit.reference,
        amount: this.state.amount_received,
      })
      .then((response) => {
        if (response.data.status === "true") {
          setTimeout(() => {
            self.setState({
              otp_sent: false,
            });
          }, 60000);
        }
      });
  };

  confirmDeposit = (e) => {
    e.preventDefault();

    //bind this to self
    let self = this;
    this.setState({
      isSubmitting: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transaction/confirm", {
        token: localStorage.getItem("supportToken"),
        reference: this.state.deposit.reference,
        amount_received: this.state.amount_received,
        pin: this.state.pin,
        auth_code: this.state.auth_code,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.scrollTo(0, 0);
          this.getDeposits();
          window.$("#confirmBankDepositModal").modal("hide");
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  markAsExpired = (e) => {
    e.preventDefault();
    if (this.state.pin.length !== 6) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });
    axios
      .post(ConstantVar.API_BASE_URL + "/admin/p2p_bank_transaction/expired", {
        token: localStorage.getItem("supportToken"),
        reference: this.state.deposit.reference,
        pin: this.state.pin,
      })
      .then((response) => {
        if (response.data.status === "true") {
          window.scrollTo(0, 0);
          this.getDeposits();
          window.$("#markAsExpiredModal").modal("hide");
          this.setState({ pin: "" });
          alert(response.data.message);
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitting: false,
        });
        alert(err.response.data.message);
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getDeposits();
    });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <div className="card shadow">
          <div className="card-header border-0">
            <h2>Bank Fiat Deposits</h2>
            <div className=" form-inline float-right">
              <button
                type="button"
                onClick={() => {
                  window.$("#filterModal").modal("show");
                }}
                class="btn btn-primary btn-sm"
              >
                Filter
              </button>
            </div>
          </div>
          <hr className="dash-hr" />
          {(() => {
            if (this.state.isLoading)
              return (
                <div style={{ padding: 50 }} className="text-center">
                  <div>
                    <p>Loading...</p>
                  </div>
                </div>
              );
            else
              return (
                <div className="table-responsive">
                  {this.state.deposits.length > 0 ? (
                    <div>
                      <div className="pl-2 ml-5">{paginationElement}</div>
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead>
                            <th>S/N</th>
                            <th>Date created</th>
                            <th>Email</th>
                            <th>Reference</th>
                            <th>Amount</th>
                            <th>Received (vendor)</th>
                            <th>Credited</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            {this.state.deposits.map((deposit, index) => {
                              return (
                                <tr
                                  onClick={() => {
                                    if (
                                      deposit.status === 4 ||
                                      (deposit.status === 0 &&
                                        deposit.v_processing_method === "auto")
                                    ) {
                                      this.setState({
                                        deposit: deposit,
                                        amount_received: deposit.amount,
                                        isSubmitting: false,
                                        isLoading: false,
                                      });
                                      window.$("#previewDepositModal").modal("show");
                                    }
                                  }}
                                >
                                  <th>{index + 1}</th>
                                  <th scope="row dash-td" style={{ width: "1%" }}>
                                    <div className="media align-items-center">
                                      <p className="text-muted trans-date">
                                        {moment(deposit.created_at).format("MMM Do")}

                                        <br />
                                        {moment(deposit.created_at).format("hh:mm A")}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="dash-td" style={{ width: "9%" }}>
                                    <div>
                                      <a target="_blank" href={"/user/" + deposit.email}>
                                        {deposit.email}
                                      </a>
                                    </div>
                                  </td>

                                  <td className="dash-td">
                                    <p>{deposit.reference}</p>
                                  </td>

                                  <td className="dash-td">
                                    <NumberFormat
                                      value={deposit.amount}
                                      className="card-bal-your text-danger"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </td>

                                  <td className="dash-td">
                                    <NumberFormat
                                      value={deposit.v_amount_received}
                                      className="card-bal-your text-success"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </td>

                                  <td className="dash-td">
                                    <NumberFormat
                                      value={deposit.amount_credited}
                                      className="card-bal-your text-success"
                                      displayType={"text"}
                                      prefix={"NGN "}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                    />
                                  </td>

                                  <td className="dash-td">
                                    {deposit.status === 0 ? (
                                      <div>
                                        <button
                                          className="success btn btn-sm text-dark"
                                          style={{
                                            backgroundColor: "yellow",
                                          }}
                                        >
                                          Awaiting
                                        </button>

                                        {deposit.ticket_id !== null && (
                                          <a
                                            target="_blank"
                                            href={
                                              "/tickets?ticket_id=" + deposit.ticket_id
                                            }
                                          >
                                            <button
                                              className="success btn btn-sm btn-primary"
                                              style={{ width: "100px" }}
                                            >
                                              View Ticket
                                            </button>{" "}
                                          </a>
                                        )}

                                        {deposit.v_processing_method === "manual" && (
                                          <button
                                            className=" btn btn-sm btn-primary"
                                            onClick={() => {
                                              this.setState({
                                                deposit: deposit,
                                                amount_received: deposit.amount,
                                                isSubmitting: false,
                                                isLoading: false,
                                              });
                                              window
                                                .$("#confirmBankDepositModal")
                                                .modal("show");
                                            }}
                                          >
                                            Confirm
                                          </button>
                                        )}

                                        {deposit.on_hold === 1 ||
                                        deposit.ticket_id !== null ? (
                                          <button
                                            className=" btn btn-sm btn-primary"
                                            onClick={() => {
                                              this.setState({
                                                deposit: deposit,
                                                isSubmitting: false,
                                                isLoading: false,
                                              });
                                              window
                                                .$("#markAsExpiredModal")
                                                .modal("show");
                                            }}
                                          >
                                            Mark as expired
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : deposit.status === 4 ? (
                                      <button
                                        className="success btn btn-sm btn-success"
                                        style={{ width: "100px" }}
                                      >
                                        Successful
                                      </button>
                                    ) : deposit.status === 2 ? (
                                      <button className="success btn btn-sm btn-danger">
                                        Cancelled
                                      </button>
                                    ) : deposit.status === 5 ? (
                                      <button className="success btn btn-sm btn-warning">
                                        Expired
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="p-5 ml-5">{paginationElement}</div>
                    </div>
                  ) : (
                    <div className="text-center no-data full-width-data">
                      <p className="text-muted no-trans-text-o">No deposits yet!</p>
                    </div>
                  )}
                </div>
              );
          })()}
        </div>

        <div
          class="modal fade"
          id="confirmBankDepositModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="confirmBankDepositModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirmBankDepositModalLabel">
                  Confirm Deposit
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.confirmDeposit} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Source Account</label>
                    <p className="text-muted">
                      {this.state.deposit.u_account_name}
                      <br />({this.state.deposit.u_account_number})<br />
                      {this.state.deposit.u_bank_name}
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Received with @{this.state.deposit.v_account}</label>
                    <p className="text-muted">
                      {this.state.deposit.v_account_name}
                      <br />({this.state.deposit.v_account_number})<br />
                      {this.state.deposit.v_bank_name}
                    </p>
                  </div>
                  <div class="form-group">
                    <label>Amount Received</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Amount Received"
                      name="amount_received"
                      value={this.state.amount_received}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>OTP</label>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      placeholder="Enter OTP"
                      name="auth_code"
                      value={this.state.auth_code}
                      onChange={this.handleChange}
                    />
                    <small>
                      {!this.state.otp_sent ? (
                        <a
                          href="#"
                          disabled={this.state.isSubmitting}
                          onClick={this.sendOTP}
                        >
                          Request OTP
                        </a>
                      ) : (
                        <a className="text-success">OTP successfully sent</a>
                      )}
                    </small>
                  </div>

                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Confirm Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="previewDepositModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="previewDepositModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="previewDepositModalLabel">
                  Deposit Preview
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.confirmDeposit} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Source Account</label>
                    <p className="text-muted">
                      {this.state.deposit.u_account_name}
                      <br />({this.state.deposit.u_account_number})<br />
                      {this.state.deposit.u_bank_name}
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Received with @{this.state.deposit.v_account}</label>
                    <p className="text-muted">
                      {this.state.deposit.v_account_name}
                      <br />({this.state.deposit.v_account_number})<br />
                      {this.state.deposit.v_bank_name}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="markAsExpiredModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="markAsExpiredModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="markAsExpiredModalLabel">
                  Mark deposit as expired?
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.markAsExpired} autoComplete="off">
                <div class="modal-body">
                  <div class="form-group">
                    <label>Confirmation PIN</label>
                    <input
                      class="form-control form-control-sm"
                      type="password"
                      placeholder="PIN"
                      name="pin"
                      required
                      value={this.state.pin}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Confirm Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="filterModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="filterModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="filterModalLabel">
                  Filter Deposits
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  this.setState(
                    {
                      currentPage: 1,
                      paginationLoaded: false,
                    },
                    () => {
                      window.$("#filterModal").modal("hide");
                      this.getDeposits();
                    }
                  );
                }}
                autoComplete="off"
              >
                <div class="modal-body">
                  <div className=" form-group">
                    <label> Per Page</label>
                    <input
                      type="number"
                      name="perPage"
                      placeholder="Per Page"
                      value={this.state.perPage}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label> Status</label>
                    <select
                      className="form-control"
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="successful">Successful</option>
                      <option value="pending">Pending</option>
                      <option value="expired">Expired</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Start Date </label>
                    <input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date </label>
                    <input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className=" form-group">
                    <label> Email Address</label>
                    <input
                      className="form-control"
                      placeholder="Email Address:"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    disabled={this.state.isSubmitting}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiatDeposit;
