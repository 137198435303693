import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar.js";
import Sidebar from "../../components/sidebar.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import * as ConstantVar from "../../constants_var.js";
import ReactPaginate from "react-paginate";
import "../../components/assets/paginate.css";

class FiatDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savings: [],
      isLoading: true,
      email: "",
      status: "all",

      //pagination state
      perPage: 20,
      currentPage: 1,
      paginationLoaded: false,
      totalEntries: 0,
      _from: 0,
      _to: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSavings();
  }
  deposit_confirmation_auth_code;

  getSavings = () => {
    this.setState({
      isLoading: true,
    });
    //bind this to self
    let self = this;
    axios
      .get(ConstantVar.API_BASE_URL + "/admin/savings_plans", {
        params: {
          token: localStorage.getItem("supportToken"),
          email: this.state.email,
          status: this.state.status,
          per_page: this.state.perPage,
          current_page: this.state.currentPage,
        },
      })
      .then((response) => {
        if (response.data.status === "true") {
          self.setState({
            savings: response.data.data.data,
            isLoading: false,
            _from: response.data.data.pagination.from,
            _to: response.data.data.pagination.to,
          });

          if (!this.state.paginationLoaded) {
            let total = response.data.data.pagination.total;
            self.setState({
              pageCount: Math.ceil(total / this.state.perPage),
              currentPage: response.data.data.pagination.currentPage,
              paginationLoaded: true,
              totalEntries: total,
            });
          }
        }
      });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handlePagination = (data) => {
    const selectedPage = data.selected;
    this.setState({ currentPage: selectedPage + 1 }, () => {
      this.getSavings();
    });
  };

  render() {
    var paginationElement;
    if (this.state.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"PREVIOUS"}
          nextLabel={"NEXT"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          forcePage={this.state.currentPage - 1}
          onPageChange={this.handlePagination}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }
    return (
      <div>
        <Sidebar />
        <div className="main-content main-content-my">
          <Navbar />
          <div className="header bg-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body"></div>
            </div>
          </div>

          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <h2>Savings Plans</h2>
                    <div className=" form-inline float-left">
                      <select
                        className="form-control"
                        name="status"
                        onChange={(evt) => {
                          this.setState(
                            {
                              [evt.target.name]: evt.target.value,
                              currentPage: 1,
                              paginationLoaded: false,
                            },
                            () => {
                              this.getSavings();
                            }
                          );
                        }}
                      >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="matured">matured</option>
                        <option value="withdrew">Withdrew</option>
                      </select>
                    </div>
                    <div className=" form-inline float-right">
                      <input
                        className="form-control"
                        placeholder="Email Address:"
                        name="email"
                        onChange={this.handleChange}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          if (this.state.email.length > 5) {
                            this.setState(
                              {
                                currentPage: 1,
                                paginationLoaded: false,
                              },
                              () => {
                                this.getSavings();
                              }
                            );
                          }
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <hr className="dash-hr" />
                  {(() => {
                    if (this.state.isLoading)
                      return (
                        <div style={{ padding: 50 }} className="text-center">
                          <div>
                            <p>Loading...</p>
                          </div>
                        </div>
                      );
                    else
                      return (
                        <div className="table-responsive">
                          {this.state.savings.length > 0 ? (
                            <div>
                              <div className="pl-2 ml-5">{paginationElement}</div>
                              <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                  <thead>
                                    <th>Date created</th>
                                    <th>Email</th>
                                    <th>Amount Invested</th>
                                    <th>Current Yield</th>
                                    <th>Total Yield</th>
                                    <th>Duration</th>
                                    <th>Maturity Date</th>
                                    <th>Status</th>
                                  </thead>
                                  <tbody>
                                    {this.state.savings.map((saving) => {
                                      return (
                                        <tr>
                                          <td scope="row dash-td" style={{ width: "1%" }}>
                                            <div>
                                              {moment(saving.created_at).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </div>
                                          </td>
                                          <td className="dash-td" style={{ width: "9%" }}>
                                            <div>
                                              <a
                                                target="_blank"
                                                href={"/user/" + saving.email}
                                              >
                                                {saving.email}
                                              </a>
                                            </div>
                                          </td>

                                          <td className="dash-td">
                                            <NumberFormat
                                              value={saving.amount_invested}
                                              className="card-bal-your h4"
                                              displayType={"text"}
                                              prefix={"USDT "}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                            />
                                          </td>

                                          <td className="dash-td">
                                            <NumberFormat
                                              value={
                                                saving.amount_invested +
                                                saving.current_yield
                                              }
                                              className="card-bal-your h4"
                                              displayType={"text"}
                                              prefix={"USDT "}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                            />
                                          </td>
                                          <td className="dash-td">
                                            <NumberFormat
                                              value={saving.total_yield}
                                              className="card-bal-your h4"
                                              displayType={"text"}
                                              prefix={"USDT "}
                                              decimalScale={2}
                                              thousandSeparator={true}
                                            />
                                          </td>

                                          <td className="dash-td">
                                            <div>{saving.duration} days</div>
                                          </td>

                                          <td className="dash-td">
                                            <div>
                                              {moment(saving.maturity_date).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </div>
                                          </td>

                                          <td className="dash-td">
                                            {saving.status === 0 ? (
                                              <div>
                                                <button className=" btn btn-sm btn-secondary">
                                                  active
                                                </button>
                                              </div>
                                            ) : saving.status === 4 ? (
                                              <button
                                                className="success btn btn-sm btn-success"
                                                style={{ width: "100px" }}
                                              >
                                                withdrew
                                              </button>
                                            ) : saving.status === 1 ? (
                                              <button className="btn-primary btn btn-sm btn-primary">
                                                matured
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div className="p-5 ml-5">{paginationElement}</div>
                            </div>
                          ) : (
                            <div className="text-center no-data full-width-data">
                              <p className="text-muted no-trans-text-o">
                                No savings yet!
                              </p>
                            </div>
                          )}
                        </div>
                      );
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiatDeposit;
